/* eslint-disable react-hooks/exhaustive-deps */
import { Table, Button, Form, Modal, Accordion } from "react-bootstrap";
import { useEffect, useState, useRef, useMemo, Fragment } from "react";
import CachedIcon from "mdi-react/CachedIcon";
import queryString from "query-string";
import { useMutation, useQuery } from "react-query";
import { format, parse } from "date-fns";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import currency from "currency.js";
import CurrencyInput from "react-currency-input-field";
import MagnifyIcon from "mdi-react/MagnifyIcon";

import "./../assets/scss/reports/cashbook.scss";
import useDebounce, { useQueryParams, useScrollTop } from "../utils/hooks";
import {
  paginationOptions,
  scrollToTop,
  sortOptions,
  VendorPayment,
} from "../utils/helpers";
import { useAuth } from "../hooks/useAuth";
import ConfirmDialog from "./ConfirmDialogue";
import { fetchActionsUtil } from "../utils/helpers";
import PreviewFormOnlyComponent from "./Requisition/PreviewFormOnlyComponent";
import ModalLoader from "./utils/ModalLoader";
import RsDateRangePicker from "./utils/RsDateRangePicker";
import NoTableItem from "./utils/NoTableItem";
import { ApproveCheckIcon } from "./Icons";
import Select from "react-select";
import CurrencyCustomInput from "./utils/CurrencyCustomInput";
import PORequisitionModal from "./Requisition/PORequisition";
import { cloneDeep } from "lodash";
import { FilesAttachments } from "../utils/Attachments";

export default function RFQCompanyModal({
  show,
  onHide,
  selectedRFQ,
  disableInput,
  headerName,
  headerNo,
  theRFQName,
}) {
  const { backendUrl } = useAuth();
  const [selectedFilledForm, setSelectedFilledForm] = useState({});
  const [, setFetchingIframe] = useState(false);
  const [selectedID, setSelectedID] = useState("");
  const [tableData, setTableData] = useState([]);
  // const [dataDetails, setDataDetails] = useState([]);
  const [activeTableIndex, setActiveTableIndex] = useState(null);
  const [selectedData, setSelectedData] = useState([]);

  // const [onlyItemData, setOnlyItemData] = useState([]);

  const [selectedAllData, setSelectedAllData] = useState([]);

  const [allOpenPos, setAllOpenPOs] = useState([]);

  const [ShowNewRequisitionModal, setShowNewRequisitionModal] = useState(false);
  const [vendorAttachments, setVendorAttachments] = useState([]);

  const ref = useRef(null);
  //console.log(selectedData);
  useScrollTop();
  const initialFilterParams = { q: "", page: 1, limit: 40 };
  const [queryParams, setQueryParams] = useQueryParams({
    ...initialFilterParams,
  });
  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });
  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams((q) => ({ ...q, ...debouncedFilterParams }));
  }, [debouncedFilterParams, setQueryParams]);

  const fetchDatas = async () => {
    const data = await fetchActionsUtil(
      `${backendUrl}/api/vendors/particular-RFQ/${
        selectedRFQ?.RFQId
      }?${queryString.stringify(queryParams)}`
    );

    data.itemsData = [
      {
        label: "All Item",
        value: "",
      },
      ...data.products.map((el) => ({
        label: el?.itemName,
        value: el?.itemName,
      })),
    ];

    data.vendorCert = [
      {
        label: "Select Vendor Cert",
        value: "",
      },
      ...data?.vendorCert?.map((el) => ({
        label: el,
        value: el,
      })),
    ];

    return data;
  };

  const { data, refetch, isFetching } = useQuery(
    ["GET_PARTICULAR_RFQS", { queryParams, selectedRFQ }],
    () => fetchDatas(),
    {
      enabled: true,
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    if (isFetching) {
      setActiveTableIndex(null);
      setTableData([]);
      setSelectedID("");
      // setSelectedData([]);
    }
  }, [isFetching]);

  useEffect(() => {
    if (filterParams?.onlyItems) {
      setSelectedData([]);
      setSelectedAllData([]);
      setActiveTableIndex(null);
      setTableData([]);
      setSelectedID("");
      setVendorAttachments([]);
    } else {
      setSelectedData([]);
      setSelectedAllData([]);
      setActiveTableIndex(null);
      setTableData([]);
      setSelectedID("");
      setVendorAttachments([]);
    }
  }, [filterParams?.onlyItems]);

  const handleSearchQueryChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]: e.target.value,
      page: 1,
    });
  };

  const showRFQ = async (formId, childId) => {
    const { form } = await fetchActionsUtil(
      `${backendUrl}/api/forms/single-filled-form?FormID=${formId}&childFormId=${childId}`,
      "GET"
    );
    setSelectedFilledForm(form);
    // console.log(form);
  };

  const showRFQTable = async (vendor, RFQId, requestid) => {
    const element = await fetchActionsUtil(
      `${backendUrl}/api/vendors/vendor-items?vendor=${vendor}&RFQId=${RFQId}&requestid=${requestid}`,
      "GET"
    );
    // console.log(element);
    setTableData(element.data[0]?.items);
    element?.quote?.quoteAttachments &&
      setVendorAttachments(JSON.parse(element?.quote?.quoteAttachments));
    // setSelectedData(element.data[0]?.items);
  };

  // const clearDateRange = () => {
  //   setFilterParams({
  //     ...filterParams,
  //     startDate: "",
  //     endDate: "",
  //   });
  // };

  // const filterByDateRange = (date) => {
  //   setFilterParams({
  //     ...filterParams,
  //     startDate: format(date[0], "yyyy-MM-dd"),
  //     endDate: format(date[1], "yyyy-MM-dd"),
  //   });
  // };

  const handleInputChange = ({ index, name, value }) => {
    const oldTableData = tableData;
    oldTableData[index][name] = value;
    setTableData([...oldTableData]);
  };

  const AddPriceMutation = useMutation(
    (payload) =>
      fetchActionsUtil(
        `${backendUrl}/api/vendors/add-quote-price`,
        "post",
        "",
        payload
      ),
    {
      onSuccess: ({ message }) => {
        toast.success(message);
        refetch();
      },
      onError: ({ message = "" }) => {
        toast.error(`Unable to perform action: ${message}`);
      },
    }
  );

  const sendDataUpdate = async () => {
    if (
      await ConfirmDialog({
        title: "Save Price",
        description: `Are you sure you want to save the price`,
      })
    ) {
      AddPriceMutation.mutate({ tableData });
    }
  };

  function FilterArea() {
    return (
      <Form>
        <div className="d-flex">
          <Form.Group className="mx-3">
            <CurrencyCustomInput
              name={`minPrice`}
              value={filterParams.minPrice}
              onValueChange={(value, name) => {
                setFilterParams({
                  ...filterParams,
                  page: 1,
                  minPrice: value,
                });
              }}
              placeholder="Minimum"
            />
          </Form.Group>

          <Form.Group>
            <CurrencyCustomInput
              name={`maxPrice`}
              value={filterParams.maxPrice}
              onValueChange={(value, name) => {
                setFilterParams({
                  ...filterParams,
                  page: 1,
                  maxPrice: value,
                });
              }}
              placeholder="Maximum"
            />
          </Form.Group>
        </div>
      </Form>
    );
  }

  const onSelected = (item) => {
    const theSelected = [...selectedData];

    if (theSelected.length > 0) {
      const index = theSelected.findIndex((it) => it.id === item.id);
      const vendorindex = theSelected.findIndex(
        (it) => it.vendor === item.vendor
      );

      if (vendorindex >= 0) {
        if (index >= 0) {
          theSelected.splice(index, 1);
          setSelectedData(theSelected);
        } else {
          setSelectedData((d) => [...d, item]);
        }
      } else {
        setSelectedData([item]);
      }
    } else {
      setSelectedData((d) => [...d, item]);
    }
  };

  const selectAll = (e) => {
    if (!e.target.checked) {
      setSelectedData([]);
    } else {
      setSelectedData(tableData);
    }
  };

  const onSelectedOnly = (item) => {
    const theSelected = [...selectedData];

    const theAllSelected = [...selectedAllData];

    if (theSelected.length > 0) {
      const index = theSelected.findIndex((it) => it.id === item.id);

      if (index >= 0) {
        theSelected.splice(index, 1);
        setSelectedData(theSelected);
      } else {
        setSelectedData((d) => [...d, item]);
      }
    } else {
      setSelectedData((d) => [...d, item]);
    }

    // All New Items
    if (theAllSelected.length > 0) {
      const index = theAllSelected.findIndex((i) => i.id === item.id);

      if (index >= 0) {
        theAllSelected.splice(index, 1);
        setSelectedAllData(theAllSelected);
      } else {
        setSelectedAllData((d) => [...d, item]);
      }
    } else {
      setSelectedAllData((d) => [...d, item]);
    }
  };

  const selectAllOnly = (e) => {
    if (!e.target.checked) {
      setSelectedData([]);
    } else {
      setSelectedData(data?.vendorRFQ);
    }
  };

  const itemDetails = useMemo(() => {
    let arr = null;
    if (selectedAllData.length > 0) {
      arr = Array.from(
        new Set(data?.vendorRFQ?.map((obj) => JSON.stringify(obj)))
      )
        .map((str) => JSON.parse(str))
        .filter((el) => !selectedData.find((ek) => ek.id === el.id))
        .concat([...selectedAllData]);
    } else {
      arr = Array.from(
        new Set(data?.vendorRFQ?.map((obj) => JSON.stringify(obj)))
      )
        .map((str) => JSON.parse(str))
        .filter((el) => !selectedData.find((ek) => ek.id === el.id));
    }

    return arr;
  }, [data?.vendorRFQ]);

  const openOnlyPO = () => {
    const vendors = [...new Set(selectedData?.map((d) => d.vendor))];
    const deleted = cloneDeep(selectedData)?.map((d) => {
      delete d.VendorsRFQs;
      return d;
    });
    const items = vendors?.map((el) => {
      return {
        open: true,
        items: deleted?.filter((it) => it?.vendor === el),
      };
    });
    setAllOpenPOs(items);
    // console.log(items);
  };

  const SaveSelectedItem = useMutation(
    (payload) =>
      fetchActionsUtil(
        `${backendUrl}/api/vendors/save-rfq-selected`,
        "post",
        "",
        payload
      ),
    {
      onSuccess: ({ message }) => {
        toast.success(message);
      },
      onError: ({ message = "" }) => {
        toast.error(`Unable to perform action: ${message}`);
      },
    }
  );

  const saveSelectedItems = async () => {
    //console.log(selectedData);

    if (
      await ConfirmDialog({
        title: "Save Items",
        description: `Are you sure you want to save these items`,
      })
    ) {
      SaveSelectedItem.mutate({ selectedData });
    }
  };

  useEffect(() => {
    const showSelectedItems = () => {
      if (filterParams?.onlyItems) {
        const items = itemDetails?.filter((el) => el.selected === true);
        items?.map((el) => onSelectedOnly(el));
      } else {
        const items = tableData?.filter((el) => el.selected === true);
        items?.map((el) => onSelected(el));
      }
    };
    showSelectedItems();
  }, [itemDetails, filterParams?.onlyItems, tableData]);

  return (
    <Modal
      show={show}
      onHide={() => onHide(false)}
      centered={true}
      animation={false}
      enforceFocus={false}
      backdrop="static"
      fullscreen={true}
    >
      <Modal.Header closeButton>
        <Modal.Title className=" w-100">
          <div className="d-flex justify-content-between">
            <div>
              <h1 className="h6">{headerName}</h1>
            </div>
            <div>
              <h1 className="h6 mx-4">{headerNo}</h1>
            </div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <main className="inner-page-outlet">
          <main className="cash-book">
            <div className="content">
              <div className="d-flex justify-content-start mx-3 pt-3">
                <Form.Group>
                  <Form.Check
                    inline
                    id="onlyItems"
                    label="Filter By Item"
                    name="onlyItems"
                    type={"checkbox"}
                    checked={filterParams?.onlyItems}
                    onChange={(e) => {
                      setFilterParams({
                        ...filterParams,
                        page: 1,
                        onlyItems: e.target.checked,
                      });
                    }}
                  />
                </Form.Group>
              </div>
              <div className="d-flex  align-items-baseline">
                <div className="d-flex  align-items-baseline mx-4">
                  <div>
                    <Button
                      onClick={() => refetch()}
                      variant=""
                      className="bg-light-blue border-0 text-primary"
                    >
                      <CachedIcon />
                    </Button>
                  </div>
                  <div className="d-flex  align-items-baseline mx-4">
                    <div className="mx-3" style={{ zIndex: "999" }}>
                      <Select
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            width: "12rem",
                          }),
                        }}
                        classNamePrefix={`form-select-store`}
                        menuPosition="fixed"
                        isSearchable={false}
                        placeholder="Sort By Price"
                        options={sortOptions?.filter(
                          (el) =>
                            el?.value === "highest-price" ||
                            el?.value === "lowest-price"
                        )}
                        value={sortOptions.find(
                          (el) => el.value === filterParams.sort
                        )}
                        onChange={({ value }) =>
                          setFilterParams({
                            ...filterParams,
                            page: 1,
                            sort: value,
                          })
                        }
                      />
                    </div>

                    {FilterArea()}
                  </div>
                </div>
                <div className="d-flex  align-items-baseline mb-4 mx-4">
                  <div className="mx-4" />
                  <div className="mx-3" style={{ zIndex: "999" }}>
                    <Select
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          width: "12rem",
                        }),
                      }}
                      classNamePrefix={`form-select-store`}
                      menuPosition="fixed"
                      isSearchable={false}
                      placeholder="All Item"
                      options={data?.itemsData}
                      value={data?.itemsData?.find(
                        (el) => el.value === filterParams.item
                      )}
                      onChange={({ value }) =>
                        setFilterParams({
                          ...filterParams,
                          page: 1,
                          item: value,
                        })
                      }
                    />
                  </div>

                  <div className="mx-3" style={{ zIndex: "999" }}>
                    <Select
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          width: "12rem",
                        }),
                      }}
                      classNamePrefix={`form-select-store`}
                      menuPosition="fixed"
                      isSearchable={false}
                      placeholder="Payment Search"
                      options={VendorPayment}
                      value={VendorPayment?.find(
                        (el) => el.value === filterParams?.payType
                      )}
                      onChange={({ value }) =>
                        setFilterParams({
                          ...filterParams,
                          page: 1,
                          payType: value,
                        })
                      }
                    />
                  </div>
                  <div className="mx-3" style={{ zIndex: "999" }}>
                    <Select
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          width: "12rem",
                        }),
                      }}
                      classNamePrefix={`form-select-store`}
                      menuPosition="fixed"
                      isSearchable={false}
                      placeholder="Select Vendor Cert"
                      options={data?.vendorCert}
                      value={data?.vendorCert?.find(
                        (el) => el.value === filterParams?.cert
                      )}
                      onChange={({ value }) =>
                        setFilterParams({
                          ...filterParams,
                          page: 1,
                          cert: value,
                        })
                      }
                    />
                  </div>
                  <Button
                    onClick={() =>
                      setFilterParams({
                        ...filterParams,
                        minPrice: "",
                        maxPrice: "",
                        sort: "",
                        item: "",
                      })
                    }
                    variant="white"
                    // className="col border"
                  >
                    Reset
                  </Button>
                </div>
              </div>

              {filterParams?.onlyItems ? (
                <div className="mx-3">
                  <div>
                    <Table
                      responsive
                      borderless
                      striped
                      className="product-table"
                    >
                      <thead>
                        <tr>
                          <th>
                            <input
                              type="checkbox"
                              onChange={selectAllOnly}
                              checked={Boolean(
                                selectedData.length === data?.vendorRFQ?.length
                              )}
                            />
                          </th>
                          <th>Vendor</th>
                          <th>Item Code</th>
                          <th>Item Name</th>
                          <th>Unit Cost</th>
                          <th>Quantity</th>
                          <th>Subtotal</th>
                        </tr>
                      </thead>
                      <tbody ref={ref}>
                        {itemDetails?.length > 0 &&
                          itemDetails
                            ?.filter((el) => el?.amount > 0)
                            ?.map((el, index) => (
                              <tr key={index}>
                                <td>
                                  <input
                                    type="checkbox"
                                    onChange={() => onSelectedOnly(el)}
                                    checked={Boolean(
                                      selectedData.find((ek) => ek.id === el.id)
                                    )}
                                  />
                                </td>
                                <td>{el?.VendorsRFQs?.CompanyName}</td>
                                <td>{el.barcode}</td>
                                <td>{el.itemName}</td>
                                <td>{el.amount}</td>
                                <td>{el.quantity}</td>
                                <td>
                                  {!isNaN(el.amount * el.quantity)
                                    ? currency(el.amount, {
                                        precision: 2,
                                        symbol: " ",
                                      })
                                        .multiply(el.quantity)
                                        .format()
                                    : 0}
                                </td>
                              </tr>
                            ))}
                      </tbody>
                    </Table>

                    {itemDetails?.length > 0 && (
                      <div className="d-flex justify-content-end mb-3">
                        <div className="gridChild gridChildBorderLeftBlue">
                          <div className="gridChildLeft">
                            <p className="gridChld1">
                              {currency(
                                itemDetails?.reduce(
                                  (a, b) => a + b.amount * b.quantity,
                                  0
                                ),
                                {
                                  symbol: "",
                                }
                              ).format()}
                            </p>
                            <p className="gridChld2">Total</p>
                          </div>
                        </div>
                      </div>
                    )}

                    {selectedData.length > 0 && (
                      <div className="d-flex justify-content-between">
                        <div>
                          <button
                            className="btn btn-primary"
                            onClick={saveSelectedItems}
                          >
                            Save Selected
                          </button>
                        </div>
                        <div>
                          <button
                            onClick={openOnlyPO}
                            className="btn btn-primary"
                          >
                            Create PO
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="col-4">
                    <div className="px-md-4">
                      <div className="row">
                        <Table
                          responsive
                          borderless
                          striped
                          className="product-table"
                        >
                          <thead>
                            <tr>
                              <th>S/N</th>
                              <th>Vendor</th>
                              <th>Date</th>
                              <th />
                            </tr>
                          </thead>
                          {!isFetching ? (
                            <tbody>
                              {data?.vendorRFQ?.map((el, index) => (
                                <tr
                                  className={`${activeTableIndex === index &&
                                    "active-row-blue"} p-cursor`}
                                  key={index}
                                  onClick={() => {
                                    setSelectedID(el.id);
                                    activeTableIndex !== index &&
                                      setActiveTableIndex(index);

                                    if (el.FormID && el.childFormId) {
                                      showRFQ(el.FormID, el.childFormId);
                                    } else {
                                      setVendorAttachments([]);
                                      showRFQTable(
                                        el.vendor,
                                        el.RFQId,
                                        el.QuoteId
                                      );
                                      setSelectedFilledForm(null);
                                    }
                                  }}
                                >
                                  <td>{index + 1}</td>
                                  <td>{el?.VendorsQuotes?.CompanyName}</td>
                                  <td>
                                    {el?.Date_Log &&
                                      format(
                                        new Date(el?.Date_Log),
                                        "dd/MM/yyyy"
                                      )}
                                  </td>
                                  <td>
                                    {(selectedID === el.id ||
                                      el?.selectedItem) && <ApproveCheckIcon />}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          ) : (
                            <ModalLoader show={true} />
                          )}
                        </Table>
                      </div>
                    </div>

                    {data?.vendorRFQ?.length < 1 && !isFetching && (
                      <div className="d-flex justify-content-center text-center w-100 my-4">
                        <NoTableItem queryParams={queryParams} />
                      </div>
                    )}
                  </div>

                  <div className="col-8">
                    {selectedID !== "" ? (
                      <>
                        {selectedFilledForm ? (
                          <PreviewFormOnlyComponent
                            selectedFilledForm={selectedFilledForm}
                            template={selectedFilledForm?.filledFormData}
                            setFetchingIframe={setFetchingIframe}
                            size="30rem"
                            canFill={false}
                            showTableCheckbox={false}
                            printForm
                          />
                        ) : (
                          <div className="mx-3">
                            <div>
                              <Table
                                responsive
                                borderless
                                striped
                                className="product-table"
                              >
                                <thead>
                                  <tr>
                                    <th>
                                      <input
                                        type="checkbox"
                                        onChange={selectAll}
                                        checked={Boolean(
                                          selectedData.length ===
                                            tableData.length
                                        )}
                                      />
                                    </th>
                                    <th>Item Code</th>
                                    <th>Item Name</th>
                                    <th>Unit Cost</th>
                                    <th>Quantity</th>
                                    <th>Subtotal</th>
                                    <th>Remark</th>
                                  </tr>
                                </thead>
                                <tbody ref={ref}>
                                  {tableData &&
                                    tableData.map((el, index) => (
                                      <tr key={index}>
                                        <td>
                                          <input
                                            type="checkbox"
                                            onChange={() => onSelected(el)}
                                            checked={Boolean(
                                              selectedData.find(
                                                (ek) => ek.id === el.id
                                              )
                                            )}
                                          />
                                        </td>
                                        <td
                                          className="p-cursor"
                                          // style={{ width: "50%" }}
                                        >
                                          {el.barcode}
                                        </td>
                                        <td
                                        // style={{ width: "50%" }}
                                        >
                                          {el.itemName}
                                        </td>
                                        <td style={{ width: "50%" }}>
                                          <CurrencyInput
                                            className="form-control border-0 px-1"
                                            value={el.amount}
                                            disabled={disableInput}
                                            onKeyDown={(e) =>
                                              e.keyCode === 13 &&
                                              e.target.blur()
                                            }
                                            name="amount"
                                            onValueChange={(value, name) =>
                                              handleInputChange({
                                                index,
                                                name,
                                                value,
                                              })
                                            }
                                            //    disableGroupSeparators
                                            decimalsLimit={2}
                                          />
                                        </td>
                                        <td>{el.quantity}</td>
                                        <td style={{ width: "50%" }}>
                                          <CurrencyInput
                                            className="form-control border-0 px-0"
                                            name="subtotal"
                                            disabled
                                            value={
                                              !isNaN(el.amount * el.quantity)
                                                ? currency(el.amount, {
                                                    precision: 2,
                                                  }).multiply(el.quantity)
                                                : 0
                                            }
                                            onKeyDown={(e) =>
                                              e.keyCode === 13 &&
                                              e.target.blur()
                                            }
                                            onValueChange={(value, name) =>
                                              handleInputChange({
                                                index,
                                                name,
                                                value,
                                              })
                                            }
                                            // disableGroupSeparators
                                            decimalsLimit={2}
                                          />
                                        </td>
                                        <td style={{ width: "100%" }}>
                                          {el?.remark}
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </Table>

                              {vendorAttachments?.length > 0 && (
                                <div className="my-4 col-md-6">
                                  <p className="mb-2"> Attachments: </p>
                                  <FilesAttachments
                                    files={vendorAttachments.map((el) =>
                                      !el?.fileId
                                        ? el
                                        : {
                                            ...el,
                                            systemFileName: `${
                                              el.systemFileName
                                            }/${el.name}`,
                                          }
                                    )}
                                    colWidth="3"
                                    url={`${backendUrl}/api/vendors/files`}
                                    viewOnly={true}
                                  />
                                </div>
                              )}

                              {tableData.length > 0 && (
                                <div className="d-flex justify-content-end mb-3">
                                  <div className="gridChild gridChildBorderLeftBlue">
                                    <div className="gridChildLeft">
                                      <p className="gridChld1">
                                        {currency(
                                          tableData.reduce(
                                            (a, b) => a + b.amount * b.quantity,
                                            0
                                          ),
                                          {
                                            symbol: "",
                                          }
                                        ).format()}
                                      </p>
                                      <p className="gridChld2">Total</p>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {selectedData.length > 0 && (
                                <div className="d-flex justify-content-between">
                                  <div>
                                    <button
                                      className="btn btn-primary"
                                      onClick={saveSelectedItems}
                                    >
                                      Save Selected
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      onClick={() =>
                                        setShowNewRequisitionModal(true)
                                      }
                                      className="btn btn-primary"
                                    >
                                      Create PO
                                    </button>
                                  </div>
                                </div>
                              )}
                            </div>
                            {!disableInput && (
                              <div className="d-flex justify-content-end">
                                <button
                                  onClick={sendDataUpdate}
                                  className="btn btn-primary"
                                  disabled={AddPriceMutation.isLoading}
                                >
                                  {AddPriceMutation.isLoading
                                    ? "Saving..."
                                    : "Create Quote"}
                                </button>
                              </div>
                            )}
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="d-flex justify-content-center text-center w-100 my-4">
                        <NoTableItem queryParams={"No quote selected"} />
                      </div>
                    )}
                  </div>
                </div>
              )}

              <div className="d-flex justify-content-between px-3 align-items-center pagination">
                <div className="pagination_left">
                  <p className="m-0 p-0">Show</p>
                  <select
                    value={queryParams.limit}
                    name="limit"
                    className="form-select "
                    onChange={(e) => handleSearchQueryChange(e)}
                  >
                    <option value="10">10 rows</option>
                    <option value="20">20 rows</option>
                    <option value="30">30 rows</option>
                    <option value="40">40 rows</option>
                    <option value="50">50 rows</option>
                    <option value="100">100 rows</option>
                  </select>
                </div>

                <ReactPaginate
                  {...paginationOptions}
                  pageCount={Math.ceil(data?.count / queryParams.limit)}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={0}
                  onPageChange={({ selected }) => {
                    setQueryParams({
                      ...queryParams,
                      page: selected + 1,
                    });
                    scrollToTop();
                  }}
                  forcePage={queryParams.page - 1}
                />
              </div>
            </div>
          </main>
        </main>

        {ShowNewRequisitionModal && (
          <PORequisitionModal
            setShowNewRequisitionModal={setShowNewRequisitionModal}
            ShowNewRequisitionModal={ShowNewRequisitionModal}
            selectedItems={selectedData}
          />
        )}
        {allOpenPos?.length > 0 &&
          allOpenPos?.map((el, index) => (
            <Fragment key={index}>
              <PORequisitionModal
                setShowNewRequisitionModal={(isOpen) =>
                  setAllOpenPOs((old) =>
                    old?.map((o, i) => ({
                      ...old,
                      open: i === index ? isOpen : o.open,
                    }))
                  )
                }
                ShowNewRequisitionModal={el?.open}
                selectedItems={el?.items}
                disableVendor
                key={index}
                theRFQName={`${theRFQName} PO`}
              />
            </Fragment>
          ))}
      </Modal.Body>
    </Modal>
  );
}
