import React, { useState } from "react";
import { toast } from "react-toastify";
import { Button, Form } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import "./../../assets/scss/scoped/login.scoped.scss";
import "./../../assets/scss/fileModal.scss";


const OtpModal = ({ open, setOpen, submitOTP, confirmLoading, title, setLoginAction }) => {
  // const [confirmLoading, setConfirmLoading] = useState(false);
  const [inputText, setInputText] = useState({});
  const [canClose, setCanClose] = useState(false)
  const [rememberDevice, setRememberDevice] = useState(true)
  const showModal = () => {
    setOpen(true);
  };
  const handleOk = async () => {
    await submitOTP({ otp: inputText, rememberDevice: rememberDevice })
    // setConfirmLoading(false)
  };
  const handleCancel = () => {
    if (inputText.length === 6 || canClose) {
      setOpen(false);
      setInputText("")
      setLoginAction("login")
      return
    }
    toast.warning("You haven't entered your OTP")
    setCanClose(true)
  };

  return (
    <Modal
      // {...props}
      show={open}
      onHide={handleCancel}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="modal-title">
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p style={{ marginBottom: "1rem" }}>
          <p style={{ marginBottom: "1rem" }}>An OTP have been sent to your registered email.</p>
          <Form.Control placeholder="Your OTP" onChange={(e) => setInputText(e.target.value)} />
          <br />
          {title !== "Two Factor Authentication" &&
            <Form.Check // prettier-ignore
              defaultChecked={rememberDevice}

              onClick={(e) => setRememberDevice(e.target.checked)}
              type={"checkbox"}
              id={`remember-checkbox`}
              label={`Remember this device`}
            />
          }
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={confirmLoading} onClick={handleOk}>{confirmLoading ? "Please Wait..." : "Submit"}</Button>
      </Modal.Footer>
    </Modal>
  );
}
export default OtpModal;

