import { useEffect, useState, useRef, Fragment, useMemo } from "react";
import {
  Button,
  Modal,
  Table,
  InputGroup,
  Popover,
  Overlay,
  Form,
  Dropdown,
} from "react-bootstrap";
import "./../../assets/scss/requisition/index.scss";
import {
  DownloadIcon,
  DropdownCloseIcon,
  ExcelIcon,
  Logo,
  PrintIcon,
  SignatureIcon,
} from "../Icons";
import { useNavigate, useParams } from "react-router-dom";
import { backendApis, services } from "../../config";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { addDays, format } from "date-fns";
import CurrencyInput from "react-currency-input-field";
import { cloneDeep, find, isEmpty, lowerCase, uniqBy } from "lodash";
import {
  VendorPayment,
  fetchActionsUtil,
  isInventoryOrFundRequest,
  nanoid,
  waitFor,
} from "../../utils/helpers";
import useDebounce from "../../utils/hooks";
import useEffectOnce from "../../utils/hooks";
import { toast } from "react-toastify";
import { cacheTimes, queryActions } from "../../utils/reactQueryActions";
import currency from "currency.js";
import ItemsTable from "../utils/ItemsTable";
import { useAuth } from "../../hooks/useAuth";
import { useFormik } from "formik";
import RequisitionService from "../../hooks/requisitionService";
import * as yup from "yup";
import eventBus from "../../utils/EventBus";
import _ from "lodash";
import AsyncSelect from "react-select/async";
import { useStoreActions, useStoreState } from "easy-peasy";
import CurrencyCustomInput from "../utils/CurrencyCustomInput";
import Select from "react-select";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import Datetime from "react-datetime";
import moment from "moment";
import { log } from "@craco/craco/lib/logger";
import AddWarehousePOModal from "../AddWarehousePO";
import DotsHorizontalIcon from "mdi-react/DotsHorizontalIcon";
import ConfirmDialog from "../ConfirmDialogue";
import { read, utils } from "xlsx";
import ModalLoader from "../utils/ModalLoader";
import { FilesAttachments } from "../../utils/Attachments";

export default function PORequisitionModal({
  setShowNewRequisitionModal,
  ShowNewRequisitionModal,
  selectedItems,
  mainRefetch,
  theRFQName,
  planItems,
  pickedVendor,
  vendorNumber,
  disableVendor,
  key,
  initialTableData = [],
  approvedRequisitionId = "",
  draftPO,
  editPurchaseOrder,
  incomingAttachments,
  splitItems = [],
  demandPlan,
}) {
  const initialRequestData = {
    id: `id${String(Date.now())}`,
    barcode: " ",
    itemName: " ",
    vendor: `vendor${String(Date.now())}`,
    RFQId: `rfq${String(Date.now())}`,
    requestid: `REQ${String(Date.now())}`,
    warehouse: " ",
    unitCost: 0,
    quantity: 0,
    RegDate: format(new Date(), "yyyy-MM-dd"),
    selected: false,
    amount: 0,

    Generic_Configuration: " ",
    Branded_Generic: " ",
    Pack_Size: " ",
    Tier: " ",
    specification: " ",
  };
  const generalSettings = useStoreState((state) => state.generalSettings);
  const { createRequisitionMutation } = RequisitionService();
  const [tableData, setTableData] = useState([initialRequestData]);
  const [total, setTotal] = useState(0);
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const [activeTableIndex, setActiveTableIndex] = useState(null);
  const [selectedVendors, setSelectedVendors] = useState([]);
  // const [fundRequest, setFundRequest] = useState(initialRequestData());
  const { user: authUser, backendUrl } = useAuth();
  const [showWarehousePO, setShowWarehousePO] = useState(false);
  const [selectedPO, setSelectedPO] = useState({});
  const [allItems, setAllItems] = useState(splitItems ? splitItems : []);
  const [savedStaff, setSavedStaff] = useState({});
  const [savedWarehouse, setSavedWarehouse] = useState({});
  const [reciever, setReceiver] = useState();
  const [draftPoID, setDraftPoID] = useState(null);
  const [editPOData, setEditPOData] = useState({
    requestid: null,
    POId: null,
    vendor: null,
  });
  const [loading, setLoading] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [IsLoading, setIsLoading] = useState(false);

  const handleInputChange = ({ index, name, value }) => {
    const oldTableData = tableData;
    oldTableData[index][name] = value;
    setTableData([...oldTableData]);
  };

  const calculateTotal = (tableData) => {
    setTotal(
      tableData
        .map((el) =>
          currency(el?.unitCost, {
            precision: 2,
          }).multiply(el?.quantity)
        )
        .reduce(
          (a, b) =>
            currency(a, {
              precision: 2,
            }).add(b),
          0
        )
    );
  };

  const debouncedTableData = useDebounce(tableData, 800);
  useEffect(() => {
    if (debouncedTableData) calculateTotal(debouncedTableData);
  }, [debouncedTableData]);

  const saveImages = async ({ formValues }) => {
    return new Promise(async (resolve, reject) => {
      try {
        //------------------ Upload Files  -----------------------

        if (attachments?.length > 0) {
          const payload = { initialFiles: attachments };
          const data = await uploadFileApi(payload);
          formValues.attachments = JSON.stringify(data?.imageFiles);
        }

        //--------------------------------------------------------
        resolve(formValues);
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  };

  const saveAllData = async ({ formValues }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const changeTableData = tableData.filter(
          (el) => el.barcode !== " " || el.itemName !== " "
        );

        let description = changeTableData
          .map((el) => {
            delete el.subtotal;
            return Object.values(el).join(",,,");
          })
          .join("&&&");

        description = description + "&&&";

        formValues.requestto = formValues.recipient;
        formValues.regdate = format(new Date(), "yyyy-MM-dd");
        formValues.status = "Pending";

        const dataMain = changeTableData.map((el) => {
          delete el.id;
          return el;
        });

        createRequisitionMutation.mutate(
          {
            approvedRequisitionId,
            description,
            amount: total,
            ...formValues,
            tableData: JSON.stringify(dataMain),
            demandPlanId: demandPlan?.id,
          },
          {
            onSuccess: (data) => {
              formik.resetForm();
              eventBus.dispatch("REQUISITION_CREATED", data.requisition);
              setShowNewRequisitionModal(false);
              mainRefetch && mainRefetch();
            },
          }
        );

        resolve();
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  };

  const save = async (formValues) => {
    const data = await saveImages({ formValues });
    await saveAllData({ formValues: data });

    // let description = tableData
    //   .map((el) => {
    //     delete el.subtotal;
    //     return Object.values(el).join(",,,");
    //   })
    //   .join("&&&");

    // description = description + "&&&";

    // formValues.requestto = formValues.recipient;
    // formValues.regdate = format(new Date(), "yyyy-MM-dd");
    // formValues.status = "Pending";

    // const dataMain = tableData.map((el) => {
    //   delete el.id;
    //   return el;
    // });

    // createRequisitionMutation.mutate(
    //   {
    //     approvedRequisitionId,
    //     // requestid: id,
    //     description,
    //     // amount: isNaN(realTotal) ? 0 : realTotal,
    //     amount: total,
    //     // requiredSignatoriesStaff_ID: JSON.stringify([]),
    //     ...formValues,
    //     tableData: JSON.stringify(dataMain),
    //     demandPlanId: demandPlan?.id,
    //   },
    //   {
    //     onSuccess: (data) => {
    //       formik.resetForm();
    //       eventBus.dispatch("REQUISITION_CREATED", data.requisition);
    //       setShowNewRequisitionModal(false);
    //       mainRefetch && mainRefetch();
    //     },
    //   }
    // );
  };

  const ref = useRef(null);

  const handleRowClick = (e, index) => {
    setShow(true);
    setTarget(e.target);
  };

  const createNew = (e) => {
    setShow(true);
    setTarget(e.target);
    setActiveTableIndex(-1);
  };

  const fetchPrice = async (vendorid) => {
    const items = planItems?.map((item) => ({
      id: `id${String(Date.now())}`,
      barcode: item.Bar_Code,
      itemName: item.Item_Name,
      vendor: `vendor${String(Date.now())}`,
      RFQId: `rfq${String(Date.now())}`,
      requestid: `REQ${String(Date.now())}`,
      warehouse: " ",
      unitCost: 0,
      quantity: item?.QtyToOrder,
      RegDate: format(new Date(), "yyyy-MM-dd"),
      selected: false,
      amount: 0,

      Generic_Configuration: item.Generic_Configuration
        ? item?.Generic_Configuration
        : " ",
      Branded_Generic: item.Branded_Generic ? item?.Branded_Generic : " ",
      Pack_Size: item.Pack_Size ? item?.Pack_Size : " ",
      Tier: item.Tier ? item?.Tier : " ",
      specification: item.specification ? item?.specification : " ",
    }));

    const getPrices = await fetchActionsUtil(
      `${backendUrl}/api/items/get-vendor-prices`,
      "POST",
      "",
      { tableData: items, Vendor_ID: vendorid }
    );
    setTableData(getPrices?.data);
  };

  useEffect(() => {
    if (planItems) {
      fetchPrice(" ");
    }
  }, [planItems]);

  const handleSelectedItem = (item) => {
    item = {
      id: `id${String(Date.now())}`,
      barcode: item.Bar_Code,
      itemName: item.Item_Name,
      vendor: `vendor${String(Date.now())}`,
      RFQId: `rfq${String(Date.now())}`,
      requestid: `REQ${String(Date.now())}`,
      warehouse: item.Warehouse ? item.Warehouse : " ",
      unitCost: item.UnitCost,
      quantity: 1,
      RegDate: format(new Date(), "yyyy-MM-dd"),
      selected: false,
      amount: item.UnitCost,

      Generic_Configuration: item.Generic_Configuration
        ? item?.Generic_Configuration
        : " ",
      Branded_Generic: item.Branded_Generic ? item?.Branded_Generic : " ",
      Pack_Size: item.Pack_Size ? item?.Pack_Size : " ",
      Tier: item.Tier ? item?.Tier : " ",
      specification: item.specification ? item?.specification : " ",
      disable: true,
    };

    let oldTableData = tableData;

    const found = oldTableData.find((el) => el.barcode === item.barcode);

    if (found) {
      const newItem = { ...found, quantity: parseInt(found.quantity) + 1 };
      const index = oldTableData.findIndex((el) => el.barcode === item.barcode);
      oldTableData[index] = newItem;
    } else {
      oldTableData = [...oldTableData, item];
    }

    setTableData([...oldTableData]);
    if (activeTableIndex === -1) setActiveTableIndex(oldTableData.length - 1);
    setShow(false);
  };

  const { data: warehouse } = useQuery(
    ["FETCH_WAREHOUSE"],
    () => fetchActionsUtil(`${backendUrl}/api/warehouse`, "GET"),
    {
      keepPreviousData: true,
    }
  );

  const enterNewItem = () => {
    let oldTableData = tableData;

    // Check if any object is duplicated (by reference, which is typically how JS compares objects)
    const hasDuplicates =
      oldTableData.length !==
      new Set(oldTableData.map((obj) => JSON.stringify(obj))).size;

    if (hasDuplicates) {
      return toast.error("There are duplicates, delete it");
    } else {
      setTableData([...oldTableData, initialRequestData]);
      if (activeTableIndex === -1) setActiveTableIndex(oldTableData.length - 1);
    }
  };

  const removeSelection = () => {
    const oldTableData = tableData.filter(
      (el, index) => index !== activeTableIndex
    );
    setTableData([...oldTableData]);
  };

  const formik = useFormik({
    initialValues: {
      title: theRFQName ? theRFQName : "",
      type: "PO",
      department: "",
      recipient: "",
      jobGrade: "",
      comment: "",
      currency: generalSettings?.prevailingCurrency,
      dueDate: moment(),
      // conversionAmount: generalSettings.dollarInBaseCurrency,
      expectedDeliveryDate: new Date(),
      vendorPaymentType: "",
      vendorPayment: "",
      paymentDueDate: moment(),
      SendVendorEmail: true,
    },
    validationSchema: yup.object().shape({
      title: yup.string().required(),
      type: yup.string().required(),
    }),
    onSubmit: async (values) => {
      values = cloneDeep(values);
      if (isEmpty(values.comment)) values.comment = "...";

      const { dueDate, vendorPaymentType, vendorPayment } = values;

      if (typeof dueDate === "string") {
        return formik.setFieldError("dueDate", "Invalid date");
      }

      if (selectedVendors?.length < 1) {
        return toast.error("Please Select Vendor", {
          position: "top-right",
        });
      }

      if (tableData?.length < 1) {
        return toast.error("Please Select Items", {
          position: "top-right",
        });
      }

      if (vendorPaymentType === "") {
        return formik.setFieldError("vendorPaymentType", "Can not be empty");
      }

      if (vendorPaymentType === "Part_Payment") {
        if (parseFloat(vendorPayment) > 100 || parseFloat(vendorPayment) < 0) {
          return formik.setFieldError("vendorPayment", "Enter btw 1 - 100");
        }
      }

      if (vendorPaymentType === "Post_Payment") {
        if (vendorPayment === "") {
          return formik.setFieldError("vendorPayment", "Can not be empty");
        }
      }

      if (isEmpty(values.department) && isEmpty(values.recipient)) {
        return toast.error(`Please Select Department or Recipient`, {
          position: "top-right",
        });
      }

      if (
        isEmpty(values.recipient) &&
        !(await ConfirmDialog({
          title: "Recipient not Selected",
          description: "Continue?",
        }))
      ) {
        return;
      }

      // TODO check if item was shared

      // return;

      // tableData.forEach(ele => {
      //         const pos = allItems?.filter((el) => el.barcode === ele.barcode);

      // });

      /*  

      return; */

      console.log({ allItems, tableData });

      values.selectedVendors = JSON.stringify(
        [selectedVendors]?.map((el) => el.value)
      );
      values.dueDate = dueDate.format();
      values.allItems = JSON.stringify(allItems?.map((el) => el));

      values.attachments = JSON.stringify([]);
      if (attachments?.length > 0) {
        values.attachments = JSON.stringify(attachments);
      }

      if (draftPoID) {
        values.draftPoID = draftPoID;
        if (
          await ConfirmDialog({
            title: `Create Purchase Order`,
            description: `Are you sure you want to create purchase order`,
          })
        ) {
          save(values);
        }
      } else if (editPOData?.requestid) {
        values.editPOData = editPOData?.requestid;
        values.editedPoID = editPOData?.POId;
        if (
          await ConfirmDialog({
            title: `Edit Purchase Order`,
            description: `All your previous data will be deleted and a new one will be created.`,
          })
        ) {
          save(values);
        }
      } else {
        if (
          await ConfirmDialog({
            title: `Create Purchase Order`,
            description: `Are you sure you want to create purchase order`,
          })
        ) {
          save(values);
        }
      }

      //console.log(values);
    },
  });

  const daftPO = useMutation(
    (payload) =>
      fetchActionsUtil(
        `${backendUrl}/api/vendors/daft-PO`,
        "POST",
        "",
        payload
      ),
    {
      onSuccess: ({ message }) => {
        toast.success(message);
        setShowNewRequisitionModal(false);
        mainRefetch();
      },
      onError: () => {
        toast.error("Unable to daft purchase order");
      },
    }
  );

  const holdOneSomeTime = async () => {
    const vendorSelect =
      Object.entries(selectedVendors).length > 0
        ? JSON.stringify(selectedVendors)
        : "";
    const vendor =
      Object.entries(selectedVendors).length > 0 ? selectedVendors?.label : "";

    const staffs =
      Object.entries(savedStaff).length > 0 ? JSON.stringify(savedStaff) : "";

    const warehouses =
      Object.entries(savedWarehouse).length > 0
        ? JSON.stringify(savedWarehouse)
        : "";
    const data = {
      ...formik.values,
      tableData: tableData.length > 0 ? JSON.stringify(tableData) : "",
      selectedVendors: vendorSelect,
      allItems: allItems.length > 0 ? JSON.stringify(allItems) : "",
      draftPoID,
      savedStaff: staffs,
      vendor,
      attachments: JSON.stringify(attachments),
      savedWarehouse: warehouses,
    };

    if (
      await ConfirmDialog({
        title: "Daft Purchase Order",
        description: `Are you sure you want to Draft this Purchase Order`,
      })
    ) {
      daftPO.mutate({ data });
    }
  };

  useEffect(() => {
    if (draftPO) {
      draftPO.dueDate = moment(new Date(draftPO.dueDate));
      draftPO.expectedDeliveryDate = new Date(draftPO.expectedDeliveryDate);
      draftPO.paymentDueDate = moment(new Date(draftPO.paymentDueDate));
      const table =
        draftPO.tableData === "" ? [] : JSON.parse(draftPO.tableData);
      // console.log(draftPO, table);
      formik.setValues(draftPO, false);
      setDraftPoID(draftPO.id);
      setTableData(table);
      setAllItems(draftPO.allItems === "" ? [] : JSON.parse(draftPO.allItems));
      setSelectedVendors(
        draftPO.selectedVendors === ""
          ? []
          : JSON.parse(draftPO.selectedVendors)
      );
      setSavedStaff(
        draftPO.savedStaff === "" || draftPO.savedStaff === null
          ? {}
          : JSON.parse(draftPO.savedStaff)
      );
      setSavedWarehouse(
        draftPO.savedWarehouse === "" || draftPO.savedWarehouse === null
          ? {}
          : JSON.parse(draftPO.savedWarehouse)
      );

      draftPO?.attachments && setAttachments(JSON.parse(draftPO?.attachments));
    }
  }, [draftPO]);

  const getUsersInDepartments = async (department) => {
    let response = await fetch(
      `${backendUrl}/api/users/by-department/${department}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    data.users = [
      { label: "Select Recipient", value: "" },
      ...data.users
        .map((el) => ({
          ...el,
          label: el.Name,
          value: el.Staff_ID,
        }))
        .filter((el) => el.Staff_ID !== authUser.Staff_ID),
    ];
    return data;
  };
  const {
    data: { users } = { users: [] },
    refetch: getUsers,
    isFetching: isLoadingUsers,
  } = useQuery(
    ["queryActions.GET_USERS_IN_DEPARTMENTS", formik.values.department],
    () => getUsersInDepartments(formik.values.department),
    {
      enabled: false,
    }
  );

  const fetchVendors = async (department) => {
    let response = await fetch(`${backendUrl}/api/vendors?verified=true`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    data.vendors = [
      { label: "None", value: "" },
      ...data.vendors.map((el) => ({
        label: el.CompanyName,
        value: el.Vendor_ID,
        vendorPaymentType: el?.paymentType ? el?.paymentType : "",
        vendorPayment:
          el?.paymentType === "Part_Payment"
            ? el?.paymentPercent
            : el?.paymentType === "Post_Payment"
            ? el?.paymentDueDays
            : "",
      })),
    ];
    return data;
  };

  const {
    data: { vendors } = { vendors: [] },
    isFetching: isLoadingVendors,
  } = useQuery([queryActions.GET_VENDORS], () => fetchVendors(), {
    enabled: true,
  });

  //----------------------------------------------------------------------
  // fetch data for form
  const getDepartments = async () => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/users/departments-with-users`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };

  const {
    data = {
      departments: [],
      staffs: [],
      jobGrades: [],
      departmentWithLeads: [],
    },
  } = useQuery(
    ["queryActions.GET_USERS_WITH_DEPARTMENTS"],
    () => getDepartments(),
    {
      enabled: true,
      keepPreviousData: true,
      cacheTime: cacheTimes.GET_USERS_WITH_DEPARTMENTS,
    }
  );

  const staffSelectOptions = useMemo(() => {
    const jobGrades = data.staffs
      .filter((el) => el.Department === formik.values.department)
      .map((el) => ({ jobGrade: el.jobGrade }));

    const staffs = data.staffs.filter((el) => {
      if (!formik.values.department) return true;
      if (!formik.values.department && !formik.values.jobGrade) return true;
      if (formik.values.department === "Department Lead") {
        const myDepartmentLead = data.departmentWithLeads
          .filter((el) => el.Dept_Lead && el.Dept_Name === authUser.Department)
          .map((el) => JSON.parse(el.Dept_Lead))
          .flat();

        return myDepartmentLead.find((lead) => lead.Staff_ID === el.Staff_ID);
      }
      if (formik.values.department && !formik.values.jobGrade) {
        return el.Department === formik.values.department;
      }
      if (formik.values.department && formik.values.jobGrade) {
        return (
          el.Department === formik.values.department &&
          el.jobGrade === formik.values.jobGrade
        );
      }
    });

    return {
      departments: [
        { label: "Any Department", value: "" },
        { label: "Department Lead", value: "Department Lead" },
        ...data.departments.map((el) => ({
          label: el.Department,
          value: el.Department,
        })),
      ],
      jobGrades: [
        { label: "Any Job Grade", value: "" },
        ...uniqBy(jobGrades, "jobGrade").map((el) => ({
          label: el.jobGrade,
          value: el.jobGrade,
        })),
      ],
      staffs: [
        { label: "Any Staff", value: "" },
        ...staffs.map((el) => ({
          label: el.Name,
          value: el.Staff_ID,
        })),
      ],
    };
  }, [data, formik.values.department, formik.values.jobGrade]);

  const setTheVendorState = (input) => {
    let ved = null;
    if (Array.isArray(input)) {
      ved = vendors.find((d) => input.find((e) => e === d.value));
    } else {
      ved = vendors.find((d) => input === d.value);
    }

    if (ved) {
      const pro = vendors.filter((el) => ved.value === el.value);
      formik.setFieldValue("vendorPaymentType", pro[0]?.vendorPaymentType);
      formik.setFieldValue("vendorPayment", pro[0]?.vendorPayment);
    }

    setSelectedVendors(ved);
  };

  useEffect(() => {
    if (selectedItems) {
      const items = selectedItems?.map((el) => ({
        ...el,
        unitCost: el?.amount,
        warehouse: el?.warehouse ? el?.warehouse : " ",
        Generic_Configuration: el.Generic_Configuration
          ? el?.Generic_Configuration
          : " ",
        Branded_Generic: el.Branded_Generic ? el?.Branded_Generic : " ",
        Pack_Size: el.Pack_Size ? el?.Pack_Size : " ",
        Tier: el.Tier ? el?.Tier : " ",
        specification: el.specification ? el?.specification : " ",
      }));
      // console.log(items);
      const vens = [...new Set(selectedItems?.map((d) => d.vendor))];

      setTheVendorState(vens);
      setTableData(items);
    }
  }, [selectedItems, vendors]);

  const choosePayment = (proVendor) => {
    const pro = vendors.filter((el) => proVendor.value === el.value);
    formik.setFieldValue("vendorPaymentType", pro[0]?.vendorPaymentType);
    formik.setFieldValue("vendorPayment", pro[0]?.vendorPayment);
  };

  useEffect(() => {
    if (pickedVendor) {
      setTheVendorState(pickedVendor);
    }
  }, [pickedVendor, vendors]);

  useEffect(() => {
    if (vendorNumber) {
      setTheVendorState(vendorNumber);
    }
  }, [vendorNumber, vendors]);

  useEffectOnce(() => {
    // console.log(initialTableData);
    if (initialTableData?.length > 0) {
      setTableData(
        initialTableData.map((item) => ({
          id: `id${String(Date.now())}`,
          barcode: item.barcode,
          itemName: item.itemName,
          vendor: `vendor${String(Date.now())}`,
          RFQId: `rfq${String(Date.now())}`,
          requestid: `REQ${String(Date.now())}`,
          warehouse: item.Warehouse ? item.Warehouse : " ",
          unitCost: item.UnitCost,
          quantity: item?.quantityApproved
            ? item?.quantityApproved
            : item?.quantityRequested
            ? item?.quantityRequested
            : item.quantity,

          RegDate: format(new Date(), "yyyy-MM-dd"),
          selected: false,
          amount: item.UnitCost,
          Generic_Configuration: item.Generic_Configuration
            ? item?.Generic_Configuration
            : " ",
          Branded_Generic: item.Branded_Generic ? item?.Branded_Generic : " ",
          Pack_Size: item.Pack_Size ? item?.Pack_Size : " ",
          Tier: item.Tier ? item?.Tier : " ",
          specification: item.specification
            ? item?.specification
            : item?.description
            ? item?.description
            : " ",
        }))
      );
    }

    if (incomingAttachments) {
      setAttachments(incomingAttachments);
    }
  });

  // GET FORM PERMISION =====================================================
  const getNumberSystems = async () => {
    let response = await fetch(`${backendUrl}/api/numbersystem`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    if (data.numberSystems) {
      const permissions = JSON.parse(
        data.numberSystems.find(
          (el) => el?.documentType === formik.values?.type
        )?.selectedSignatory || JSON.stringify([])
      );

      if (permissions?.length > 0) {
        setReceiver(permissions[1]);
        formik.setFieldValue("department", permissions[1].department || "All");
        formik.setFieldValue("recipient", permissions[1].staff);
        formik.setFieldValue("jobGrade", permissions[1].jobGrade);
      }
    }
    return data;
  };

  const { refetch: refetchPermissions } = useQuery(
    ["NUMBER_SYSTEMS", formik.values?.type],
    () => getNumberSystems(formik.values?.type),
    {
      keepPreviousData: true,
      //  staleTime: 1000 * 60 * 60 * 24,
    }
  );
  // =========================================================================

  useEffect(() => {
    if (formik.values.vendorPaymentType === "Post_Payment") {
      formik.setFieldValue(
        "paymentDueDate",
        addDays(new Date(), +Number(formik.values.vendorPayment))
      );
    } else if (formik.values.vendorPaymentType === "Pre_Payment") {
      formik.setFieldValue("paymentDueDate", new Date());
    }
  }, [, formik.values.vendorPayment, formik.values.vendorPaymentType]);

  function handleFile({ eventData: e, type }) {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async function(e) {
        const workbook = read(e.target.result, {
          cellDates: true,
          dateNF: "yyyy-MM-dd HH:mm:ss.SSS",
        });
        let rows = utils.sheet_to_json(
          workbook.Sheets[workbook.SheetNames[0]],
          {
            defval: "",
            dateNF: "yyyy-MM-dd HH:mm:ss.SSS",
          }
        );

        if (
          !(await ConfirmDialog({
            title: "Are you sure?",
            description: "You want to import",
          }))
        ) {
          return;
        }
        // TODO - validate excel
        let schema = yup.object().shape({
          items: yup.array().of(
            yup.object({
              Item_Code: yup.string().required(),
              Item_Name: yup.string().required(),
              Unit_Cost: yup.string().required(),
              Quantity: yup.string().default("0"),
              Pack_Size: yup.string(),
              Tier: yup.string(),
              Receiving_Officer: yup
                .string()
                .trim()
                .required(),
              Branch: yup.string().required(),
              Address: yup.string().required(),
              Branch_Full_Name: yup.string().required(),
              Phone: yup.string().required(),
              Warehouse: yup.string(),
            })
          ),
        });
        try {
          await schema.validate({ items: rows });

          // General Clean up
          const dataForTable = uniqBy(rows, "Item_Code").map((el) => ({
            // ...el,
            id: `id${String(Date.now())}`,
            barcode: el.Item_Code,
            itemName: el.Item_Name,
            vendor: `vendor${String(Date.now())}`,
            RFQId: `rfq${String(Date.now())}`,
            requestid: `REQ${String(Date.now())}`,
            warehouse: el.Warehouse || " ",
            unitCost: el.Unit_Cost,
            quantity: rows
              .filter((row) => row.Item_Code === el.Item_Code)
              .map((el) => el.Quantity)
              .reduce((a, b) => currency(a).add(b).value, 0),
            RegDate: format(new Date(), "yyyy-MM-dd"),
            selected: false,
            amount: el.Unit_Cost,
            Generic_Configuration: el.Generic_Configuration
              ? el?.Generic_Configuration
              : " ",
            Branded_Generic: el.Branded_Generic ? el?.Branded_Generic : " ",
            Pack_Size: el.Pack_Size ? el?.Pack_Size : " ",
            Tier: el.Tier ? el?.Tier : " ",
            specification: el.specification ? el?.specification : " ",
          }));

          setTableData(dataForTable);

          const dataForDistribution = rows.map((el, index) => ({
            // ...el,
            Branded_Generic: el.Branded_Generic ? el?.Branded_Generic : " ",
            Generic_Configuration: el?.Generic_Configuration
              ? el?.Generic_Configuration
              : " ",
            Pack_Size: el.Pack_Size ? el?.Pack_Size : " ",
            Sendercompany: authUser.company,
            Tier: el.Tier ? el?.Tier : " ",
            barcode: el.Item_Code,
            issueTo: el.Branch.trim(),
            issueToAddress: el.Address,
            issueToCompanyName: el.Branch_Full_Name,
            issueToPhone: el.Phone,
            itemName: el.Item_Name,
            priority: index,
            quantity: el.Quantity,
            receivingofficer: el.Receiving_Officer,
            remaining: el.Quantity,
            sendTo: `${
              backendApis.find((branch) => branch.name === el.Branch.trim())
                ?.url
            }/api/itemissuer/receiver`,
            warehouse: el.Warehouse || " ",
            warehouseName: "",
          }));

          setAllItems(dataForDistribution);
        } catch (err) {
          console.log(err);
          toast.error(err.name);
          toast.error(JSON.stringify(err.errors));
        }
      };
      reader.readAsArrayBuffer(file);
    }
    e.target.value = "";
  }

  const getStaffs = async (name) => {
    try {
      const sendTo = `${
        backendApis.find((el) => el.name === name).url
      }/api/users`;
      const staffs = await fetchActionsUtil(sendTo, "GET");

      staffs.staff = staffs.staff.map((el) => {
        //  console.log(el);
        return {
          ...el,
          value: el.Name,
          label: el.Name,
          company: staffs.company,
        };
      });

      return { [name]: staffs.staff };
    } catch (error) {
      console.log(error);
    }
  };

  const getWarehouse = async (name) => {
    try {
      const sendTo = `${
        backendApis.find((el) => el.name === name).url
      }/api/warehouse`;
      const data = await fetchActionsUtil(sendTo, "GET");

      data.warehouses = data.warehouses.map((el) => {
        //  console.log(el);
        return {
          ...el,
          value: el.W_ID,
          label: el.W_name,
        };
      });

      return { [name]: data.warehouses };
    } catch (error) {
      console.log(error);
    }
  };

  const getEditPurchaseOrder = async () => {
    try {
      setLoading(true);
      const poData = await fetchActionsUtil(
        `${backendUrl}/api/vendors/get-edit-PO/${editPurchaseOrder}`
      );

      const { tableData, allItems, item } = poData;

      const itemData = {
        title: item?.POName,
        type: "PO",
        department: "",
        recipient: "",
        jobGrade: "",
        comment: "",
        currency: generalSettings?.prevailingCurrency,
        dueDate: moment(new Date(item?.dueDate)),
        expectedDeliveryDate: new Date(),
        vendorPaymentType: item.vendorPaymentType,
        vendorPayment: item.vendorPayment,
        paymentDueDate: moment(new Date(item?.paymentDueDate)),
        SendVendorEmail: item?.SendVendorEmail,
      };

      let dataTable = [];

      if (tableData?.length > 0) {
        dataTable = tableData?.map((el) => {
          return {
            id: el?.id,
            barcode: el?.barcode,
            itemName: el?.itemName,
            vendor: el?.vendor,
            RFQId: `rfq${String(Date.now())}`,
            requestid: el?.requestid,
            warehouse: el.warehouse ? el.warehouse : " ",
            unitCost: el.unitCost,
            quantity: el?.quantity,
            RegDate: format(new Date(), "yyyy-MM-dd"),
            selected: false,
            amount: el.amount,

            Generic_Configuration: el.Generic_Configuration
              ? el?.Generic_Configuration
              : " ",
            Branded_Generic: el.Branded_Generic ? el?.Branded_Generic : " ",
            Pack_Size: el.Pack_Size ? el?.Pack_Size : " ",
            Tier: el.Tier ? el?.Tier : " ",
            specification: el.specification ? el?.specification : " ",
          };
        });
      }

      let staffs = [];
      let warehouses = [];

      if (allItems?.length > 0) {
        const cams = [...new Set(allItems.map((d) => d.issueTo))];
        staffs = await Promise.all(
          cams.map((el) => {
            return new Promise(async (resolve, reject) => {
              try {
                const details = await getStaffs(el);

                resolve({ ...details });
              } catch (error) {
                console.log(error);
                reject(error);
              }
            });
          })
        );

        warehouses = await Promise.all(
          cams.map((el) => {
            return new Promise(async (resolve, reject) => {
              try {
                const details = await getWarehouse(el);

                resolve({ ...details });
              } catch (error) {
                console.log(error);
                reject(error);
              }
            });
          })
        );

        staffs = staffs.reduce(function(result, item) {
          var key = Object.keys(item)[0]; //first property: a, b, c
          result[key] = item[key];
          return result;
        }, {});

        warehouses = warehouses.reduce(function(result, item) {
          var key = Object.keys(item)[0]; //first property: a, b, c
          result[key] = item[key];
          return result;
        }, {});
      }

      formik.setValues(itemData, false);
      setEditPOData({
        requestid: item?.requestid,
        POId: item?.POId,
        vendor: item?.vendor,
      });
      setTableData(dataTable);
      setAllItems(allItems?.length > 0 ? allItems : []);
      setSavedStaff(isEmpty(staffs) ? {} : staffs);
      setSavedWarehouse(isEmpty(warehouses) ? {} : warehouses);
      item?.attachments && setAttachments(JSON.parse(item?.attachments));

      // console.log(poData, staffs);
    } catch (err) {
      console.log(err);
      toast.error(`Unable to perform action`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (editPurchaseOrder) {
      getEditPurchaseOrder();
    }
  }, [editPurchaseOrder]);

  useEffect(() => {
    if (editPurchaseOrder) {
      const ved = vendors.find((d) => editPOData?.vendor === d.value);
      setSelectedVendors(ved);
      // console.log(ved, editPOData?.vendor, vendors);
    }
  }, [vendors, editPOData?.vendor]);

  const sendDeleteImage = async (payload, index) => {
    try {
      setIsLoading(true);
      let response = await fetch(
        `${backendUrl}/api/vendors/delete-initial-file`,
        {
          method: "POST",
          credentials: "include",
          body: JSON.stringify(payload),
          headers: {
            Accept: "Application/json",
            "Content-Type": "Application/json",
          },
        }
      );
      if (!response.ok) {
        response = await response.json();
        throw new Error(response.message);
      }
      const res = await response.json();
      setAttachments(attachments.filter((el, i) => i !== index));

      return res;
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteImage = async (index) => {
    const file = attachments.find((el, i) => i === index);
    if (file?.fileId) {
      if (
        await ConfirmDialog({
          title: "Delete",
          description: `Do you want to delete this file - ${
            file.name
          }, you can't undo this`,
        })
      ) {
        const res = await sendDeleteImage(
          { file, requestid: editPurchaseOrder },
          index
        );
        toast.success(res?.message);
      }
    } else {
      setAttachments(attachments.filter((el, i) => i !== index));
    }
  };

  const uploadFileApi = async (payload) => {
    const formData = new FormData();
    const alreadyUploadedFilesInitialFiles = [];
    for (const file of payload.initialFiles) {
      if (!file?.fileId) {
        formData.append("initialFiles", file);
      } else {
        alreadyUploadedFilesInitialFiles.push(file);
      }
    }

    if (!isEmpty(alreadyUploadedFilesInitialFiles)) {
      formData.append(
        "alreadyUploadedFilesInitialFiles",
        JSON.stringify(alreadyUploadedFilesInitialFiles)
      );
    }

    let response = await fetch(`${backendUrl}/api/vendors/upload-files`, {
      method: "POST",
      body: formData,
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const res = await response.json();
    return res;
  };

  const uploadFileMutation = useMutation((payload) => uploadFileApi(payload), {
    onSuccess: async ({ message, imageFiles }) => {
      toast.success(message);
      setAttachments(imageFiles);
    },
    onError: () => {
      toast.error(`Unable to perform action`);
    },
  });

  const getAllWarehouse = useMemo(() => {
    return warehouse?.warehouses
      ? [
          {
            label: "Select Warehouse",
            value: " ",
          },
          ...warehouse?.warehouses?.map((el) => ({
            label: el?.W_name,
            value: el?.W_ID,
          })),
        ]
      : [];
  }, [warehouse?.warehouses]);

  return (
    <div key={key}>
      <Modal
        show={ShowNewRequisitionModal}
        onHide={() => setShowNewRequisitionModal(false)}
        dialogClassName="requisition-details-modal preview-modal"
        backdropClassName={`global-backdrop`}
        centered={true}
        animation={false}
        enforceFocus={false}
        key={key}
        fullscreen={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h1 className="py-2 capitalize">{`${
              editPurchaseOrder ? "Edit" : "New"
            } Purchase Order`}</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="preview p-0">
          <div className="preview-content p-0">
            <div className="content">
              <div className="preview-body">
                <Form
                  className="mb-3"
                  id="requisition-form"
                  noValidate
                  onSubmit={formik.handleSubmit}
                >
                  <div className="row">
                    <Form.Group className="col-8 mb-3">
                      <Form.Label>Title :</Form.Label>
                      <Form.Control
                        name="title"
                        placeholder="Enter a title"
                        value={formik.values.title}
                        onChange={formik.handleChange}
                        isInvalid={
                          formik.touched.title && !!formik.errors.title
                        }
                      />

                      <Form.Control.Feedback type="invalid">
                        {formik.errors.title}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="col-4 mb-3">
                      <Form.Label htmlFor="dueDate">Due Date:</Form.Label>
                      <Datetime
                        dateFormat="MMM DD, YYYY"
                        timeFormat={false}
                        closeOnSelect={true}
                        closeOnClickOutside={true}
                        name="dueDate"
                        inputProps={{
                          className: `date-input form-control ${
                            formik.touched.dueDate && !!formik.errors.dueDate
                              ? "is-invalid"
                              : ""
                          }`,
                          placeholder: "Select date",
                          readOnly: true,
                        }}
                        value={formik.values.dueDate}
                        onChange={(date) => {
                          formik.setFieldValue("dueDate", date, true);
                        }}
                        onBlur={() => formik.setFieldTouched("BOB", true)}
                      />
                      {formik.touched.dueDate && !!formik.errors.dueDate ? (
                        <span className="custom-invalid-feedback">
                          {formik.errors.dueDate}
                        </span>
                      ) : null}
                    </Form.Group>
                  </div>

                  <Form.Group className="mb-3">
                    <Form.Label>Vendors (Verified):</Form.Label>
                    <Select
                      closeMenuOnSelect={true}
                      isLoading={isLoadingVendors}
                      isDisabled={
                        selectedItems
                          ? true
                          : vendorNumber
                          ? true
                          : disableVendor
                          ? true
                          : false
                      }
                      isSearchable={true}
                      name="vendor"
                      options={vendors}
                      value={selectedVendors}
                      onChange={(selected) => {
                        setSelectedVendors(selected);
                        choosePayment(selected);
                        if (planItems) {
                          fetchPrice(selected.value);
                        }
                      }}
                    />
                  </Form.Group>

                  <div className="row">
                    <Form.Group className="col-5 mb-3">
                      <Form.Label>Payment Type</Form.Label>
                      <Select
                        classNamePrefix="form-select"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        placeholder="Payment Type"
                        isSearchable={false}
                        value={VendorPayment.find(
                          (d) => d.value === formik.values.vendorPaymentType
                        )}
                        options={VendorPayment}
                        onChange={(selected) => {
                          formik.setFieldValue(
                            "vendorPaymentType",
                            selected.value
                          );
                        }}
                      />
                      {formik.touched.vendorPaymentType &&
                      !!formik.errors.vendorPaymentType ? (
                        <span className="custom-invalid-feedback">
                          {formik.errors.vendorPaymentType}
                        </span>
                      ) : null}
                    </Form.Group>

                    {(formik.values.vendorPaymentType === "Part_Payment" ||
                      formik.values.vendorPaymentType === "Post_Payment") && (
                      <>
                        <Form.Group className="col-3 mb-3">
                          <Form.Label>
                            {formik.values.vendorPaymentType === "Part_Payment"
                              ? "Part Payment Percentage (%)"
                              : "Payment Due In (Days)"}
                          </Form.Label>
                          <Form.Control
                            type="number"
                            className="form-control"
                            value={formik.values.vendorPayment}
                            placeholder={
                              formik.values.vendorPaymentType === "Post_Payment"
                                ? "Days"
                                : "Percentage"
                            }
                            // allowNegativeValue={false}
                            // allowDecimals={true}
                            name="vendorPayment"
                            onChange={formik.handleChange}
                          />
                          {formik.touched.vendorPayment &&
                          !!formik.errors.vendorPayment ? (
                            <span className="custom-invalid-feedback">
                              {formik.errors.vendorPayment}
                            </span>
                          ) : null}
                        </Form.Group>
                        <div className="col-4">
                          <Form.Group>
                            <Form.Label>Payment Due date</Form.Label>
                            <Datetime
                              timeFormat={false}
                              closeOnSelect={true}
                              closeOnClickOutside={true}
                              dateFormat="MMM DD, YYYY"
                              name="paymentDueDate"
                              inputProps={{
                                className: `date-input form-control ${
                                  formik.touched.paymentDueDate &&
                                  !!formik.errors.paymentDueDate
                                    ? "is-invalid"
                                    : ""
                                }`,
                                placeholder: "Select date",
                                readOnly: true,
                              }}
                              value={formik.values.paymentDueDate}
                              onChange={(date) => {
                                formik.setFieldValue(
                                  "paymentDueDate",
                                  date,
                                  true
                                );
                              }}
                              onBlur={() =>
                                formik.setFieldTouched("paymentDueDate", true)
                              }
                            />
                          </Form.Group>
                        </div>{" "}
                      </>
                    )}
                  </div>

                  <fieldset className="html-fieldset my-4">
                    <legend className="html-legend">Internal Approval</legend>
                    <div className="row gap-3">
                      <Form.Group className="col mb-3">
                        <Form.Label>Department :</Form.Label>
                        <Select
                          classNamePrefix={`form-select`}
                          name="department"
                          options={staffSelectOptions?.departments}
                          value={staffSelectOptions?.departments.find(
                            (el) => el.value === formik.values.department
                          )}
                          onChange={({ value }) => {
                            formik.setFieldValue("department", value);

                            formik.setFieldValue("jobGrade", "");
                            formik.setFieldValue("recipient", "");
                          }}
                          isDisabled={reciever?.department}
                        />
                      </Form.Group>

                      <Form.Group className="col mb-3">
                        <Form.Label>Job Grade :</Form.Label>
                        <Select
                          classNamePrefix={`form-select`}
                          name="jobGrade"
                          options={staffSelectOptions?.jobGrades}
                          value={staffSelectOptions?.jobGrades.find(
                            (el) => el.value === formik.values.jobGrade
                          )}
                          onChange={({ value }) => {
                            formik.setFieldValue("jobGrade", value);

                            formik.setFieldValue("recipient", "");
                          }}
                          isDisabled={
                            reciever?.jobGrade ||
                            reciever?.staff ||
                            reciever?.department === "Department Lead"
                          }
                        />
                      </Form.Group>

                      <Form.Group className="col mb-3">
                        <Form.Label>Recipient :</Form.Label>
                        <Select
                          classNamePrefix={`form-select`}
                          name="recipient"
                          options={staffSelectOptions?.staffs}
                          value={staffSelectOptions?.staffs.find(
                            (el) => el.value === formik.values.recipient
                          )}
                          onChange={({ value }) =>
                            formik.setFieldValue("recipient", value)
                          }
                          isDisabled={reciever?.staff}
                        />
                      </Form.Group>
                    </div>
                  </fieldset>

                  <Form.Group className="mb-3">
                    <Form.Label>Comment :</Form.Label>
                    <label className="border p-0 rounded message-area d-block">
                      <Form.Control
                        name="comment"
                        value={formik.values.comment}
                        onChange={formik.handleChange}
                        as="textarea"
                        className="w-100 border-0 p-3"
                        placeholder="Enter a comment"
                        rows={4}
                      />
                    </label>
                  </Form.Group>
                </Form>

                <div className="my-2" />

                <div className="d-flex justify-content-end mb-3 gap-3">
                  {/*  <Dropdown>
                    <Dropdown.Toggle
                      variant=""
                      className="btn print"
                      bsPrefix=""
                    >
                      Import <ExcelIcon color="#008000" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      popperConfig={{
                        strategy: "fixed",
                      }}
                      renderOnMount
                      className="text-center"
                    >
                      <Dropdown.Item
                        as="label"
                        className="p-cursor"
                        title="Import"
                      >
                        <input
                          type="file"
                          className="d-none"
                          onChange={(e) =>
                            handleFile({
                              eventData: e,
                              type: "Sales Inventory",
                            })
                          }
                          accept=".xlsx"
                        />
                        Sales Inventory
                      </Dropdown.Item>
                      <Dropdown.Item
                        as="label"
                        className="p-cursor"
                        title="Import"
                      >
                        <input
                          type="file"
                          className="d-none"
                          onChange={(e) =>
                            handleFile({
                              eventData: e,
                              type: "Asset",
                            })
                          }
                          accept=".xlsx"
                        />
                        Asset
                      </Dropdown.Item>
                      <Dropdown.Item
                        as="label"
                        className="p-cursor"
                        title="Import"
                      >
                        <input
                          type="file"
                          className="d-none"
                          onChange={(e) =>
                            handleFile({ eventData: e, type: "Consumables" })
                          }
                          accept=".xlsx"
                        />
                        Consumables
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown> */}

                  {/*  <a
                    href={`/excel_templates/INVEX_INSERT_PURCHASE_ORDER_ITEMS_TEMPLATE.xlsx`}
                    target="blank"
                    title="Download"
                    download=""
                    className="btn border"
                  >
                    <DownloadIcon /> Download Template
                  </a> */}
                </div>

                <Table responsive borderless striped className="product-table">
                  <thead>
                    <tr>
                      <th />
                      <th>S/N</th>
                      <th>Item Code</th>
                      <th>Item Name</th>
                      <th>Specification</th>

                      {generalSettings?.pharmacySetting && (
                        <>
                          {/* <th className="d-none">Generic Configuration</th>
                          <th className="d-none">Branded Generic</th> */}
                          <th>Pack Size</th>
                          <th>Tier</th>
                        </>
                      )}

                      <th>Unit Cost</th>
                      <th>Quantity</th>
                      <th>Subtotal</th>
                      <th>Warehouse</th>
                    </tr>
                  </thead>
                  <tbody ref={ref}>
                    {tableData &&
                      tableData.map((el, index) => (
                        <tr
                          key={index}
                          className={`${activeTableIndex === index &&
                            "active-row"}`}
                          onClick={() =>
                            activeTableIndex !== index &&
                            setActiveTableIndex(index)
                          }
                        >
                          <td>
                            <DotsHorizontalIcon
                              className="p-cursor"
                              onClick={() => {
                                setSelectedPO(el);
                                setShowWarehousePO(true);
                              }}
                            />
                          </td>
                          <td>{index + 1}</td>
                          <td
                            className="p-cursor"
                            // onClick={(e) => handleRowClick(e, index)}
                          >
                            {/* {el.barcode} */}
                            <Form.Control
                              // as="textarea"
                              // rows={3}
                              className="form-control border-2 px-1 bg-white col-6"
                              name="barcode"
                              value={el?.barcode}
                              onKeyDown={(e) =>
                                e.keyCode === 13 && e.target.blur()
                              }
                              onChange={(e) =>
                                handleInputChange({
                                  index,
                                  name: "barcode",
                                  value: e.target.value,
                                })
                              }
                              disabled={
                                incomingAttachments ||
                                el?.disable ||
                                selectedItems
                              }
                            />
                          </td>
                          <td>
                            {/* {el.itemName}  */}

                            <Form.Control
                              // as="textarea"
                              // rows={3}
                              className="form-control border-2 bg-white"
                              name="itemName"
                              value={el?.itemName}
                              onKeyDown={(e) =>
                                e.keyCode === 13 && e.target.blur()
                              }
                              onChange={(e) =>
                                handleInputChange({
                                  index,
                                  name: "itemName",
                                  value: e.target.value,
                                })
                              }
                              disabled={
                                incomingAttachments ||
                                el?.disable ||
                                selectedItems
                              }
                            />
                          </td>
                          <td>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              className="form-control border-2 px-1 bg-white col-6"
                              name="specification"
                              value={el?.specification}
                              onKeyDown={(e) =>
                                e.keyCode === 13 && e.target.blur()
                              }
                              onChange={(e) =>
                                handleInputChange({
                                  index,
                                  name: "specification",
                                  value: e.target.value,
                                })
                              }
                              disabled={
                                incomingAttachments ||
                                el?.disable ||
                                selectedItems
                              }
                            />
                          </td>

                          {generalSettings?.pharmacySetting && (
                            <>
                              {/* <td className="d-none">
                                {el?.Generic_Configuration}
                              </td>
                              <td className="d-none">{el?.Branded_Generic}</td> */}
                              <td>{el?.Pack_Size}</td>
                              <td>{el?.Tier}</td>
                            </>
                          )}

                          <td>
                            <CurrencyInput
                              className="form-control border-2 px-1"
                              value={el.unitCost}
                              onKeyDown={(e) =>
                                e.keyCode === 13 && e.target.blur()
                              }
                              name="unitCost"
                              onValueChange={(value, name) =>
                                handleInputChange({
                                  index,
                                  name,
                                  value,
                                })
                              }
                              //    disableGroupSeparators
                              decimalsLimit={2}
                              disabled={selectedItems}
                            />
                          </td>
                          <td>
                            <CurrencyInput
                              className="form-control border-2 px-1"
                              value={el.quantity}
                              onKeyDown={(e) =>
                                e.keyCode === 13 && e.target.blur()
                              }
                              name="quantity"
                              onValueChange={(value, name) =>
                                handleInputChange({
                                  index,
                                  name,
                                  value,
                                })
                              }
                              //  disableGroupSeparators
                              // allowNegativeValue={false}
                              // allowDecimals={true}
                              disabled={selectedItems}
                            />
                          </td>
                          <td>
                            <CurrencyInput
                              className="form-control border-0 px-0"
                              name="subtotal"
                              disabled
                              value={
                                !isNaN(el.unitCost * el.quantity)
                                  ? currency(el.unitCost, {
                                      precision: 2,
                                    }).multiply(el.quantity)
                                  : 0
                              }
                              onKeyDown={(e) =>
                                e.keyCode === 13 && e.target.blur()
                              }
                              onValueChange={(value, name) =>
                                handleInputChange({
                                  index,
                                  name,
                                  value,
                                })
                              }
                              // disableGroupSeparators
                              decimalsLimit={2}
                            />
                          </td>
                          <td>
                            <Select
                              styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  width: "12rem",
                                }),
                              }}
                              classNamePrefix={`form-select-store`}
                              menuPosition="fixed"
                              isSearchable={false}
                              placeholder="Select Warehouse"
                              options={getAllWarehouse}
                              value={getAllWarehouse?.find(
                                (details) => details.value === el?.warehouse
                              )}
                              onChange={({ value }) =>
                                handleInputChange({
                                  index,
                                  name: "warehouse",
                                  value,
                                })
                              }
                              isDisabled={
                                incomingAttachments ||
                                el?.disable ||
                                selectedItems
                              }
                            />
                          </td>
                        </tr>
                      ))}
                    {!selectedItems && (
                      <tr
                        className={`p-cursor ${activeTableIndex === -1 &&
                          "active-row"}`}
                      >
                        <td onClick={(e) => createNew(e)}>
                          <span className="">
                            {/* <MagnifyIcon /> */}
                            <button className="btn btn-primary">+Add</button>
                          </span>
                        </td>
                        <td />
                        <td />
                        <td />
                        <td />
                        {generalSettings?.pharmacySetting && (
                          <>
                            {/* <td />
                            <td /> */}
                            <td />
                            <td />
                          </>
                        )}
                        <td />
                        <td />
                        <td />
                        <td>
                          <span className="">
                            <button
                              className="btn btn-primary"
                              onClick={enterNewItem}
                            >
                              Enter
                            </button>
                          </span>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>

                <Overlay
                  show={show}
                  onHide={() => setShow(false)}
                  target={target}
                  container={document.body}
                  containerPadding={0}
                  rootClose={true}
                  popperConfig={{
                    strategy: "fixed",
                    modifiers: [
                      {
                        name: "ga-custom",
                        enabled: true,
                        phase: "beforeWrite",
                        fn({ state }) {
                          const oldTransfrom = String(
                            state.styles.popper?.transform
                          );
                          const y = Number(
                            String(oldTransfrom)
                              .split(",")[1]
                              .split(")")[0]
                              .split("px")[0]
                              .trim()
                          );

                          const x = Number(
                            String(oldTransfrom)
                              .split(",")[0]
                              .split("(")[1]
                              .split("px")[0]
                              .trim()
                          );

                          //  console.log(x, y, y < -210);

                          // state.styles.popper.left = `50%`;
                          // state.styles.popper.top = `50%`;
                          state.styles.popper.transform =
                            y < -210
                              ? `translate(${x}px, ${-210}px)`
                              : `translate(${x}px, ${y}px)`;
                        },
                      },
                    ],
                  }}
                >
                  <Popover
                    style={{
                      maxWidth: "unset",
                      height: "28.75rem",
                      overflow: "hidden",
                      boxShadow: "0px 4px 20px 0px #131B2814",
                    }}
                  >
                    <ItemsTable
                      handleSelectedItem={handleSelectedItem}
                      type="true"
                      withVendorUnitCost={true}
                      Vendor_ID={selectedVendors?.value}
                    />
                  </Popover>
                </Overlay>

                {/*  */}

                <div className="mb-4">
                  <p className="mb-4"> Attachments: </p>
                  <FilesAttachments
                    files={attachments.map((el) =>
                      !el?.fileId
                        ? el
                        : {
                            ...el,
                            modifiedSystemFileName: `${el.systemFileName}/${
                              el.name
                            }`,
                          }
                    )}
                    setFiles={(files) => setAttachments(files)}
                    colWidth="3"
                    url={`${backendUrl}/api/${
                      incomingAttachments ? "requisition" : "vendors"
                    }/files`}
                    // upload={() =>
                    //   uploadFileMutation.mutate({
                    //     initialFiles: attachments,
                    //   })
                    // }
                    deleteImage={deleteImage}
                    isLoading={uploadFileMutation?.isLoading || IsLoading}
                    viewOnly={incomingAttachments}
                  />
                </div>

                {/*  */}
              </div>

              {tableData.length > 0 && (
                <div className="d-flex justify-content-end mb-3 mx-3">
                  <div className="gridChild gridChildBorderLeftBlue">
                    <div className="gridChildLeft">
                      <p className="gridChld1">
                        {currency(
                          tableData.reduce(
                            (a, b) => a + b.unitCost * b.quantity,
                            0
                          ),
                          {
                            symbol: "",
                          }
                        ).format()}
                      </p>
                      <p className="gridChld2">Total</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="px-4 bg-white">
          {![null, -1].includes(activeTableIndex) ? (
            !selectedItems && (
              <Button
                disabled={createRequisitionMutation.isLoading}
                onClick={() => removeSelection()}
                variant="danger"
                size="sm"
                className="text-white px-3 "
              >
                Remove Selection
              </Button>
            )
          ) : (
            <span />
          )}

          <div
            className={
              editPurchaseOrder
                ? ""
                : "d-flex justify-content-between w-100 mt-4"
            }
          >
            {editPurchaseOrder ? null : (
              <div>
                <Button
                  disabled={daftPO.isLoading}
                  variant="outline-primary"
                  size="sm"
                  type="button"
                  onClick={holdOneSomeTime}
                >
                  {daftPO.isLoading ? "Please wait..." : "Draft Purchase Order"}
                </Button>
              </div>
            )}

            <div className="d-flex align-items-center">
              <div className="mx-4">
                <Form.Check
                  type="switch"
                  name="SendVendorEmail"
                  className="custom-form-check"
                  checked={Boolean(formik.values.SendVendorEmail)}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "SendVendorEmail",
                      !formik.values.SendVendorEmail
                    )
                  }
                  label="Send Email To Vendor"
                />
              </div>

              <Button
                disabled={createRequisitionMutation.isLoading}
                variant="primary"
                size="sm"
                type="button"
                form="requisition-form"
                onClick={() => formik.submitForm()}
              >
                {createRequisitionMutation.isLoading
                  ? "Please wait..."
                  : "Send for approval"}
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
      {showWarehousePO && (
        <AddWarehousePOModal
          show={showWarehousePO}
          onHide={setShowWarehousePO}
          mainData={selectedPO}
          allItems={allItems}
          setAllItems={setAllItems}
          savedStaff={savedStaff}
          setSavedStaff={setSavedStaff}
          savedWarehouse={savedWarehouse}
          setSavedWarehouse={setSavedWarehouse}
        />
      )}
      <ModalLoader show={loading} />
    </div>
  );
}
