import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { useState, useEffect, useCallback, useMemo } from "react";
import { useMutation, useQueries, useQuery } from "react-query";
import { toast } from "react-toastify";
import Select from "react-select";

import { useAuth } from "../../hooks/useAuth";
import { sendFormData, fetchActionsUtil, waitFor } from "../../utils/helpers";
import ConfirmDialog from "../ConfirmDialogue";
import Avatar from "../utils/Avatar";
import { appSettings, backendApis } from "../../config";
import CloseIcon from "mdi-react/CloseIcon";
import { differenceBy, filter, find, isEmpty, uniqBy } from "lodash";
import { uniq } from "lodash";
import { queryActions } from "../../utils/reactQueryActions";

const apis = [backendApis];

const initials = {
  profilePics: "",
  Name: "",
  AccessLavel: "",
  Warehouse: "",
  username: "",
  Department: "",
  email: "",
  Branch: "",
  Staff_ID: "",
  userType: "core",
  initialUserType: "",
};

const allDepartment = [
  "",
  "Admin",
  "Accountant",
  "Cashier",
  // "Content Management",
  "Contracts",
  "HR",
  "Sales",
  "Waiter",
  // "Shout Out",
  "Store",
  "Warehouse",
  "Production",
  "Sales Manager",
  "Operations",
  "Business Development",
  "Procurement",
  "QHSE",
  "Maintenance",
  "Document Control",
  "Government InvexERP",
  "Supply and Logistics",
  "Hospital Management Board",
  "Primary Healthcare Center",
  "Ministry of Health",
];

const alLevels = ["", "High", "Low"];

const branches = ["", "HQ", "Small Branch"];

const userType = ["core", "passive"];

const EditUserAccountModal = (props) => {
  const { backendUrl, brachData, token, user: authUser } = useAuth();

  const [inputData, setInputData] = useState(initials);

  const [defaultDepartmentValue, setDepartmentValue] = useState(null);
  //   const [userData, setUserData] = useState({});
  const [selectedBranch, setSelectedBranch] = useState({
    Branch: [],
    editedBranch: [],
    createdBranch: [],
    deletedBranch: [],
  });

  const [verifyEmail, setVerifyEmail] = useState(false);

  // const fechIt = useCallback(async () => {
  //   const getData = await fetchActionsUtil(
  //     `${backendUrl}/api/users/${props.userId}`,
  //     "GET"
  //   );

  //   const {
  //     Name,
  //     AccessLavel,
  //     Warehouse,
  //     username,
  //     Department,
  //     email,
  //     Branch,
  //     Title,
  //     userType,
  //   } = getData?.data;
  //   setInputData({
  //     Name: Name ? Name : "",
  //     AccessLavel: AccessLavel ? AccessLavel : "",
  //     Warehouse: Warehouse ? Warehouse : "",
  //     username: username ? username : "",
  //     Department: Department ? Department : "",
  //     email: email ? email : "",
  //     Branch: Branch ? Branch : "",
  //     profilePics: "",
  //     Staff_ID: props.userId,
  //     Title: Title ? Title : "",
  //     userType: userType ? userType : "core",
  //     initialUserType: userType ? userType : "core",
  //   });

  //   setDepartmentValue({ label: Department, value: Department });
  // }, [backendUrl, props.userId]);

  // useEffect(() => {
  //   props.userId && fechIt();
  // }, [props.userId, fechIt, props.show]);

  const fetchcompanyDetails = async (url) => {
    const res = await fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
      },
    });

    const data = await res.json();

    let companyData = data?.company;

    return companyData;
  };

  const company = useQuery(
    ["GET_COMPANY_DETAILS"],
    () => fetchcompanyDetails(`${backendUrl}/api/company/details`),
    {}
  );

  const fetchAllBranchStaff = async ({ endpoint, branch }) => {
    try {
      const res = await fetch(endpoint);

      const data = await res.json();

      return { ...data, url: endpoint, branch: branch };
    } catch (error) {
      throw new Error(`Failed to fetch ${endpoint}`);
    }
  };

  const queries = useQueries(
    apis
      ?.map((company) =>
        company.map((api) => ({
          queryKey: ["user", api.name],

          queryFn: () =>
            fetchAllBranchStaff({
              endpoint: `${api.url}/api/users`,
              branch: api.name,
            }),
        }))
      )
      .flat()
  );

  const staffUserDetails = useMemo(() => {
    try {
      let userBranches = [];
      if (
        !isEmpty(queries) &&
        queries.length > 0
        //&&
        //  process.env.REACT_APP_SITE_TITLE === "InvexERP"
      ) {
        const companies = queries?.filter((query) => query.data);

        const staff = companies;

        const companyStaff = staff
          ?.map((user) => {
            const url = user?.data?.url;
            const branch = user?.data?.branch;
            return user?.data?.staff?.map((staf) => {
              if (url && branch) {
                return { ...staf, url: url, Branch: branch };
              }
            });
          })
          .flat();

        let uniqStaff = uniqBy(companyStaff, "username");

        function findStaffBranch(username) {
          let findStaffBranch = companyStaff?.filter(
            (staff) => staff.username === username
          );

          findStaffBranch = findStaffBranch?.map((branch) => ({
            branch: branch.Branch,
            url: branch.url,
          }));

          return findStaffBranch;
        }

        uniqStaff = uniqStaff.map((staff) => {
          const staffBranchFound = findStaffBranch(staff?.username);
          return { ...staff, Branch: staffBranchFound };
        });

        uniqStaff = uniqStaff.map((staff) => ({
          ...staff,
          Branch: uniqBy(staff?.Branch, "branch"),
        }));

        uniqStaff = uniqStaff.filter(
          (branch) => branch?.Staff_ID === props?.userId
        );

        function findUserBranches(username) {
          return companyStaff?.filter((url) => url?.username === username);
        }

        userBranches = uniqStaff.map((branch) => {
          const found = findUserBranches(branch?.username);

          const urls = found.map((url) => url?.url);
          return { ...branch, url: urls };
        });
      }

      const remainingOtherBranchUsers = userBranches?.filter(
        (staff) => staff?.username?.toLowerCase() !== "support"
      ).length;

      if (process.env.REACT_APP_SITE_TITLE === "InvexERP") {
        let uniqueSTaffWithStaffType = userBranches?.map((user) => ({
          ...user,
          userType: user?.userType ? user?.userType : "core",
        }));

        // uniqueSTaffWithStaffType = uniqueSTaffWithStaffType?.filter(
        //   (staff) => staff?.username?.toLowerCase() !== "support"
        // );

        const totalCompanyCoreUsers =
          company?.data?.coreUsers || company?.data?.Num_Users;
        const totalCompanyPassiveUsers = company?.data?.passiveUsers || 0;

        const currentCoreUsers = uniqueSTaffWithStaffType?.filter(
          (user) => user?.userType === "core"
        ).length;

        const currentPassiveUsers = uniqueSTaffWithStaffType?.filter(
          (user) => user?.userType === "passive"
        ).length;

        return {
          staff: uniqueSTaffWithStaffType,
          activatedCoreUsers: currentCoreUsers,
          activatedPassiveUsers: currentPassiveUsers,
          totalCoreUsers: totalCompanyCoreUsers,
          totalPassiveUsers: totalCompanyPassiveUsers,
          otherBranchTotalStaff: remainingOtherBranchUsers,
          totalUsers: company?.data?.Num_Users,
        };
      } else {
        // let uniqueSTaffWithStaffType = userBranches?.filter(
        //   (staff) => staff?.username?.toLowerCase() !== "support"
        // );
        let uniqueSTaffWithStaffType = userBranches;
        return {
          // staff: remainingOtherBranchUsers,
          // totalUsers: company?.data?.Num_Users,

          staff: uniqueSTaffWithStaffType,
          otherBranchTotalStaff: remainingOtherBranchUsers,
          totalUsers: company?.data?.Num_Users,
        };
      }
    } catch (err) {
      console.log(err);
    }
  }, [queries, company?.data, props?.userId]);

  useEffect(() => {
    if (staffUserDetails?.staff[0]) {
      const Name = staffUserDetails?.staff[0]?.Name || "";
      const AccessLavel = staffUserDetails?.staff[0]?.AccessLavel || "";
      const Warehouse = staffUserDetails?.staff[0]?.Warehouse || "";
      const username = staffUserDetails?.staff[0]?.username || "";
      const Department = staffUserDetails?.staff[0]?.Department || "";
      const email = staffUserDetails?.staff[0]?.email || "";
      let Branch = staffUserDetails?.staff[0]?.Branch || "";
      const Title = staffUserDetails?.staff[0]?.Title || "";
      const userType = staffUserDetails?.staff[0]?.userType || "";
      const password = staffUserDetails?.staff[0]?.password || "";

      Branch = Branch.map((api) => ({
        ...api,
        value: api.branch,
        name: api.branch,
        label: api.branch,
      }));

      // const {
      //   Name,
      //   AccessLavel,
      //   Warehouse,
      //   username,
      //   Department,
      //   email,
      //   Branch,
      //   Title,
      //   userType,
      //   password,
      // } = staffUserDetails?.staff[0];

      setInputData({
        Name: Name ? Name : "",
        AccessLavel: AccessLavel ? AccessLavel : "",
        Warehouse: Warehouse ? Warehouse : "",
        username: username ? username : "",
        Department: Department ? Department : "",
        email: email ? email : "",
        Branch: Branch ? Branch : "",
        profilePics: "",
        Staff_ID: props.userId,
        Title: Title ? Title : "",
        userType: userType ? userType : "core",
        initialUserType: userType ? userType : "core",
        hashPassword: password ? password : "",
      });

      setDepartmentValue({
        value: Department ? Department : "",
        label: Department ? Department : "",
      });
      const branchToBeEdited = Branch.map((api) => ({
        ...api,
        value: api.branch,
        name: api.branch,
        label: api.branch,
        url: api.url.replace("/api/users", ""),
      }));

      let api = branchToBeEdited[0]?.url;

      // let APIS = branchToBeEdited.concat(
      //   backendApis?.map((api) => ({
      //     ...api,
      //     value: api.name,
      //     label: api.name,
      //     branch: api.name,
      //     url: api.url,
      //   }))
      // );

      // APIS = uniqBy(APIS, "name");

      // let noneAddedBranches = [...backendApis];
      // let allBranches = [...branchToBeEdited];

      // for (let i = 0; i < allBranches?.length; i++) {
      //   for (let j = 0; j < noneAddedBranches.length; j++) {
      //     if (allBranches[i]?.branch === noneAddedBranches[j].name) {
      //       noneAddedBranches.splice(j, 1);
      //       break;
      //     }
      //   }
      // }

      // let branchesToBeCreated = [];
      // let branchesToBeEdited = [...branchToBeEdited];

      // for (let i = 0; i < noneAddedBranches.length; i++) {
      //   for (let j = 0; j < branchesToBeEdited.length; j++) {
      //     if (branchesToBeEdited[j].name === noneAddedBranches[i].name) {
      //       const branch = branchesToBeEdited.splice(j, 1)[0];

      //       branchesToBeCreated = [...branchesToBeCreated, branch];

      //       break;
      //     }
      //   }
      // }

      // let branchesToDeleted = [...uniqBy(branchToBeEdited, "branch")];

      // for (let i = 0; i < branchesToDeleted.length; i++) {
      //   for (let j = 0; j < branchesToBeEdited.length; j++) {
      //     if (branchesToBeEdited[j].name === branchesToDeleted[i].branch) {
      //       branchesToDeleted.splice(i, 1);
      //       break;
      //     }
      //   }
      // }

      setSelectedBranch({
        ...selectedBranch,
        Branch: branchToBeEdited,
        editedBranch: branchToBeEdited,
        createdBranch: [],
        deletedBranch: [],
        api: api,
      });

      // setBackendAPIS(APIS);
    }
  }, [isEmpty(staffUserDetails?.staff[0])]);

  const { data, isFetching } = useQuery(
    ["FETCH_WAREHOUSE"],
    () => fetchActionsUtil(`${backendUrl}/api/warehouse`, "GET"),
    {
      keepPreviousData: true,
    }
  );

  const mergedWarehouse = useMemo(() => {
    return data?.warehouses?.map((el) => ({
      label: el.W_name,
      value: el.W_ID,
    }));
  }, [data]);

  const defaltWarehouse = useMemo(() => {
    try {
      return inputData?.Warehouse?.split(",")?.map((el) => ({
        // label: mergedWarehouse.find(({ value }) => value === el).label,
        // value: el,
        label: el,
        value: el,
      }));
    } catch (error) {}
  }, [inputData?.Warehouse]);

  const OnChangeInput = (e) => {
    const { name, value, files } = e.target;
    if (name === "profilePics")
      return setInputData({ ...inputData, [name]: files[0] });
    setInputData({ ...inputData, [name]: value });
  };

  const handleBranchChange = (selected) => {
    // if (!selected || isEmpty(selected)) {
    //   return;
    // }

    setSelectedBranch({
      ...selectedBranch,
      Branch: selected,
    });

    // setSelectedBranch({
    //   ...selectedBranch,
    //   Branch: selected,
    //   editedBranch: branchesToBeEdited,
    //   createdBranch: branchesToBeCreated,
    //   deletedBranch: branchesToDeleted,
    //   api: api,
    // });
  };

  const editAccount = useMutation(
    (payload) =>
      sendFormData(
        `${selectedBranch?.api}/api/users/edit-user-account`,
        payload
      ),
    {
      onSuccess: () => {
        props.refetch();
        toast.success("Account Updated Successfully");
        props.refetch();
        props.onHide();
      },
      onError: ({ message = "" }) => {
        toast.error(message);
      },
    }
  );

  function checkDepartment() {
    const canAccessDepartment = [
      { department: "Admin" },
      { department: "Accountant" },
      { department: "Content Management" },

      { department: "HR" },
      { department: "Warehouse" },
      { department: "Production" },
      { department: "Operations" },
      { department: "Business Development" },
      { department: "Procurement" },
      { department: "Document Control" },
    ].find((dp) => dp?.department === inputData?.Department);

    if (!isEmpty(canAccessDepartment)) {
      return true;
    } else {
      return false;
    }
  }

  const saveData = async (e) => {
    e.preventDefault();

    const { profilePics, Branch, ...rest } = inputData;

    rest.brachData = JSON.stringify(brachData);

    if (typeof rest?.Warehouse === "string") {
    } else {
      rest.Warehouse = rest?.Warehouse?.map((d) => d?.value);
    }

    if (isEmpty(selectedBranch) || isEmpty(selectedBranch?.Branch)) {
      toast.error("Select  branches  to edit");
      return;
    }

    const formData = new FormData();
    for (const [key, value] of Object.entries(rest)) {
      formData.append(key, value);
    }

    formData.append("Branch", JSON.stringify(Branch));

    // let noneAddedBranches = differenceBy(backendApis, Branch, "name");

    // if (branchesToBeCreated.length < 1) {
    //   branchesToBeCreated = differenceBy(selectedBranch.Branch, Branch, "name");
    // }

    // let branchToBeEdited;

    // branchToBeEdited = differenceBy(branchesToBeCreated, Branch, "name");

    // if (branchToBeEdited.length < 1) {
    //   branchToBeEdited = differenceBy(Branch, branchesToBeCreated, "name");
    // }

    // console.log("this is the branch to be edited", branchToBeEdited);
    // console.log("this is the branch to be created", branchesToBeCreated);

    let noneAddedBranches = [...backendApis];
    let allBranches = [...inputData.Branch];

    for (let i = 0; i < allBranches?.length; i++) {
      for (let j = 0; j < noneAddedBranches.length; j++) {
        if (allBranches[i]?.branch === noneAddedBranches[j].name) {
          noneAddedBranches.splice(j, 1);
          break;
        }
      }
    }

    let branchesToBeCreated = [];
    let branchesToBeEdited = [...selectedBranch?.Branch];

    for (let i = 0; i < noneAddedBranches.length; i++) {
      for (let j = 0; j < branchesToBeEdited.length; j++) {
        if (branchesToBeEdited[j].name === noneAddedBranches[i].name) {
          const branch = branchesToBeEdited.splice(j, 1)[0];

          branchesToBeCreated = [...branchesToBeCreated, branch];

          break;
        }
      }
    }

    let branchesToDeleted = [...inputData.Branch];

    // for (let i = 0; i < branchesToDeleted.length; i++) {
    //   for (let j = 0; j < branchesToBeEdited.length; j++) {
    //     if (branchesToBeEdited[j].name === branchesToDeleted[i].branch) {
    //       branchesToDeleted.splice(i, 1);
    //       break;
    //     }
    //   }
    // }

    // for (let i = 0; i < branchesToBeEdited.length; i++) {
    //   for (let j = 0; j < branchesToDeleted.length; j++) {
    //     if (branchesToBeEdited[i].name === branchesToDeleted[j].branch) {
    //       branchesToDeleted.splice(i, 1);
    //       break;
    //     }
    //   }
    // }

    // branches to be deleted will be
    // if they are on the branch list but are not on the selected branch
    // let noneDeleted  = [...inputData.Branch]

    for (let i = 0; i < selectedBranch.Branch.length; i++) {
      for (let j = 0; j < branchesToDeleted.length; j++) {
        if (selectedBranch.Branch[i].name === branchesToDeleted[j].branch) {
          branchesToDeleted.splice(j, 1);
          break;
        }
      }
    }

    const isHQ = backendApis.find((branch) => branch?.isHq);

    let api;
    if (branchesToBeEdited?.length > 0) {
      api = branchesToBeEdited[0]?.url;
    } else {
      api = isHQ?.url;
    }

    setSelectedBranch({
      ...selectedBranch,
      api: api,
    });

    formData.append("BranchToBeEdited", JSON.stringify(branchesToBeEdited));
    formData.append("BranchToBeCreated", JSON.stringify(branchesToBeCreated));
    formData.append("BranchToBeDeleted", JSON.stringify(branchesToDeleted));

    if (profilePics) {
      formData.append(
        "profilePics",
        profilePics,
        `${rest.Staff_ID}_profilePics`
      );
    }

    if (process.env.REACT_APP_SITE_TITLE === "InvexERP") {
      if (inputData.userType !== inputData.initialUserType) {
        toast.error(
          `Please select  ${inputData.initialUserType} from the user type`
        );
        return;
      }

      if (inputData?.userType === "passive" && checkDepartment()) {
        toast.error("This department cannot be assigned to passive user");
        return;
      }
    }

    if (
      await ConfirmDialog({
        title: "Edit Profile",
        description: `Are you sure you want to EDIT ${
          props.modalName
        }'s Profile`,
      })
    ) {
      editAccount.mutate(formData);
    }
  };

  // useEffect(() => {
  //   if (
  //     inputData.Department === "Accountant" ||
  //     inputData.Department === "Admin" ||
  //     inputData.Department === "Procurement" ||
  //     inputData.Department === "HR" ||
  //     inputData.Department === "Warehouse" ||
  //     inputData.Department === "Production" ||
  //     inputData.Department === "Content Management" ||
  //     inputData.Department === ""
  //   ) {
  //     setInputData({ ...inputData, userType: "core" });
  //   } else {
  //     setInputData({ ...inputData, userType: "passive" });
  //   }
  // }, [inputData.Department]);

  const getDepartments = async () => {
    let response = await fetch(`${backendUrl}/api/users/departments`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    data.departments = uniq([
      ...allDepartment,
      ...data.departments.map((el) => el.Department),
    ]);
    return data;
  };
  const { data: { departments } = { departments: [] } } = useQuery(
    [queryActions.GET_DEPARTMENTS],
    () => getDepartments(),
    {}
  );

  const resendVerificationLink = useMutation(
    async (payload) =>
      fetchActionsUtil(
        `${backendUrl}/api/users/update-email`,
        "POST",
        token,
        payload
      ),
    {
      onMutate: () => {
        setVerifyEmail(true);
      },
      onSuccess: (data) => {
        toast.success(`${data?.message} ${inputData.email}`);
        setVerifyEmail(false);
      },
      onError: (error) => {
        toast.error(`Unable to perform action`, error.message);
        setVerifyEmail(false);
      },
    }
  );

  /*
   `${backendUrl}/api/auth/send-staff-verify-mail`,
  email: authUser.email,
      Staff_ID: authUser.Staff_ID,
      companyName: authUser?.company,



  */

  return (
    <Modal
      onHide={props.onHide}
      show={props.show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div>
          <div>
            <div className="d-flex justify-content-between">
              <h6>Edit Details</h6>
              <div role="button" className="text-lg" onClick={props.onHide}>
                <CloseIcon />
              </div>
            </div>
            <p className="text-muted">{`Edit ${props.modalName}'s Details`}</p>
          </div>
          <form className="mt-3" onSubmit={saveData}>
            <div className="row">
              <div className="col-2 p-3">
                <Avatar
                  onFileUpload={OnChangeInput}
                  image={inputData.profilePics}
                  name={inputData.Name}
                  style={{ width: "7rem", height: "7rem" }}
                  nameInput="profilePics"
                  userId={props.userId}
                />
              </div>
              <div className="col-10 p-3">
                <div className="row">
                  <div className="col-6">
                    {/*  */}
                    <div className="mb-3">
                      <label className="col-form-label fw-bold">
                        Full Name
                      </label>
                      <div>
                        <input
                          type="text"
                          className="form-control"
                          value={inputData.Name}
                          name="Name"
                          onChange={OnChangeInput}
                        />
                      </div>
                    </div>

                    <div className="mb-3">
                      <label className="col-form-label fw-bold">
                        Access Level
                      </label>
                      <div>
                        <select
                          className="form-select"
                          aria-label="Default select example"
                          value={inputData.AccessLavel}
                          name="AccessLavel"
                          onChange={OnChangeInput}
                        >
                          {alLevels.map((a, i) => (
                            <option key={i} value={a}>
                              {a}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    {process.env.REACT_APP_SITE_TITLE === "InvexERP" && (
                      <div className="mb-3 row">
                        <label className="col-form-label fw-bold">
                          User Type
                        </label>
                        <div>
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            disabled
                            value={inputData.userType}
                            name="userType"
                            onChange={OnChangeInput}
                          >
                            {userType.map((a, i) => (
                              <option key={i} value={a}>
                                {a}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    )}
                    {/*  */}
                    <div className="mb-3">
                      <label className="col-form-label fw-bold">Email</label>
                      <div>
                        <input
                          type="text"
                          className="form-control"
                          value={inputData.email}
                          name="email"
                          onChange={OnChangeInput}
                        />
                      </div>
                    </div>
                    <div className="mb-3">
                      <Button
                        onClick={() => {
                          if (!inputData?.email) {
                            toast.error(`Please enter email`);

                            return;
                          }
                          resendVerificationLink.mutate({
                            email: inputData.email,
                            Staff_ID: inputData.Staff_ID,
                            companyName: authUser?.company,
                          });
                        }}
                      >
                        {verifyEmail
                          ? "  Resend Verification Link..."
                          : "  Resend Verification Link"}
                      </Button>
                    </div>
                    {/*  */}
                  </div>
                  {/* Second side inputs */}
                  <div className="col-6">
                    <div className="mb-3">
                      <label className="col-form-label fw-bold">Title</label>
                      <div>
                        <input
                          type="text"
                          className="form-control"
                          value={inputData.Title}
                          name="Title"
                          onChange={OnChangeInput}
                        />
                      </div>
                    </div>
                    {/*  */}
                    <div className="mb-3 d-none">
                      <label className="col-form-label fw-bold">
                        User Name
                      </label>
                      <div>
                        <input
                          type="text"
                          className="form-control"
                          value={inputData.username}
                          name="username"
                          onChange={OnChangeInput}
                          readOnly
                        />
                      </div>
                    </div>
                    {/*  */}
                    <div className="mb-3">
                      <label className="col-form-label fw-bold">
                        Department
                      </label>

                      {defaultDepartmentValue && (
                        <div>
                          <Select
                            classNamePrefix="form-select"
                            menuPosition="fixed"
                            menuPlacement="auto"
                            isSearchable={true}
                            defaultValue={defaultDepartmentValue}
                            onChange={(selected) => {
                              setInputData({
                                ...inputData,
                                Department: selected?.value,
                              });
                            }}
                            // defaultValue={{
                            //   value: inputData?.Department,
                            //   label: inputData?.Department,
                            // }}

                            options={
                              departments
                                .filter((department) =>
                                  !appSettings.isMedbury &&
                                  [
                                    "Hospital Management Board",
                                    "Primary Healthcare Center",
                                    "Ministry of Health",
                                  ].includes(department)
                                    ? false
                                    : true
                                )
                                .map((d, i) => ({
                                  label: d,
                                  value: d,
                                })) || []
                            }
                            isClearable
                          />

                          {/* <select
                          className="form-select"
                          aria-label="Default select example"
                          value={inputData.Department}
                          name="Department"
                          onChange={OnChangeInput}
                        >
                          {departments
                            .filter((department) =>
                              !appSettings.isMedbury &&
                              [
                                "Hospital Management Board",
                                "Primary Healthcare Center",
                                "Ministry of Health",
                              ].includes(department)
                                ? false
                                : true
                            )
                            .map((d, i) => (
                              <option key={i} value={d}>
                                {d}
                              </option>
                            ))}
                        </select> */}
                        </div>
                      )}
                    </div>
                    {/*  */}

                    {/* {inputData.Department === "Warehouse" && (
                      <div className="mb-3 row">
                        <label className="col-sm-4 col-form-label fw-bold text-nowrap">
                          Choose Warehouse
                        </label>
                        <div>
                          <Select
                            closeMenuOnSelect={true}
                            isLoading={isFetching}
                            isMulti
                            isSearchable={true}
                            defaultValue={defaltWarehouse}
                            onChange={(select) => {
                              setInputData({
                                ...inputData,
                                Warehouse: select,
                              });
                            }}
                            options={mergedWarehouse}
                          />
                        </div>
                      </div>
                    )} */}
                    {/*  */}
                    <div className="mb-3 row">
                      <label className="col-sm-4 col-form-label fw-bold">
                        Branch
                      </label>
                      <div>
                        {!isEmpty(selectedBranch.Branch) && (
                          <Select
                            menuPosition="fixed"
                            className=""
                            menuPlacement="auto"
                            placeholder="Select branch"
                            value={selectedBranch.Branch}
                            name="Branch"
                            onChange={handleBranchChange}
                            isSearchable={true}
                            isMulti
                            // options={selectedBranch.Branch}
                            options={backendApis?.map((api) => ({
                              ...api,
                              value: api.name,
                              label: api.name,
                              branch: api.name,
                              url: api.url,
                            }))}
                          />
                        )}

                        {/* {branches?.map((branch) => (
            <div className=" col-md-3 gap-4 ">
              <button className=" text-center   w-100 py-3">
                {branch.branch}
              </button>{" "}
            </div>
          ))} */}
                      </div>
                    </div>

                    {/* <label className="col-form-label fw-bold">Branch</label>
                      <div>
                        <select
                          className="form-select"
                          aria-label="Default select example"
                          value={inputData.Branch}
                          name="Branch"
                          onChange={OnChangeInput}
                        >
                          {branches.map((d, i) => (
                            <option key={i} value={d}>
                              {d}
                            </option>
                          ))}
                        </select>
                      </div> */}

                    {/*  */}
                  </div>
                </div>
                {/*  */}
              </div>
            </div>
          </form>
        </div>
      </Modal.Body>
      <div className="d-flex justify-content-end m-4">
        <button className="btn bg print mx-4" onClick={props.onHide}>
          Cancel
        </button>
        <button className="btn btn-primary" onClick={saveData}>
          {editAccount.isLoading ? "Please Wait..." : "Update details"}
        </button>
      </div>
    </Modal>
  );
};

export default EditUserAccountModal;
