/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef, useMemo } from "react";
import { Button, Modal, Table, Popover, Overlay, Form } from "react-bootstrap";
import "./../../assets/scss/requisition/index.scss";
import { useMutation, useQuery } from "react-query";
import { format } from "date-fns";
import CurrencyInput from "react-currency-input-field";
import { isEmpty, uniqBy } from "lodash";
import { fetchActionsUtil } from "../../utils/helpers";
import { toast } from "react-toastify";
import { cacheTimes, queryActions } from "../../utils/reactQueryActions";
import DateTime from "react-datetime";

import ItemsTable from "../utils/ItemsTable";
import { useAuth } from "../../hooks/useAuth";
import { useFormik } from "formik";
import RequisitionService from "../../hooks/requisitionService";
import * as yup from "yup";
import eventBus from "../../utils/EventBus";
import { useStoreState } from "easy-peasy";
import Select from "react-select";
import moment from "moment";
import ModalLoader from "../utils/ModalLoader";
import ConfirmDialog from "../ConfirmDialogue";
import { FilesAttachments } from "../../utils/Attachments";

export default function InItemsRequisitionModal({
  setShowNewRequisitionModal,
  ShowNewRequisitionModal,
  selectedVendors,
  mainRefetch,
  selectedRotingPO,
  selectedRFQ,
  editRFQ,
  approvedRequisitionId,
  incomingAttachments,
}) {
  const initialRequestData = {
    barcode: " ",
    itemName: " ",
    quantity: 1,
    warehouse: " ",
    Generic_Configuration: " ",
    Branded_Generic: " ",
    Pack_Size: " ",
    Tier: " ",
    specification: " ",
  };
  const generalSettings = useStoreState((state) => state.generalSettings);
  const { createRequisitionMutation } = RequisitionService();
  const [tableData, setTableData] = useState([initialRequestData]);
  const [sentVendors, setSentVendors] = useState([]);
  const { user: authUser, backendUrl } = useAuth();

  const [reciever, setReceiver] = useState();
  const [loading, setLoading] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [IsLoading, setIsLoading] = useState(false);

  const handleInputChange = ({ index, name, value }) => {
    const oldTableData = tableData;
    oldTableData[index][name] = value;
    setTableData([...oldTableData]);
  };

  const saveImages = async ({ formValues }) => {
    return new Promise(async (resolve, reject) => {
      try {
        //------------------ Upload Files  -----------------------

        if (attachments?.length > 0) {
          const payload = { initialFiles: attachments };
          const data = await uploadFileApi(payload);
          formValues.attachments = JSON.stringify(data?.imageFiles);
        }

        //--------------------------------------------------------
        resolve(formValues);
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  };

  const saveAllData = async ({ formValues }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const changeTableData = tableData.filter(
          (el) => el.barcode !== " " || el.itemName !== " "
        );

        let description = changeTableData
          .map((el) => {
            delete el.subtotal;
            return Object.values(el).join(",,,");
          })
          .join("&&&");

        description = description + "&&&";

        formValues.requestto = formValues.recipient;
        formValues.regdate = format(new Date(), "yyyy-MM-dd");
        formValues.status = "Pending";

        createRequisitionMutation.mutate(
          {
            approvedRequisitionId,
            description,
            amount: 0,
            ...formValues,
            tableData: JSON.stringify(changeTableData),
          },
          {
            onSuccess: (data) => {
              formik.resetForm();
              eventBus.dispatch("REQUISITION_CREATED", data.requisition);
              setShowNewRequisitionModal(false);
              mainRefetch && mainRefetch();
            },
          }
        );

        resolve();
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  };

  const save = async (formValues) => {
    const data = await saveImages({ formValues });
    await saveAllData({ formValues: data });
  };

  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const [activeTableIndex, setActiveTableIndex] = useState(null);
  const ref = useRef(null);

  // const handleRowClick = (e, index) => {
  //   setShow(true);
  //   setTarget(e.target);
  // };

  const createNew = (e) => {
    setShow(true);
    setTarget(e.target);
    setActiveTableIndex(-1);
  };

  const handleSelectedItem = (item) => {
    item = {
      barcode: item.Bar_Code,
      itemName: item.Item_Name,
      // unitCost: item.UnitCost,
      quantity: 1,
      warehouse: item.Warehouse ? item.Warehouse : " ",

      Generic_Configuration: item.Generic_Configuration
        ? item?.Generic_Configuration
        : " ",
      Branded_Generic: item.Branded_Generic ? item?.Branded_Generic : " ",
      Pack_Size: item.Pack_Size ? item?.Pack_Size : " ",
      Tier: item.Tier ? item?.Tier : " ",
      specification: item?.description ? item?.description : " ",
      disable: true,
    };

    // console.log(item);

    let oldTableData = tableData;

    const found = oldTableData.find((el) => el.barcode === item.barcode);

    if (found) {
      const newItem = { ...found, quantity: parseInt(found.quantity) + 1 };
      const index = oldTableData.findIndex((el) => el.barcode === item.barcode);
      oldTableData[index] = newItem;
    } else {
      oldTableData = [...oldTableData, item];
    }

    // if (activeTableIndex === -1) {
    //   oldTableData = [...oldTableData, item];
    // } else {
    //   oldTableData[activeTableIndex] = item;
    // }
    setTableData([...oldTableData]);
    if (activeTableIndex === -1) setActiveTableIndex(oldTableData.length - 1);
    setShow(false);
  };

  const enterNewItem = () => {
    let oldTableData = tableData;

    // Check if any object is duplicated (by reference, which is typically how JS compares objects)
    const hasDuplicates =
      oldTableData.length !==
      new Set(oldTableData.map((obj) => JSON.stringify(obj))).size;

    if (hasDuplicates) {
      return toast.error("There are duplicates, delete it");
    } else {
      setTableData([...oldTableData, initialRequestData]);
      if (activeTableIndex === -1) setActiveTableIndex(oldTableData.length - 1);
    }
  };

  const { data: warehouse } = useQuery(
    ["FETCH_WAREHOUSE"],
    () => fetchActionsUtil(`${backendUrl}/api/warehouse`, "GET"),
    {
      keepPreviousData: true,
    }
  );

  const removeSelection = () => {
    const oldTableData = tableData.filter(
      (el, index) => index !== activeTableIndex
    );
    setTableData([...oldTableData]);
  };

  const formik = useFormik({
    initialValues: {
      title: "",
      type: "RFQ",
      department: "",
      recipient: "",
      jobGrade: "",
      comment: "",
      currency: generalSettings?.prevailingCurrency,
      // conversionAmount: generalSettings.dollarInBaseCurrency,
      expectedDeliveryDate: new Date(),
      dueDate: moment(),
    },
    validationSchema: yup.object().shape({
      title: yup.string().required("required"),
      type: yup.string().required("required"),
      recipient: yup.string().required("required"),
      // comment: yup.string(),
    }),
    onSubmit: async (values) => {
      if (isEmpty(values.comment)) values.comment = "...";

      values.selectedVendors = JSON.stringify([]);
      if (sentVendors?.length > 0) {
        values.selectedVendors = JSON.stringify(
          sentVendors?.map((el) => el.value)
        );

        if (sentVendors?.length < 1) {
          return toast.error("Please Select Vendors", {
            position: "top-right",
          });
        }
      }

      if (tableData?.length < 1) {
        return toast.error("Please Select Items", {
          position: "top-right",
        });
      }
      // console.log(values);
      let title = "Create Request For Quote";
      let description = "Are you sure you want to create Request For Quote";

      if (editRFQ) {
        values.editRFQ = JSON.stringify(editRFQ);

        title = "Edit Request For Quote";
        description =
          "All your previous data will be deleted and a new one will be created.";
      }

      values.attachments = JSON.stringify([]);
      if (attachments?.length > 0) {
        values.attachments = JSON.stringify(attachments);
      }

      // console.log({ values });

      if (
        await ConfirmDialog({
          title,
          description,
        })
      ) {
        save(values);
      }
    },
  });

  const getUsersInDepartments = async (department) => {
    let response = await fetch(
      `${backendUrl}/api/users/by-department/${department}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    data.users = [
      { label: "Select Recipient", value: "" },
      ...data.users
        .map((el) => ({
          ...el,
          label: el.Name,
          value: el.Staff_ID,
        }))
        .filter((el) => el.Staff_ID !== authUser.Staff_ID),
    ];
    return data;
  };
  const {
    data: { users } = { users: [] },
    refetch: getUsers,
    isFetching: isLoadingUsers,
  } = useQuery(
    ["queryActions.GET_USERS_IN_DEPARTMENTS", formik.values.department],
    () => getUsersInDepartments(formik.values.department),
    {
      enabled: false,
    }
  );

  const fetchVendors = async (department) => {
    let response = await fetch(`${backendUrl}/api/vendors?verified=true`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    data.vendors = [
      { label: "None", value: "" },
      ...data.vendors.map((el) => ({
        label: el.CompanyName,
        value: el.Vendor_ID,
      })),
    ];
    return data;
  };

  const {
    data: { vendors } = { vendors: [] },
    isFetching: isLoadingVendors,
  } = useQuery([queryActions.GET_VENDORS], () => fetchVendors(), {
    enabled: true,
  });

  //----------------------------------------------------------------------
  // fetch data for form
  const getDepartments = async () => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/users/departments-with-users`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };

  const {
    data = {
      departments: [],
      staffs: [],
      jobGrades: [],
      departmentWithLeads: [],
    },
  } = useQuery(
    ["queryActions.GET_USERS_WITH_DEPARTMENTS"],
    () => getDepartments(),
    {
      enabled: true,
      keepPreviousData: true,
      cacheTime: cacheTimes.GET_USERS_WITH_DEPARTMENTS,
    }
  );

  const staffSelectOptions = useMemo(() => {
    const jobGrades = data.staffs
      .filter((el) => el.Department === formik.values.department)
      .map((el) => ({ jobGrade: el.jobGrade }));

    const staffs = data.staffs.filter((el) => {
      if (!formik.values.department) return true;
      if (!formik.values.department && !formik.values.jobGrade) return true;
      if (formik.values.department === "Department Lead") {
        const myDepartmentLead = data.departmentWithLeads
          .filter((el) => el.Dept_Lead && el.Dept_Name === authUser.Department)
          .map((el) => JSON.parse(el.Dept_Lead))
          .flat();

        return myDepartmentLead.find((lead) => lead.Staff_ID === el.Staff_ID);
      }
      if (formik.values.department && !formik.values.jobGrade) {
        return el.Department === formik.values.department;
      }
      if (formik.values.department && formik.values.jobGrade) {
        return (
          el.Department === formik.values.department &&
          el.jobGrade === formik.values.jobGrade
        );
      }
    });

    return {
      departments: [
        { label: "Any Department", value: "" },
        { label: "Department Lead", value: "Department Lead" },
        ...data.departments.map((el) => ({
          label: el.Department,
          value: el.Department,
        })),
      ],
      jobGrades: [
        { label: "Any Job Grade", value: "" },
        ...uniqBy(jobGrades, "jobGrade").map((el) => ({
          label: el.jobGrade,
          value: el.jobGrade,
        })),
      ],
      staffs: [
        { label: "Any Staff", value: "" },
        ...staffs.map((el) => ({
          label: el.Name,
          value: el.Staff_ID,
        })),
      ],
    };
  }, [data, formik.values.department, formik.values.jobGrade]);

  useEffect(() => {
    if (selectedVendors) {
      const ved = vendors.filter((d) =>
        selectedVendors.find((e) => e === d.value)
      );

      setSentVendors(ved);
    }
  }, [selectedVendors, vendors]);

  useEffect(() => {
    if (selectedRotingPO) {
      const unique = [...new Set(selectedRotingPO.map((item) => item.barcode))];
      const items = unique?.map((el) => {
        const item = selectedRotingPO?.filter((a) => a.barcode === el);

        return {
          barcode: item[0]?.barcode,
          itemName: item[0]?.itemName,
          warehouse: item[0]?.warehouse,
          quantity: item?.reduce(
            (a, b) =>
              a + (parseFloat(b.quantity) - parseFloat(b.receiveQuantity)),
            0
          ),
          ids: JSON.stringify(item?.map((e) => e.id)),

          Generic_Configuration: item[0].Generic_Configuration
            ? item[0]?.Generic_Configuration
            : " ",
          Branded_Generic: item[0].Branded_Generic
            ? item[0]?.Branded_Generic
            : " ",
          Pack_Size: item[0].Pack_Size ? item[0]?.Pack_Size : " ",
          Tier: item[0].Tier ? item[0]?.Tier : " ",
        };
      });
      //  console.log(items);
      setTableData(items);
    }
  }, [selectedRotingPO]);

  useEffect(() => {
    if (selectedRFQ) {
      // console.log({ selectedRFQ });
      const items = selectedRFQ?.map((el) => ({
        barcode: el?.Bar_Code ? el?.Bar_Code : el?.barcode,
        itemName: el?.Item_Name ? el?.Item_Name : el?.itemName,
        quantity: el?.QtyToOrder
          ? el?.QtyToOrder
          : el?.quantityApproved
          ? el?.quantityApproved
          : el?.quantityRequested,
        warehouse: el?.Warehouse ? el.Warehouse : "",

        Generic_Configuration: el?.Generic_Configuration
          ? el?.Generic_Configuration
          : " ",
        Branded_Generic: el?.Branded_Generic ? el?.Branded_Generic : " ",
        Pack_Size: el?.Pack_Size ? el?.Pack_Size : " ",
        Tier: el.Tier ? el?.Tier : " ",
        specification: el?.description ? el?.description : " ",
      }));
      setTableData(items);
    }

    if (incomingAttachments) {
      setAttachments(incomingAttachments);
    }
  }, [selectedRFQ]);

  // GET FORM PERMISSIONS =====================================================
  const getNumberSystems = async () => {
    try {
      let response = await fetch(`${backendUrl}/api/numbersystem`, {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      });

      if (!response.ok) {
        response = await response.json();
        throw new Error(response.message);
      }

      const { data } = await response.json();

      if (data.numberSystems) {
        const permissions = JSON.parse(
          data.numberSystems.find(
            (el) => el?.documentType === formik.values?.type
          )?.selectedSignatory || JSON.stringify([])
        );

        if (permissions?.length > 0) {
          setReceiver(permissions[1]);
          formik.setFieldValue(
            "department",
            permissions[1]?.department || "All"
          );
          formik.setFieldValue("recipient", permissions[1]?.staff);
          formik.setFieldValue("jobGrade", permissions[1]?.jobGrade);
        }
      }
      return data;
    } catch (error) {
      console.log("error =", error);
    }
  };

  const { refetch: refetchPermissions } = useQuery(
    ["NUMBER_SYSTEMS", formik.values?.type],
    () => getNumberSystems(formik.values?.type),
    {
      keepPreviousData: true,
      //  staleTime: 1000 * 60 * 60 * 24,
    }
  );
  // =========================================================================

  const getEditRFQ = async () => {
    try {
      setLoading(true);
      const { data } = await fetchActionsUtil(
        `${backendUrl}/api/vendors/public-RFQ-items?edit=${true}&RFQId=${
          editRFQ?.RFQId
        }`
      );

      const { items, vendors, ...rest } = data;

      const itemData = {
        title: rest?.formName,
        type: "RFQ",
        department: "",
        recipient: "",
        jobGrade: "",
        comment: "",
        currency: generalSettings?.prevailingCurrency,
        dueDate: rest?.DueDate ? moment(new Date(rest?.DueDate)) : moment(),
        expectedDeliveryDate: new Date(),
      };

      const dataVendors =
        vendors?.length > 0
          ? vendors?.map((el) => ({
              label: el?.CompanyName,
              value: el?.Vendor_ID,
            }))
          : [];

      if (rest?.attachments) {
        setAttachments(JSON.parse(rest?.attachments));
      }

      setSentVendors(dataVendors);
      formik.setValues(itemData, false);
      setTableData(items);
    } catch (err) {
      console.log(err);
      toast.error(`Unable to perform action`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (editRFQ) {
      getEditRFQ();
    }
  }, [editRFQ]);

  const sendDeleteImage = async (payload, index) => {
    try {
      setIsLoading(true);
      let response = await fetch(
        `${backendUrl}/api/vendors/delete-initial-file`,
        {
          method: "POST",
          credentials: "include",
          body: JSON.stringify(payload),
          headers: {
            Accept: "Application/json",
            "Content-Type": "Application/json",
          },
        }
      );
      if (!response.ok) {
        response = await response.json();
        throw new Error(response.message);
      }
      const res = await response.json();
      setAttachments(attachments.filter((el, i) => i !== index));

      return res;
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteImage = async (index) => {
    const file = attachments.find((el, i) => i === index);
    if (file?.fileId) {
      if (
        await ConfirmDialog({
          title: "Delete",
          description: `Do you want to delete this file - ${
            file.name
          }, you can't undo this`,
        })
      ) {
        const res = await sendDeleteImage(
          { file, requestid: editRFQ?.requestid },
          index
        );
        toast.success(res?.message);
      }
    } else {
      setAttachments(attachments.filter((el, i) => i !== index));
    }
  };

  const uploadFileApi = async (payload) => {
    const formData = new FormData();
    const alreadyUploadedFilesInitialFiles = [];
    for (const file of payload.initialFiles) {
      if (!file?.fileId) {
        formData.append("initialFiles", file);
      } else {
        alreadyUploadedFilesInitialFiles.push(file);
      }
    }

    if (!isEmpty(alreadyUploadedFilesInitialFiles)) {
      formData.append(
        "alreadyUploadedFilesInitialFiles",
        JSON.stringify(alreadyUploadedFilesInitialFiles)
      );
    }

    let response = await fetch(`${backendUrl}/api/vendors/upload-files`, {
      method: "POST",
      body: formData,
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };

  const uploadFileMutation = useMutation((payload) => uploadFileApi(payload), {
    onSuccess: async ({ message, imageFiles }) => {
      toast.success(message);
      setAttachments(imageFiles);
    },
    onError: () => {
      toast.error(`Unable to perform action`);
    },
  });

  const getWarehouse = useMemo(() => {
    return warehouse?.warehouses
      ? [
          {
            label: "Select Warehouse",
            value: " ",
          },
          ...warehouse?.warehouses?.map((el) => ({
            label: el?.W_name,
            value: el?.W_ID,
          })),
        ]
      : [];
  }, [warehouse?.warehouses]);

  return (
    <div>
      <Modal
        show={ShowNewRequisitionModal}
        onHide={() => setShowNewRequisitionModal(false)}
        dialogClassName="requisition-details-modal preview-modal"
        backdropClassName={`global-backdrop`}
        centered={true}
        animation={false}
        enforceFocus={false}
        fullscreen={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h1 className="py-2 capitalize">
              {editRFQ ? "Edit Request For Quote" : "New Request For Quote"}
            </h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="preview p-0">
          <div className="preview-content p-0">
            <div className="content">
              <div className="preview-body">
                <Form
                  className="mb-3"
                  id="requisition-form"
                  noValidate
                  onSubmit={formik.handleSubmit}
                >
                  <div className="row">
                    <Form.Group className="col-8 mb-3">
                      <Form.Label>Title :</Form.Label>
                      <Form.Control
                        name="title"
                        placeholder="Enter a title"
                        value={formik.values.title}
                        onChange={formik.handleChange}
                        isInvalid={
                          formik.touched.title && !!formik.errors.title
                        }
                      />

                      <Form.Control.Feedback type="invalid">
                        {formik.errors.title}
                      </Form.Control.Feedback>
                    </Form.Group>
                    {/*  */}
                    <Form.Group className="col-4 mb-3 pb-2">
                      <Form.Label className="mb-1">Due Date</Form.Label>
                      <DateTime
                        timeFormat={false}
                        closeOnSelect={true}
                        closeOnClickOutside={true}
                        dateFormat="MMM DD, YYYY"
                        inputProps={{
                          className: `date-input form-control`,
                          placeholder: "Select date",
                          readOnly: true,
                        }}
                        value={formik.values.dueDate}
                        onChange={(date) => {
                          formik.setFieldValue("dueDate", date, true);
                        }}
                      />
                    </Form.Group>
                  </div>

                  <Form.Group className="mb-3">
                    <Form.Label>Vendors :</Form.Label>
                    <Select
                      closeMenuOnSelect={true}
                      isLoading={isLoadingVendors}
                      isMulti
                      isSearchable={true}
                      name="vendor"
                      options={vendors}
                      value={sentVendors}
                      onChange={(selected) => setSentVendors(selected)}
                    />
                  </Form.Group>

                  <fieldset className="html-fieldset my-4">
                    <legend className="html-legend">Company Approval</legend>
                    <div className="row gap-3">
                      <Form.Group className="col mb-3">
                        <Form.Label>Department :</Form.Label>
                        <Select
                          classNamePrefix={`form-select`}
                          name="department"
                          options={staffSelectOptions?.departments}
                          value={staffSelectOptions?.departments.find(
                            (el) => el.value === formik.values.department
                          )}
                          onChange={({ value }) => {
                            formik.setFieldValue("department", value);

                            formik.setFieldValue("jobGrade", "");
                            formik.setFieldValue("recipient", "");
                          }}
                          isDisabled={reciever?.department}
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.department}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group className="col mb-3">
                        <Form.Label>Job Grade :</Form.Label>
                        <Select
                          classNamePrefix={`form-select`}
                          name="jobGrade"
                          options={staffSelectOptions?.jobGrades}
                          value={staffSelectOptions?.jobGrades.find(
                            (el) => el.value === formik.values.jobGrade
                          )}
                          onChange={({ value }) => {
                            formik.setFieldValue("jobGrade", value);

                            formik.setFieldValue("recipient", "");
                          }}
                          isDisabled={
                            reciever?.jobGrade ||
                            reciever?.staff ||
                            reciever?.department === "Department Lead"
                          }
                        />
                      </Form.Group>

                      <Form.Group className="col mb-3">
                        <Form.Label>Recipient :</Form.Label>
                        <Select
                          classNamePrefix={`form-select`}
                          name="recipient"
                          options={staffSelectOptions?.staffs}
                          value={staffSelectOptions?.staffs.find(
                            (el) => el.value === formik.values.recipient
                          )}
                          onChange={({ value }) =>
                            formik.setFieldValue("recipient", value)
                          }
                          isDisabled={reciever?.staff}
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.recipient}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                  </fieldset>

                  <Form.Group className="mb-3">
                    <Form.Label>Comment :</Form.Label>
                    <label className="border p-0 rounded message-area d-block">
                      <Form.Control
                        name="comment"
                        value={formik.values.comment}
                        onChange={formik.handleChange}
                        as="textarea"
                        className="w-100 border-0 p-3"
                        placeholder="Enter a comment"
                        rows={4}
                      />
                    </label>
                  </Form.Group>
                </Form>

                <div className="my-2" />

                <Table responsive borderless striped className="product-table">
                  <thead>
                    <tr>
                      <th>S/N</th>
                      <th>Item Code</th>
                      <th>Item Name</th>
                      <th>Specification</th>
                      <th>Quantity</th>

                      {generalSettings?.pharmacySetting && (
                        <>
                          {/* <th className="d-none">Generic Configuration</th>
                          <th className="d-none">Branded Generic</th> */}
                          <th>Pack Size</th>
                          <th>Tier</th>
                        </>
                      )}
                      <th>Warehouse</th>
                    </tr>
                  </thead>
                  <tbody ref={ref}>
                    {tableData &&
                      tableData.map((el, index) => (
                        <tr
                          key={index}
                          className={`${activeTableIndex === index &&
                            "active-row"}`}
                          onClick={() =>
                            activeTableIndex !== index &&
                            setActiveTableIndex(index)
                          }
                        >
                          <td>{index + 1}</td>
                          <td
                            className="p-cursor"
                            // onClick={(e) => handleRowClick(e, index)}
                          >
                            {/* {el.barcode} */}
                            <Form.Control
                              // as="textarea"
                              // rows={3}
                              className="form-control border-2 px-1 bg-white col-6"
                              name="barcode"
                              value={el?.barcode}
                              onKeyDown={(e) =>
                                e.keyCode === 13 && e.target.blur()
                              }
                              onChange={(e) =>
                                handleInputChange({
                                  index,
                                  name: "barcode",
                                  value: e.target.value,
                                })
                              }
                              disabled={incomingAttachments || el?.disable}
                            />
                          </td>
                          <td>
                            {/* {el.itemName} */}

                            <Form.Control
                              // as="textarea"
                              // rows={3}
                              className="form-control border-2 bg-white"
                              name="itemName"
                              value={el?.itemName}
                              onKeyDown={(e) =>
                                e.keyCode === 13 && e.target.blur()
                              }
                              onChange={(e) =>
                                handleInputChange({
                                  index,
                                  name: "itemName",
                                  value: e.target.value,
                                })
                              }
                              disabled={incomingAttachments || el?.disable}
                            />
                          </td>
                          <td>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              className="form-control border-2 px-1 bg-white col-6"
                              name="specification"
                              value={el?.specification}
                              onKeyDown={(e) =>
                                e.keyCode === 13 && e.target.blur()
                              }
                              onChange={(e) =>
                                handleInputChange({
                                  index,
                                  name: "specification",
                                  value: e.target.value,
                                })
                              }
                              disabled={incomingAttachments || el?.disable}
                            />
                          </td>

                          <td>
                            <CurrencyInput
                              className="form-control border-2 px-1"
                              value={el.quantity}
                              onKeyDown={(e) =>
                                e.keyCode === 13 && e.target.blur()
                              }
                              disabled={selectedRotingPO ? true : false}
                              name="quantity"
                              onValueChange={(value, name) =>
                                handleInputChange({
                                  index,
                                  name,
                                  value,
                                })
                              }
                              //  disableGroupSeparators
                              allowNegativeValue={false}
                              allowDecimals={true}
                            />
                          </td>

                          {generalSettings?.pharmacySetting && (
                            <>
                              {/* <td className="d-none">
                                {el?.Generic_Configuration}
                              </td>
                              <td className="d-none">{el?.Branded_Generic}</td> */}
                              <td>{el?.Pack_Size}</td>
                              <td>{el?.Tier}</td>
                            </>
                          )}
                          <td>
                            <Select
                              styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  width: "12rem",
                                }),
                              }}
                              classNamePrefix={`form-select-store`}
                              menuPosition="fixed"
                              isSearchable={false}
                              placeholder="Select Warehouse"
                              options={getWarehouse}
                              value={getWarehouse?.find(
                                (details) => details.value === el?.warehouse
                              )}
                              onChange={({ value }) =>
                                handleInputChange({
                                  index,
                                  name: "warehouse",
                                  value,
                                })
                              }
                              isDisabled={incomingAttachments || el?.disable}
                            />
                          </td>
                        </tr>
                      ))}
                    <tr
                      className={`p-cursor ${activeTableIndex === -1 &&
                        "active-row"}`}
                    >
                      <td onClick={(e) => createNew(e)}>
                        <span className="">
                          <button className="btn btn-primary">+Add</button>
                        </span>
                      </td>
                      <td />
                      <td />
                      <td />
                      <td />

                      {generalSettings?.pharmacySetting && (
                        <>
                          {/* <td />
                          <td /> */}
                          <td />
                          <td />
                        </>
                      )}
                      <td>
                        <span className="">
                          <button
                            className="btn btn-primary"
                            onClick={enterNewItem}
                          >
                            Enter
                          </button>
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <Overlay
                  show={show}
                  onHide={() => setShow(false)}
                  target={target}
                  container={document.body}
                  containerPadding={0}
                  rootClose={true}
                  popperConfig={{
                    strategy: "fixed",
                    modifiers: [
                      {
                        name: "ga-custom",
                        enabled: true,
                        phase: "beforeWrite",
                        fn({ state }) {
                          const oldTransfrom = String(
                            state.styles.popper?.transform
                          );
                          const y = Number(
                            String(oldTransfrom)
                              .split(",")[1]
                              .split(")")[0]
                              .split("px")[0]
                              .trim()
                          );

                          const x = Number(
                            String(oldTransfrom)
                              .split(",")[0]
                              .split("(")[1]
                              .split("px")[0]
                              .trim()
                          );

                          //  console.log(x, y, y < -210);

                          // state.styles.popper.left = `50%`;
                          // state.styles.popper.top = `50%`;
                          state.styles.popper.transform =
                            y < -210
                              ? `translate(${x}px, ${-210}px)`
                              : `translate(${x}px, ${y}px)`;
                        },
                      },
                    ],
                  }}
                >
                  <Popover
                    style={{
                      maxWidth: "unset",
                      height: "28.75rem",
                      overflow: "hidden",
                      boxShadow: "0px 4px 20px 0px #131B2814",
                    }}
                  >
                    <ItemsTable
                      handleSelectedItem={handleSelectedItem}
                      type="true"
                    />
                  </Popover>
                </Overlay>

                {/*  */}

                <div className="mb-4">
                  <p className="mb-4"> Attachments: </p>
                  <FilesAttachments
                    files={attachments.map((el) =>
                      !el?.fileId
                        ? el
                        : {
                            ...el,
                            modifiedSystemFileName: `${el.systemFileName}/${
                              el.name
                            }`,
                          }
                    )}
                    setFiles={(files) => setAttachments(files)}
                    colWidth="3"
                    url={`${backendUrl}/api/${
                      incomingAttachments ? "requisition" : "vendors"
                    }/files`}
                    // upload={() =>
                    //   uploadFileMutation.mutate({
                    //     initialFiles: attachments,
                    //   })
                    // }
                    deleteImage={deleteImage}
                    isLoading={uploadFileMutation?.isLoading || IsLoading}
                    viewOnly={incomingAttachments}
                  />
                </div>

                {/*  */}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="px-4 bg-white">
          {![null, -1].includes(activeTableIndex) ? (
            <Button
              disabled={createRequisitionMutation.isLoading}
              onClick={() => removeSelection()}
              variant="danger"
              size="sm"
              className="text-white px-3 "
            >
              Remove Selection
            </Button>
          ) : (
            <span />
          )}

          <div className="proceed-actions">
            <Button
              disabled={createRequisitionMutation.isLoading}
              variant="primary"
              size="sm"
              type="submit"
              form="requisition-form"
            >
              {createRequisitionMutation.isLoading
                ? "Please wait..."
                : "Send for approval"}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <ModalLoader show={loading} />
    </div>
  );
}
