import Button from "react-bootstrap/Button";

import Modal from "react-bootstrap/Modal";
import "./../assets/scss/requisition/index.scss";

import {
  ApproveBlueModalIcon,
  ApproveCheckIcon,
  RejectModalIcon,
} from "./Icons";
import { Table, Form } from "react-bootstrap";
import { map, isEmpty, cloneDeep, lowerCase, get } from "lodash";
import { isNumber } from "lodash";
import currency from "currency.js";
import { useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useAuth } from "../hooks/useAuth";
import {
  QueryClientProvider,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import {
  convertFromBaseCurrency,
  convertToBaseCurrency,
  copyText,
  fetchActionsUtil,
  formatCurrencyToNumber,
  pcsToTons,
  qtyFormat,
} from "../utils/helpers";
import { confirmable, createConfirmation } from "../utils/confirm";
import { useFormik } from "formik";
import { useStoreState } from "easy-peasy";
import moment from "moment";
import { appSettings, services } from "../config";
import { useTaxOptions } from "../utils/hooks";
import SelectBankDialog from "./SelectBankDialog";
import { useParams } from "react-router-dom";
import InvoiceAndWaybillDialog from "./InvoiceAndWaybillDialog";
import LockedStockDialog from "./LockedStockDialog";
import PreorderDialog from "./PreorderDialog";

function Dialog({
  proceed,
  show,
  title = "",
  description = "",
  summary = {},
  summaryTitle = "Summary",
  removeUnderscore = false,
  type = "info",
  choice = true,
  backdrop = true,
  // under score is added to summary object keys, incase of duplicates
  withComment = false,
}) {
  const [comment, setComment] = useState("");

  return (
    <Modal
      show={show}
      onHide={() => proceed(false)}
      dialogClassName="requisition-details-modal"
      centered={true}
      animation={false}
      enforceFocus={false}
      backdrop={backdrop}
    >
      <Modal.Header />
      <Modal.Body className="approve-action">
        <div className="text-info-h">
          <div className="mb-4">
            {type === "info" ? (
              <ApproveBlueModalIcon />
            ) : type === "danger" ? (
              <RejectModalIcon />
            ) : (
              <ApproveBlueModalIcon />
            )}{" "}
          </div>

          <h2>{title}</h2>
          <p
            className="fs-6"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>

        {!isEmpty(summary) ? (
          <>
            <Table striped className="mt-3">
              <thead>
                <tr>
                  <th colSpan={2}>{summaryTitle}</th>
                </tr>
              </thead>
              <tbody>
                {map(summary, (obj, key, all) => (
                  <tr key={key}>
                    <td>
                      {removeUnderscore ? String(key).replaceAll("_", "") : key}
                    </td>
                    <td>
                      {obj
                        ? isNumber(Number(obj)) && !isNaN(Number(obj))
                          ? currency(obj, {
                            symbol: "",
                          }).format()
                          : obj
                        : "-"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        ) : null}

        {withComment && (
          <Form.Group className="my-4">
            <Form.Label className="d-none">Comment :</Form.Label>
            <label className="border p-0 rounded pb-2 message-area d-block">
              <Form.Control
                name="message"
                as="textarea"
                className="w-100 border-0 p-3"
                placeholder="Enter your comment"
                rows={4}
                autoFocus
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
            </label>
          </Form.Group>
        )}
      </Modal.Body>
      <Modal.Footer>
        <span />
        <div className="proceed-actions justify-content-between">
          {choice ? (
            <>
              <Button
                onClick={() => proceed(false)}
                variant="white"
                className="border bg-white"
              >
                No
              </Button>
              <Button
                onClick={() =>
                  withComment
                    ? proceed({
                      proceed: true,
                      comment,
                    })
                    : proceed(true)
                }
                variant={type === 'danger' ? 'danger' : "primary"}
                className="text-white"
                autoFocus={true}
              >
                Yes
              </Button>
            </>
          ) : (
            <>
              <span />
              <Button
                onClick={() =>
                  withComment
                    ? proceed({
                      proceed: true,
                      comment,
                    })
                    : proceed(true)
                }
                variant={type === 'danger' ? 'danger' : "primary"}
                className="text-white"
                autoFocus={true}
              >
                Okay
              </Button>
            </>
          )}
        </div>
      </Modal.Footer>
    </Modal >
  );
}

function BillingDialog({
  proceed,
  show,
  title = "",
  description = "",
  summary = {},
  summaryTitle = "Summary",
  removeUnderscore = false,
  type = "info",
  choice = true,
  backdrop = true,
  previousNumOfUsers,
  backendUrl,
  token,

  // under score is added to summary object keys, incase of duplicates
  withComment = false,
}) {
  const [comment, setComment] = useState("");

  return (
    <Modal
      show={show}
      dialogClassName="requisition-details-modal"
      centered={true}
      animation={false}
      enforceFocus={false}
      backdrop={backdrop}
      onHide={() => proceed(false)}
    >
      <Modal.Header />
      <Modal.Body className="approve-action">
        <div className="text-info-h">
          <div className="mb-4">
            {type === "info" ? (
              <ApproveBlueModalIcon />
            ) : type === "danger" ? (
              <RejectModalIcon />
            ) : (
              <ApproveBlueModalIcon />
            )}{" "}
          </div>

          <h2>{title}</h2>
          <p
            className="fs-6"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>

        {!isEmpty(summary) ? (
          <>
            <Table striped className="mt-3">
              <thead>
                <tr>
                  <th colSpan={2}>{summaryTitle}</th>
                </tr>
              </thead>
              <tbody>
                {map(summary, (obj, key, index) => (
                  <tr key={index}>
                    <td>
                      {removeUnderscore ? String(key).replaceAll("_", "") : key}
                    </td>
                    <td>
                      {obj
                        ? isNumber(Number(obj)) && !isNaN(Number(obj))
                          ? currency(obj, {
                            symbol: "",
                          }).format()
                          : obj
                        : "-"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        ) : null}

        {withComment && (
          <Form.Group className="my-4">
            <Form.Label className="d-none">Comment :</Form.Label>
            <label className="border p-0 rounded pb-2 message-area d-block">
              <Form.Control
                name="message"
                as="textarea"
                className="w-100 border-0 p-3"
                placeholder="Enter your comment"
                rows={4}
                autoFocus
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
            </label>
          </Form.Group>
        )}
      </Modal.Body>
      <Modal.Footer>
        <span />
        <div className="proceed-actions justify-content-between">
          {choice ? (
            <>
              <Button
                onClick={() => proceed(true)}
                variant="primary"
                autoFocus={true}
              >
                Create Now
              </Button>
              <Button
                onClick={() => proceed(false)}
                variant="white"
                className="border bg-white"
              >
                I will do this later
              </Button>
            </>
          ) : (
            <>
              <span />
              <Button
                onClick={() =>
                  withComment
                    ? proceed({
                      proceed: true,
                      comment,
                    })
                    : proceed(true)
                }
                variant="primary"
                autoFocus={true}
              >
                Continue
              </Button>
            </>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
}

function RemoveUserBillingDialog({
  proceed,
  show,
  title = "",
  description = "",
  summary = {},
  summaryTitle = "Summary",
  removeUnderscore = false,
  type = "info",
  choice = true,
  backdrop = true,
  // under score is added to summary object keys, incase of duplicates
  withComment = false,
}) {
  const [comment, setComment] = useState("");
  const [hide, setHide] = useState(show);

  return (
    <Modal
      show={show}
      dialogClassName="requisition-details-modal"
      centered={true}
      animation={false}
      enforceFocus={false}
      backdrop={backdrop}
      onHide={() => proceed(false)}
    >
      <Modal.Header closeButton />
      <Modal.Body className="approve-action">
        <div className="text-info-h">
          <div className="mb-4">
            {type === "info" ? (
              <ApproveBlueModalIcon />
            ) : type === "danger" ? (
              <RejectModalIcon />
            ) : (
              <ApproveBlueModalIcon />
            )}{" "}
          </div>

          <h2>{title}</h2>
          <p
            className="fs-6"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>

        {!isEmpty(summary) ? (
          <>
            <Table striped className="mt-3">
              <thead>
                <tr>
                  <th colSpan={2}>{summaryTitle}</th>
                </tr>
              </thead>
              <tbody>
                {map(summary, (obj, key, index) => (
                  <tr key={index}>
                    <td>
                      {removeUnderscore ? String(key).replaceAll("_", "") : key}
                    </td>
                    <td>
                      {obj
                        ? isNumber(Number(obj)) && !isNaN(Number(obj))
                          ? currency(obj, {
                            symbol: "",
                          }).format()
                          : obj
                        : "-"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        ) : null}

        {withComment && (
          <Form.Group className="my-4">
            <Form.Label className="d-none">Comment :</Form.Label>
            <label className="border p-0 rounded pb-2 message-area d-block">
              <Form.Control
                name="message"
                as="textarea"
                className="w-100 border-0 p-3"
                placeholder="Enter your comment"
                rows={4}
                autoFocus
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
            </label>
          </Form.Group>
        )}
      </Modal.Body>
      <Modal.Footer>
        <span />
        <div className="proceed-actions justify-content-between">
          {choice ? (
            <>
              <Button
                onClick={() =>
                  withComment
                    ? proceed({
                      proceed: true,
                      comment,
                    })
                    : proceed(true)
                }
                variant="primary"
                autoFocus={true}
              >
                Remove Now
              </Button>
              <Button
                onClick={() => proceed(false)}
                variant="white"
                className="border bg-white"
              >
                Cancel
              </Button>
            </>
          ) : (
            <>
              <span />
              <Button
                onClick={() =>
                  withComment
                    ? proceed({
                      proceed: true,
                      comment,
                    })
                    : proceed(true)
                }
                variant="primary"
                autoFocus={true}
              >
                Continue
              </Button>
            </>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
}

function ApproveAndProcessDialog({
  proceed,
  show,
  title = "",
  description = "",
  summary = {},
  summaryTitle = "Summary",
  removeUnderscore = false,
  type = "info",
  choice = true,
  backdrop = true,
  // under score is added to summary object keys, incase of duplicates
  withComment = false,
  transactionID,
  url,
  refetch,
  splitPaymentsDataToEdit = {},
}) {
  const generalSettings = useStoreState((state) => state.generalSettings);
  const [comment, setComment] = useState("");
  const [tableData, setTableData] = useState([]);
  const { backendUrl } = useAuth();
  const [mode, setMode] = useState("");
  const defaultCustomer = useStoreState((state) => state.defaultCustomer);
  const [selectedCustomer, setSelectedCustomer] = useState(defaultCustomer);
  const [serialNumbersForEdit, setSerialNumbersForEdit] = useState([]);
  const itemMeasurements = useStoreState((state) => state.itemMeasurements);
  const isEdit = useMemo(() => ["edit", "edit-preorder"].includes(mode), [
    mode,
  ]);

  const [mutationLoading, setMutationLoading] = useState(false);

  const { Trans_ID } = useParams();

  const formik = useFormik({
    initialValues: {
      //  tax: "",
      taxType: "None",
      // PaymentType: appSettings.requireSalesRep ? "Credit" : "Cash",
      PaymentType: generalSettings?.defaultInvoicePaymentType || "",
      proformaNumber: "",
      splitPaymentType: "",
      printWaybill: true,
      salesDate: moment(),
      invoiceCat: "",
      OverwriteOfficer: "Retail",
      chequeNumber: "",
      BankName: "",
      cashAmount: "",
      sendEmail: false,
      printReciept: true,
      dueIn: generalSettings.dueIn || 1,
      pendingTransaction: "",
      supplyNow: generalSettings.supplyNow,
      ShipTo: "",
      customerBalance: "",
      amountToPayFromCustomerCredit: "",
      printer: appSettings.printerType,
      posCharge: "",
      loadingCharge: "",
      offloadingCharge: "",
      isBatchStandard: appSettings.isBatchStandard, // for server to use
      transportCharge: "",
      Sale_Rep: "",
      Sale_Rep_Name: "",
      shippingCost: 0,
      otherCharges: 0,
      creditLine: "",

      // Split Payment Multiple - Amounts
      /* splitPaymentCardAmount: "",
    splitPaymentChequeAmount: "",
    splitPaymentDirectBankTransferAmount: "",

    // Banks
    splitPaymentCardBank: "",
    splitPaymentChequeBank: "",
    splitPaymentDirectBankTransferBank: "",

    // Numbers
    splitPaymentChequeNumber: "", */

      // --------------
      splitPaymentCardArray: [
        {
          amount: "",
          bank: "",
          posCharge: "",
          fixedAmount: 0,
        },
      ],

      splitDirectBankTransferArray: [
        {
          amount: "",
          bank: "",
        },
      ],

      splitPaymentChequeArray: [
        {
          amount: "",
          bank: "",
          chequeNumber: "",
        },
      ],
      Bar_Code: "",
      currency: generalSettings.prevailingCurrency,
      linkedPaymentID: "",
      jobNumber: "",
      milestone: "",
      conversionAmount: generalSettings.dollarInBaseCurrency,
      poNumber: "",
      lockBank: false,
      itemSearchCriteria: generalSettings.itemSearchCriteria, // Serial Number // Item Code
      amountFromCustomerBalance: 0,
      bankOnInvoice: "",
    },

    onSubmit: (values) => {
      //  console.log(values, tableData);

      createRodInvoiceMutation.mutate(
        {
          items: tableData,
          ...values,
          subTotal,
          discount,
          amountDue,
          profit,
          balance,
          amount,
          customer: selectedCustomer,
          taxValue,
          costOFSales,
          isRod: true,
          // blunder
          amountPaid:
            values.PaymentType === "Split Payment"
              ? /*values.cashAmount*/ values.amountPaid
              : values.PaymentType === "Split Payment Multiple"
                ? totalCashFromMultiPaymentSplit
                : values.PaymentType === "Customer Account"
                  ? 0
                  : values.amountPaid,
          mode,
          TransactionIDForDelete: Trans_ID,
          salesIncome,
        },
        {
          onSuccess: () => {
            proceed(true);
          },
        }
      );
    },

    // onReset: () => {
    //   setTableData([]);
    //   formik.setFieldValue("PaymentType", formik.values.PaymentType);
    //   paymentsDataQuery.refetch();
    //   const chosenBankOnInvoice = data.banks?.find(
    //     (el) => el.showOnSalesInvoice === "yes"
    //   );

    //   // Delay, there was an issue
    //   if (chosenBankOnInvoice) {
    //     setTimeout(
    //       () =>
    //         formik.setFieldValue(
    //           "bankOnInvoice",
    //           chosenBankOnInvoice?.BankName
    //         ),
    //       500
    //     );
    //   }
    // },
  });

  const totalPosCharge = useMemo(() => {
    return formik.values.splitPaymentCardArray
      .map((el) => el.posCharge)
      .reduce(
        (a, b) =>
          currency(a, {
            precision: 2,
          }).add(b),
        0
      );
  }, [formik.values.splitPaymentCardArray]);

  const chargesAfterTax = useMemo(() => {
    return currency(formik.values.loadingCharge, {
      symbol: "",
      separator: "",
    })
      .add(formik.values.offloadingCharge)
      .add(formik.values.posCharge)
      .add(formik.values.transportCharge)
      .add(totalPosCharge)
      .add(formik.values.shippingCost)
      .add(formik.values.otherCharges);
  }, [
    formik.values.offloadingCharge,
    formik.values.loadingCharge,
    formik.values.posCharge,
    formik.values.transportCharge,
    totalPosCharge,
    formik.values.shippingCost,
    formik.values.otherCharges,
  ]);
  const discount = useMemo(() => {
    const sum = tableData
      ? tableData
        .map((el) => el.Discount)
        .reduce(
          (a, b) =>
            currency(a, {
              precision: 2,
            }).add(b),
          0
        )
      : 0.0;
    return sum
      ? currency(sum, {
        symbol: "",
        separator: "",
      }).format()
      : "0.00";
  }, [tableData]);
  const subTotal = useMemo(() => {
    // TODO - Add Charges
    const sum = tableData
      ? tableData
        .map((el) => el.SubTotal)
        .reduce(
          (a, b) =>
            currency(a, {
              symbol: "",
              precision: 2,
            }).add(b),
          0
        )
      : 0.0;
    return sum
      ? currency(sum, {
        symbol: "",
        separator: "",
      })
        .add(discount) // don't known -
        .format()
      : "0.00";
  }, [tableData, discount]);

  const salesIncome = useMemo(() => {
    return (
      currency(subTotal)
        //  .add(taxValue)
        .add(chargesAfterTax).value
    );
  }, [chargesAfterTax, subTotal]);

  const createRodInvoiceMutation = useMutation(
    (payload) => createRodInvoice(payload),
    {
      onMutate: () => {
        setMutationLoading(true);
      },
      onSuccess: async ({ data, message }) => {
        toast.success(message);

        if (mode === "edit") {
          // refreshInvoiceDataItems();
          setTimeout(() => {
            openInvoiceModal(data);
          }, 900);
          return;
        }

        data.thermalPrinter = formik.values.printer === "Thermal Printer";
        if (refetch) {
          refetch();
        }

        formik.resetForm();
        setSelectedCustomer(defaultCustomer);
        openInvoiceModal(data);
        proceed(true);
        setMutationLoading(false);
      },
      onError: async ({ message }) => {
        try {
          const errorData = JSON.parse(message);
          if (errorData.displayType === "modal") {
            if (errorData?.lockedStockInfo) {
              lockedStockAction({
                errorData,
              });
              return;
            }

            //  This  show  not mess  with  our flow
            ConfirmDialog({
              type: "danger",
              title: errorData.message,
              description: "",
              choice: false,
              summaryTitle: "Summary",
              summary: errorData?.summary,
            });
          } else {
            toast.error(
              `Unable to perform action: ${errorData?.message || message}`
            );
          }

          setMutationLoading(false);
        } catch (err) {
          // console.log(err);
          toast.error(`Unable to perform action: ${message}`);
        }
      },
    }
  );
  const taxOptions = useTaxOptions();

  const totalSplitPaymentChequeAmount = useMemo(() => {
    return formik.values.splitPaymentChequeArray
      .map((el) => el.amount)
      .reduce(
        (a, b) =>
          currency(a, {
            precision: 2,
          }).add(b),
        0
      );
  }, [formik.values.splitPaymentChequeArray]);

  const totalSplitPaymentDirectBankTransferAmount = useMemo(() => {
    return formik.values.splitDirectBankTransferArray
      .map((el) => el.amount)
      .reduce(
        (a, b) =>
          currency(a, {
            precision: 2,
          }).add(b),
        0
      );
  }, [formik.values.splitDirectBankTransferArray]);

  const totalSplitPaymentCardAmount = useMemo(() => {
    return formik.values.splitPaymentCardArray
      .map((el) => el.amount)
      .reduce(
        (a, b) =>
          currency(a, {
            precision: 2,
          }).add(b),
        0
      );
  }, [formik.values.splitPaymentCardArray]);

  const totalCashFromMultiPaymentSplit = useMemo(() => {
    return (
      //  .add(chargesAfterTax)
      currency(totalSplitPaymentCardAmount, {
        symbol: "",
        separator: "",
      })
        .add(totalSplitPaymentChequeAmount)
        .add(totalSplitPaymentDirectBankTransferAmount)
        .add(formik.values.cashAmount).value
    );
  }, [
    totalSplitPaymentCardAmount,
    totalSplitPaymentChequeAmount,
    totalSplitPaymentDirectBankTransferAmount,
    formik.values.cashAmount,
    // chargesAfterTax,
  ]);

  const taxValue = useMemo(() => {
    const taxType = taxOptions.find((el) => el.value === formik.values.taxType);
    return currency(subTotal, {
      symbol: "",
      separator: "",
    })
      .multiply(taxType?.percentage)
      .format();
  }, [subTotal, formik.values.taxType]);
  const convertQuantity = (Quantity, Item_Desc, type) => {
    return lowerCase(type) === "tons"
      ? pcsToTons(Quantity, Item_Desc, itemMeasurements)
      : Quantity;
  };

  const setupSummary = (values) => {
    values = cloneDeep(values);
    let data =
      values.PaymentType === "Split Payment Multiple"
        ? {
          "Payment Type": values.PaymentType,
          "Amount Due": amountDue,
          Cash: values.cashAmount,
          ...values.summaryFromMultiplePayments,
          "Total Pos Charge": values.posCharge,
          "Loading Charge": values.loadingCharge,
          "Offloading Charge": values.offloadingCharge,
          "Transport Charge": values.transportCharge,
          "Total Amount Paid": totalCashFromMultiPaymentSplit,
          "Supply Now": values?.supplyNow ? "Yes" : "No",
        }
        : values.PaymentType === "Split Payment"
          ? {
            "Payment Type": values.PaymentType,
            "Amount Due": amountDue,
            "Pos Charge": values.posCharge,
            "Loading Charge": values.loadingCharge,
            "Offloading Charge": values.offloadingCharge,
            "Transport Charge": values.transportCharge,
            Cash: values.cashAmount,
            [values?.splitPaymentType]: values.amount,
            "Supply Now": values?.supplyNow ? "Yes" : "No",
          }
          : {
            "Payment Type": values?.PaymentType,
            "Amount Due": amountDue,
            "Pos Charge": values.posCharge,
            "Loading Charge": values.loadingCharge,
            "Offloading Charge": values.offloadingCharge,
            "Transport Charge": values.transportCharge,
            [`${values.PaymentType}${["Credit/Debit Card", "Direct Bank Transfer", "Cheque"].includes(
              values.PaymentType
            )
              ? ` - ${values.BankName}`
              : ""
              }`]: values.amountPaid,
            "Supply Now": values?.supplyNow ? "Yes" : "No",
          };

    for (const key in data) {
      if (!Boolean(data[key])) {
        delete data[key];
      }
    }

    return data;
  };

  const showSelectBankDialog = async (props = {}) => {
    const bank = await SelectBankDialog({
      ...props,
      selectedBank: formik.values.BankName,
      lockBank: formik.values.lockBank,
    });
    if (bank && bank?.bank) {
      formik.setFieldValue("BankName", bank.bank);
      if (props.hasChequeNumber) {
        formik.setFieldValue("chequeNumber", bank.chequeNumber);
      }
    }

    if (props.submit) {
      formik.submitForm();
    }
  };

  const amountDue = useMemo(() => {
    const sum = tableData
      ? tableData
        .map((el) => el.SubTotal)
        .reduce(
          (a, b) =>
            currency(a, {
              precision: 2,
            }).add(b),
          0
        )
      : 0.0;

    // add loading charges

    const total = sum
      ? currency(sum, {
        symbol: "",
        separator: "",
      })
        .multiply(100)
        .divide(100)
        .add(taxValue)
        .add(chargesAfterTax)
        .format()
      : "0.00";

    // TODO - Add Invoice Discount Data %, Fixed

    const value = formik.values.PaymentType;
    if (value === "Credit" || value === "Customer Account") {
      formik.setFieldValue("amountPaid", 0);
    } else {
      formik.setFieldValue("amountPaid", total);
    }

    formik.setFieldValue("amountToPayFromCustomerCredit", total);

    // will be set before we get here
    // if (value !== "Split Payment Multiple") {
    // formik.setFieldValue("cashAmount", total);
    //}
    return total;
  }, [tableData, taxValue, formik.values.PaymentType, chargesAfterTax]);

  const amount = useMemo(() => {
    return currency(amountDue, {
      symbol: "",
      separator: "",
    })
      .subtract(formik.values.cashAmount)
      .format();
  }, [amountDue, formik.values.cashAmount]);

  const balance = useMemo(() => {
    // is Balance Zero for split Payment
    const totalCashForSplit = currency(formik.values.cashAmount, {
      symbol: "",
      separator: "",
    })
      .add(amount)
      .format();

    // console.log(amountDue, totalCashFromMultiPaymentSplit);

    return currency(amountDue, {
      symbol: "",
      separator: "",
    })
      .subtract(formik.values.amountFromCustomerBalance)
      .subtract(
        formik.values.PaymentType === "Split Payment"
          ? totalCashForSplit
          : formik.values.PaymentType === "Split Payment Multiple"
            ? totalCashFromMultiPaymentSplit
            : formik.values.amountPaid
      )
      .format();
  }, [
    amount,
    amountDue,
    formik.values.amountPaid,
    formik.values.PaymentType,
    formik.values.cashAmount,
    totalCashFromMultiPaymentSplit,
    formik.values.amountFromCustomerBalance,
  ]);

  const profit = useMemo(() => {
    const sum = tableData
      ? tableData
        .map((el) => el.Profit)
        .reduce(
          (a, b) =>
            currency(a, {
              precision: 2,
            }).add(b),
          0
        )
      : 0.0;
    return sum
      ? currency(sum, {
        symbol: "",
        separator: "",
      }).format()
      : "0.00";
  }, [tableData]);

  // const grandTotal = useMemo(() => {
  //   return amountDue;
  // }, [amountDue]);

  const costOFSales = useMemo(() => {
    return tableData
      .map(
        (el) =>
          currency(el.UnitCost, { symbol: "", separator: "" }).multiply(
            convertQuantity(el.Quantity, el.Serial_Number, el.saleType)
          ).value
      )
      .reduce(
        (a, b) =>
          currency(a, {
            precision: 2,
          }).add(b).value,
        0
      );
  }, [tableData]);

  const createRodInvoice = async (payload) => {
    const endpoint = !isEmpty(payload?.items[0]?.Warehouse)
      ? "warehouseInvoice/create"
      : "invoice/create";

    payload.Warehouse = payload?.items[0]?.Warehouse;

    if (
      payload.currency &&
      payload.currency !== generalSettings?.prevailingCurrency
    ) {
      payload = convertToBaseCurrency({
        data: payload,
        conversionAmount: payload.conversionAmount,
      });
    }
    /*  console.log("JJ", payload);
    return; */
    //
    //

    let response = await fetch(`${backendUrl || url}/api/${endpoint}`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      // alert(JSON.stringify(response));
      throw new Error(JSON.stringify(response));
    }
    const res = await response.json();
    return res;
  };

  const openInvoiceModal = async (data) => {
    await InvoiceAndWaybillDialog({
      title: "Transaction posted successfully",
      // description: "...",
      TransactionID: data.TransactionID,
      thermalPrinter: data.thermalPrinter,
      url: url,
    });
  };

  const lockedStockAction = async ({ errorData }) => {
    const dialogAction = await LockedStockDialog({
      title: errorData.message,
      items: errorData?.items,
    });

    if (dialogAction) {
      // send to pending
      createRodPermitMutation.mutate({
        items: tableData.map((el) => ({
          ...el,
          Quantity: el.Quantity,
          requirePermission: true,
        })),
        ...formik.values,
        subTotal,
        discount,
        amountDue,
        profit,
        balance,
        amount,
        customer: selectedCustomer,
        taxValue,
        costOFSales,
      });
    }
  };

  // const createRodInvoiceMutation = useMutation(
  //   (payload) => createRodInvoice(payload),
  //   {
  //     onSuccess: async ({ data, message }) => {
  //       toast.success(message);

  //       if (mode === "edit") {
  //         // refreshInvoiceDataItems();
  //         setTimeout(() => {
  //           openInvoiceModal(data);
  //         }, 900);
  //         return;
  //       }

  //       data.thermalPrinter = formik.values.printer === "Thermal Printer";

  //       formik.resetForm();
  //       setSelectedCustomer(defaultCustomer);
  //       openInvoiceModal(data);
  //     },
  //     onError: async ({ message }) => {
  //       try {
  //         const errorData = JSON.parse(message);
  //         if (errorData.displayType === "modal") {
  //           if (errorData?.lockedStockInfo) {
  //             lockedStockAction({
  //               errorData,
  //             });
  //             return;
  //           }

  //           //  This  show  not mess  with  our flow
  //           ConfirmDialog({
  //             type: "danger",
  //             title: errorData.message,
  //             description: "",
  //             choice: false,
  //             summaryTitle: "Summary",
  //             summary: errorData?.summary,
  //           });
  //         } else {
  //           toast.error(
  //             `Unable to perform action: ${errorData?.message || message}`
  //           );
  //         }
  //       } catch (err) {
  //         console.log(err);
  //         toast.error(`Unable to perform action: ${message}`);
  //       }
  //     },
  //   }
  // );

  const preorderInvoice = async (payload) => {
    let response = await fetch(`${backendUrl}/api/invoice/preorder`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(JSON.stringify(response));
    }
    const res = await response.json();
    return res;
  };

  // const preorderInvoiceMutation = useMutation(
  //   (payload) => preorderInvoice(payload),
  //   {
  //     onSuccess: async ({ data, message }) => {
  //       toast.success(message);

  //       PreorderDialog({
  //         title: "Transaction posted successfully",
  //         TransactionID: data.TransactionID,
  //       });

  //       if (!isEdit) {
  //         formik.resetForm();
  //         setSelectedCustomer(defaultCustomer);
  //         return;
  //       }
  //     },
  //     onError: ({ message }) => {
  //       toast.error(`Unable to perform action: ${message}`);
  //     },
  //   }
  // );

  // const salesIncome = useMemo(() => {
  //   return currency(subTotal).add(chargesAfterTax).value;
  // }, [chargesAfterTax, subTotal]);

  // permit
  const createRodPermit = async (payload) => {
    if (
      payload.currency &&
      payload.currency !== generalSettings?.prevailingCurrency
    ) {
      payload = convertToBaseCurrency({
        data: payload,
        conversionAmount: payload.conversionAmount,
      });
    }

    let response = await fetch(
      `${backendUrl}/api/invoice/create-pending-permit`,
      {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(payload),
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };

  const createRodPermitMutation = useMutation(
    (payload) => createRodPermit(payload),
    {
      onSuccess: ({ message, data }) => {
        toast.success(message);
        /*  formik.setFieldValue("pendingTransaction", data.TransactionID);
        setLockedTableData([...tableData]);
        setTableData([
          ...tableData.map((el) => ({
            ...el,
            Quantity: el.Quantity,
            requirePermission: true,
          })),
        ]); */
        copyText(
          `${services.frontendUrl}/approval/details/${data.TransactionID}`
        );

        // Clear UI
        formik.resetForm();
        setSelectedCustomer(defaultCustomer);
      },
      onError: ({ message = "" }) => {
        toast.error(`Unable to perform action: ${message}`);
      },
    }
  );

  const handlePaymentMethod = (value) => {
    if (value === "Proforma") {
      formik.setFieldValue("PaymentType", "Credit");
      formik.setFieldValue("amountPaid", 0);
      return;
    }

    formik.setFieldValue("PaymentType", value);
    if (value === "Credit" || value === "Customer Account") {
      formik.setFieldValue("amountPaid", 0);
    }
    // clear pos charge
    if (value !== "Credit/Debit Card") {
      formik.setFieldValue("posCharge", 0);
    }

    if (value === "Split Payment" || value === "Split Payment Multiple") {
      formik.setFieldValue(
        "cashAmount",
        currency(amountDue).subtract(chargesAfterTax).value
      );
    }

    if (value !== "Split Payment Multiple") {
      formik.setFieldValue("splitPaymentCardArray", [
        {
          amount: "",
          bank: "",
          posCharge: "",
          fixedAmount: "",
        },
      ]);
      formik.setFieldValue("amountFromCustomerBalance", 0);
    }
  };

  const populateTableFromItems = (items) => {
    if (generalSettings?.itemsRequiresSerialNumber) {
      setSerialNumbersForEdit(
        items
          .map((el) => el.serialNumberItems)
          .flat()
          .map((el) => el.serialNum)
      );
    }

    //console.log(items);
    // convert qtys and clean
    if (
      items &&
      items[0]?.baseCurrency &&
      items[0]?.baseCurrency !== generalSettings?.prevailingCurrency
    ) {
      items = items.map((item) =>
        convertFromBaseCurrency({
          data: item,
          conversionAmount: items[0]?.baseConversionAmount,
        })
      );
    }
    //
    // console.log(items);
    // convert qtys and clean up

    // console.log(items, "d");

    items = items?.map((el) => {
      // ------------------------

      // check if sales type  - old  records
      const value = qtyFormat(el.QTY, el.Serial_Number, itemMeasurements).split(
        "-"
      );
      const tons = value[0];

      return {
        ...el,
        //  old way -  user  must  sell  either  ton or  pcs not both
        saleType: el.Serial_Number
          ? Number(tons) > 0
            ? "Tons"
            : "Pieces"
          : "",
        // new way for new records
        ...(el?.saleType ? { saleType: el?.saleType } : {}),
        Product_Name: el.ProductName ? el.ProductName : el.Product_Name,
        Item_Desc: el.Serial_Number,
        PriceSold: currency(el.PriceSold, {
          symbol: "",
          separator: "",
        }).format(),
        Quantity: currency(el.QTY, {
          symbol: "",
          separator: "",
        }).format(),
        UnitCost: currency(el.UnitCost, {
          symbol: "",
          separator: "",
        }).format(),
        Discount: currency(el.Discount, {
          symbol: "",
          separator: "",
        }).format(),
        SubTotal: currency(el.SubTotal, {
          symbol: "",
          separator: "",
        }).format(),
        Profit: currency(el.Profit, {
          symbol: "",
          separator: "",
        }).format(),
        UnitPrice: currency(el.Unit_Price, {
          symbol: "",
          separator: "",
        }).format(),
        Size: el.Serial_Number,
        selectedBatches: el?.selectedBatches
          ? JSON.parse(el.selectedBatches)
          : [],
      };
    });

    //  console.log(items);

    if (items) {
      formik.resetForm();
      let {
        PayType,
        VAT,
        OverwriteOfficer,
        Date_Log,
        TransactionID,
        customer,
        ProductName,
        shippingCost,
        otherCharges,
        /*  currency: */ baseCurrency = generalSettings?.prevailingCurrency,
        taxType,
        ShipTo,
        linkedPaymentID,
        jobNumber,
        milestone,
        /*  conversionAmount:  */ baseConversionAmount = 0,
        poNumber,

        //   console.log(linkedPaymentID, ShipTo);
        bank,

        // --------------------------
        loadingCharge,
        offloadingCharge,
        transportCharge,
        posCharge,
        // --------------------------
        LoadingCharge,
        OffloadingCharge,
        TransportCharge,
        PosCharge,
        // --------------------------
        canModify = false,
        Status,
        dueIn,
        UserName,
        supplyNow,
        amountFromCustomerBalance,
        bankOnInvoice,
        creditLine,
      } = items[0];

      //

      loadingCharge = loadingCharge ? loadingCharge : LoadingCharge;
      offloadingCharge = offloadingCharge ? offloadingCharge : OffloadingCharge;
      transportCharge = transportCharge ? transportCharge : TransportCharge;
      posCharge = posCharge ? posCharge : PosCharge;

      //  console.log(posCharge);

      //  console.log(linkedPaymentID, ShipTo, PayType);
      // TODO - Default Pay Type
      if (PayType === "Proforma") {
        if (appSettings.requireSalesRep) {
        } else {
          PayType = generalSettings.defaultInvoicePaymentType;
        }
      }

      formik.setValues({
        ...formik.values,
        //    taxValue: VAT,
        ...(["Split Payment Multiple", "Split Payment"].includes(PayType)
          ? { PaymentType: PayType }
          : {}),
        OverwriteOfficer,
        // salesDate: Date_Log, // use current date
        ...(TransactionID ? { pendingTransaction: TransactionID } : {}),
        invoiceCat: ProductName,
        shippingCost,
        otherCharges,
        currency: baseCurrency,
        taxType: taxType || "None",
        jobNumber,
        milestone,
        conversionAmount: baseConversionAmount,
        poNumber,
        salesDate: !isEdit ? new Date() : Date_Log,
        pendingTransaction: !isEdit ? TransactionID : "",
        invoiceCat: ProductName,
        shippingCost,
        otherCharges,
        currency,
        taxType: taxType || "None",
        BankName: bank ? bank : "",
        loadingCharge,
        offloadingCharge,
        transportCharge,
        posCharge,
        ...(splitPaymentsDataToEdit ? splitPaymentsDataToEdit : {}),
        dueIn: dueIn ? dueIn : generalSettings.dueIn,
        lockBank: Boolean(
          !isEmpty(bank) && UserName === "Store" && appSettings.isMertesacker
        ),
        ...(supplyNow !== undefined ? { supplyNow } : {}),
        amountFromCustomerBalance,
        bankOnInvoice,
        creditLine,
      });

      // This was not setting, had to delay
      setTimeout(() => {
        if (ShipTo) {
          formik.setFieldValue("ShipTo", ShipTo);
        }
        if (linkedPaymentID) {
          formik.setFieldValue("linkedPaymentID", linkedPaymentID);
        }

        formik.setFieldValue("currency", baseCurrency);
      }, 500);

      setTableData(items);

      if (customer) setSelectedCustomer(customer);

      // locked
      // if (!isEdit && !canModify && mode !== "preorder") {
      //   setLockedTableData(items);
      // }

      // if (appSettings.requireSalesRep) {
      //   getSaleReps({
      //     selectedCustomer: customer,
      //     invoiceCat: ProductName,
      //   });
      // }

      // if (Status === "Pre-order" && !isEdit) {
      //   handlePaymentMethod("Customer Account");
      //   return;
      // }

      //  console.log(splitPaymentsDataToEdit);
      if (["Split Payment Multiple", "Split Payment"].includes(PayType)) return;
      handlePaymentMethod(
        linkedPaymentID ? "Customer Account" : jobNumber ? "Credit" : PayType
      );
      //  handlePaymentMethod(linkedPaymentID ? "Customer Account" : PayType);
    }
  };
  // const queryClient = useQueryClient();

  // const { backendUrl } = useAuth();

  const { data, isLoading } = useQuery(
    ["GET_TRAnSACTION_ITEMS"],
    () =>
      fetchActionsUtil(
        `${url}/api/permits/get-by-transaction/${transactionID}`,
        "GET"
      ),
    {
      onSuccess: ({ data }) => {
        populateTableFromItems(data.items);
      },
    }
  );

  // console.log(tableData);
  return (
    <Modal
      show={show}
      onHide={() => proceed(false)}
      dialogClassName="requisition-details-modal"
      centered={true}
      animation={false}
      enforceFocus={false}
      backdrop={backdrop}
    >
      <Modal.Header />
      <Modal.Body className="approve-action">
        <div className="text-info-h">
          <div className="mb-4">
            {type === "info" ? (
              <ApproveBlueModalIcon />
            ) : type === "danger" ? (
              <RejectModalIcon />
            ) : (
              <ApproveBlueModalIcon />
            )}{" "}
          </div>

          <h2>{title}</h2>
          <p
            className="fs-6"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>

        {!isEmpty(summary) ? (
          <>
            <Table striped className="mt-3">
              <thead>
                <tr>
                  <th colSpan={2}>{summaryTitle}</th>
                </tr>
              </thead>
              <tbody>
                {map(summary, (obj, key, all) => (
                  <tr key={key}>
                    <td>
                      {removeUnderscore ? String(key).replaceAll("_", "") : key}
                    </td>
                    <td>
                      {obj
                        ? isNumber(Number(obj)) && !isNaN(Number(obj))
                          ? currency(obj, {
                            symbol: "",
                          }).format()
                          : obj
                        : "-"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        ) : null}

        {withComment && (
          <Form.Group className="my-4">
            <Form.Label className="d-none">Comment :</Form.Label>
            <label className="border p-0 rounded pb-2 message-area d-block">
              <Form.Control
                name="message"
                as="textarea"
                className="w-100 border-0 p-3"
                placeholder="Enter your comment"
                rows={4}
                autoFocus
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
            </label>
          </Form.Group>
        )}
      </Modal.Body>
      <Modal.Footer>
        <span />
        <div className="proceed-actions justify-content-between">
          {choice ? (
            <>
              <Button
                onClick={() => proceed(false)}
                variant="white"
                className="border bg-white"
              >
                No
              </Button>

              <Form onSubmit={formik.handleSubmit}>
                <Button
                  // onClick={() =>
                  //   withComment
                  //     ? proceed({
                  //         proceed: true,
                  //         comment,
                  //       })
                  //     : proceed(true)
                  // }
                  disabled={isLoading || mutationLoading}
                  type="submit"
                  variant="primary"
                  autoFocus={true}
                >
                  {isLoading || mutationLoading ? "Please wait..." : "Yes"}
                </Button>
              </Form>
            </>
          ) : (
            <>
              <span />
              <Button
                onClick={() =>
                  withComment
                    ? proceed({
                      proceed: true,
                      comment,
                    })
                    : proceed(true)
                }
                variant="primary"
                autoFocus={true}
              >
                Okay
              </Button>
            </>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
}

function ApproveAndProcessAllDialog({
  proceed,
  show,
  title = "",
  description = "",
  summary = {},
  summaryTitle = "Summary",
  removeUnderscore = false,
  type = "info",
  choice = true,
  backdrop = true,
  // under score is added to summary object keys, incase of duplicates
  withComment = false,
  transactionID,
  url,
  refetch,
  splitPaymentsDataToEdit = {},
  bulkOrderId,
  setCheckOrderStatus,
}) {
  const generalSettings = useStoreState((state) => state.generalSettings);
  const [comment, setComment] = useState("");
  const [tableData, setTableData] = useState([]);
  const { backendUrl } = useAuth();
  const [mode, setMode] = useState("");
  const defaultCustomer = useStoreState((state) => state.defaultCustomer);
  const [selectedCustomer, setSelectedCustomer] = useState(defaultCustomer);
  const [serialNumbersForEdit, setSerialNumbersForEdit] = useState([]);
  const itemMeasurements = useStoreState((state) => state.itemMeasurements);
  const isEdit = useMemo(() => ["edit", "edit-preorder"].includes(mode), [
    mode,
  ]);

  const [mutationLoading, setMutationLoading] = useState(false);
  const [orders, setOrderd] = useState({});

  const { Trans_ID } = useParams();

  const formik = useFormik({
    initialValues: {
      //  tax: "",
      taxType: "None",
      // PaymentType: appSettings.requireSalesRep ? "Credit" : "Cash",
      PaymentType: generalSettings?.defaultInvoicePaymentType || "",
      proformaNumber: "",
      splitPaymentType: "",
      printWaybill: true,
      salesDate: moment(),
      invoiceCat: "",
      OverwriteOfficer: "Retail",
      chequeNumber: "",
      BankName: "",
      cashAmount: "",
      sendEmail: false,
      printReciept: true,
      dueIn: generalSettings.dueIn || 1,
      pendingTransaction: "",
      supplyNow: generalSettings.supplyNow,
      ShipTo: "",
      customerBalance: "",
      amountToPayFromCustomerCredit: "",
      printer: appSettings.printerType,
      posCharge: "",
      loadingCharge: "",
      offloadingCharge: "",
      isBatchStandard: appSettings.isBatchStandard, // for server to use
      transportCharge: "",
      Sale_Rep: "",
      Sale_Rep_Name: "",
      shippingCost: 0,
      otherCharges: 0,
      creditLine: "",

      // Split Payment Multiple - Amounts
      /* splitPaymentCardAmount: "",
      splitPaymentChequeAmount: "",
      splitPaymentDirectBankTransferAmount: "",

      // Banks
      splitPaymentCardBank: "",
      splitPaymentChequeBank: "",
      splitPaymentDirectBankTransferBank: "",

      // Numbers
      splitPaymentChequeNumber: "", */

      // --------------
      splitPaymentCardArray: [
        {
          amount: "",
          bank: "",
          posCharge: "",
          fixedAmount: 0,
        },
      ],

      splitDirectBankTransferArray: [
        {
          amount: "",
          bank: "",
        },
      ],

      splitPaymentChequeArray: [
        {
          amount: "",
          bank: "",
          chequeNumber: "",
        },
      ],
      Bar_Code: "",
      currency: generalSettings.prevailingCurrency,
      linkedPaymentID: "",
      jobNumber: "",
      milestone: "",
      conversionAmount: generalSettings.dollarInBaseCurrency,
      poNumber: "",
      lockBank: false,
      itemSearchCriteria: generalSettings.itemSearchCriteria, // Serial Number // Item Code
      amountFromCustomerBalance: 0,
      bankOnInvoice: "",
    },

    onSubmit: (values) => {
      //  console.log(values, tableData);

      createRodInvoiceMutation.mutate(
        { orders: orders, bulkOrderId: bulkOrderId },
        {
          onSuccess: () => {
            proceed(true);
          },
        }
      );

      // createRodInvoiceMutation.mutate(
      //   {
      //     items: tableData,
      //     ...values,
      //     subTotal,
      //     discount,
      //     amountDue,
      //     profit,
      //     balance,
      //     amount,
      //     customer: selectedCustomer,
      //     taxValue,
      //     costOFSales,
      //     isRod: true,
      //     // blunder
      //     amountPaid:
      //       values.PaymentType === "Split Payment"
      //         ? /*values.cashAmount*/ values.amountPaid
      //         : values.PaymentType === "Split Payment Multiple"
      //         ? totalCashFromMultiPaymentSplit
      //         : values.PaymentType === "Customer Account"
      //         ? 0
      //         : values.amountPaid,
      //     mode,
      //     TransactionIDForDelete: Trans_ID,
      //     salesIncome,
      //   },
      //   {
      //     onSuccess: () => {
      //       proceed(true);
      //     },
      //   }
      // );
    },

    // onReset: () => {
    //   setTableData([]);
    //   formik.setFieldValue("PaymentType", formik.values.PaymentType);
    //   paymentsDataQuery.refetch();
    //   const chosenBankOnInvoice = data.banks?.find(
    //     (el) => el.showOnSalesInvoice === "yes"
    //   );

    //   // Delay, there was an issue
    //   if (chosenBankOnInvoice) {
    //     setTimeout(
    //       () =>
    //         formik.setFieldValue(
    //           "bankOnInvoice",
    //           chosenBankOnInvoice?.BankName
    //         ),
    //       500
    //     );
    //   }
    // },
  });

  //  new logic to send all the  pselected processed items to the backend fserver for creating invoice
  const createRodInvoice = async (payload) => {
    let response = await fetch(
      `${backendUrl || url}/api/permits/process-approve-all-orders`,
      {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(payload),
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
      }
    );
    if (!response.ok) {
      response = await response.json();
      // alert(JSON.stringify(response));
      throw new Error(JSON.stringify(response));
    }
    const res = await response.json();
    // refetch();

    return res;
  };

  const createRodInvoiceMutation = useMutation(
    async (payload) => createRodInvoice(payload),
    {
      onMutate: () => {
        setMutationLoading(true);
      },
      onSuccess: async (data) => {
        // console.log(data);
        refetch();
        // setTimeout(() => {
        // }, 1500);
        toast.success("Success");
        setCheckOrderStatus(true);

        // if (mode === "edit") {
        //   // refreshInvoiceDataItems();
        //   setTimeout(() => {
        //     openInvoiceModal(data);
        //   }, 900);
        //   return;
        // }

        // data.thermalPrinter = formik.values.printer === "Thermal Printer";

        formik.resetForm();
        setSelectedCustomer(defaultCustomer);
        // openInvoiceModal(data);
        proceed(true);
        setMutationLoading(false);
      },
      onError: async ({ message }) => {
        // try {
        //   const errorData = JSON.parse(message);
        //   if (errorData.displayType === "modal") {
        //     if (errorData?.lockedStockInfo) {
        //       lockedStockAction({
        //         errorData,
        //       });
        //       return;
        //     }
        message = JSON.parse(message);
        // console.log();
        //     //  This  show  not mess  with  our flow
        //     ConfirmDialog({
        //       type: "danger",
        //       title: errorData.message,
        //       description: "",
        //       choice: false,
        //       summaryTitle: "Summary",
        //       summary: errorData?.summary,
        //     });
        //   } else {
        //     toast.error(
        //       `Unable to perform action: ${errorData?.message || message}`
        //     );
        //   }

        //   setMutationLoading(false);
        // } catch (err) {
        //   console.log(err);
        toast.error(`Unable to perform action ${message?.message}`);
        setCheckOrderStatus(true);

        setMutationLoading(false);
        // }
      },
    }
  );
  const taxOptions = useTaxOptions();

  // toteal split payment card amount here

  // const setupSummary = (values) => {
  //   values = cloneDeep(values);
  //   let data =
  //     values.PaymentType === "Split Payment Multiple"
  //       ? {
  //           "Payment Type": values.PaymentType,
  //           "Amount Due": amountDue,
  //           Cash: values.cashAmount,
  //           ...values.summaryFromMultiplePayments,
  //           "Total Pos Charge": values.posCharge,
  //           "Loading Charge": values.loadingCharge,
  //           "Offloading Charge": values.offloadingCharge,
  //           "Transport Charge": values.transportCharge,
  //           "Total Amount Paid": totalCashFromMultiPaymentSplit,
  //           "Supply Now": values?.supplyNow ? "Yes" : "No",
  //         }
  //       : values.PaymentType === "Split Payment"
  //       ? {
  //           "Payment Type": values.PaymentType,
  //           "Amount Due": amountDue,
  //           "Pos Charge": values.posCharge,
  //           "Loading Charge": values.loadingCharge,
  //           "Offloading Charge": values.offloadingCharge,
  //           "Transport Charge": values.transportCharge,
  //           Cash: values.cashAmount,
  //           [values?.splitPaymentType]: values.amount,
  //           "Supply Now": values?.supplyNow ? "Yes" : "No",
  //         }
  //       : {
  //           "Payment Type": values?.PaymentType,
  //           "Amount Due": amountDue,
  //           "Pos Charge": values.posCharge,
  //           "Loading Charge": values.loadingCharge,
  //           "Offloading Charge": values.offloadingCharge,
  //           "Transport Charge": values.transportCharge,
  //           [`${values.PaymentType}${
  //             ["Credit/Debit Card", "Direct Bank Transfer", "Cheque"].includes(
  //               values.PaymentType
  //             )
  //               ? ` - ${values.BankName}`
  //               : ""
  //           }`]: values.amountPaid,
  //           "Supply Now": values?.supplyNow ? "Yes" : "No",
  //         };

  //   for (const key in data) {
  //     if (!Boolean(data[key])) {
  //       delete data[key];
  //     }
  //   }

  //   return data;
  // };

  // const showSelectBankDialog = async (props = {}) => {
  //   const bank = await SelectBankDialog({
  //     ...props,
  //     selectedBank: formik.values.BankName,
  //     lockBank: formik.values.lockBank,
  //   });
  //   if (bank && bank?.bank) {
  //     formik.setFieldValue("BankName", bank.bank);
  //     if (props.hasChequeNumber) {
  //       formik.setFieldValue("chequeNumber", bank.chequeNumber);
  //     }
  //   }

  //   if (props.submit) {
  //     formik.submitForm();
  //   }
  // };

  // const grandTotal = useMemo(() => {
  //   return amountDue;
  // }, [amountDue]);

  //you will need here
  // const createRodInvoice = async (payload) => {
  //   const endpoint = !isEmpty(payload?.items[0]?.Warehouse)
  //     ? "warehouseInvoice/create"
  //     : "invoice/create";

  //   payload.Warehouse = payload?.items[0]?.Warehouse;

  //   if (
  //     payload.currency &&
  //     payload.currency !== generalSettings?.prevailingCurrency
  //   ) {
  //     payload = convertToBaseCurrency({
  //       data: payload,
  //       conversionAmount: payload.conversionAmount,
  //     });
  //   }
  //   /*  console.log("JJ", payload);
  //   return; */
  //   //
  //   //

  //   let response = await fetch(`${backendUrl || url}/api/${endpoint}`, {
  //     method: "POST",
  //     credentials: "include",
  //     body: JSON.stringify(payload),
  //     headers: {
  //       Accept: "Application/json",
  //       "Content-Type": "Application/json",
  //     },
  //   });
  //   if (!response.ok) {
  //     response = await response.json();
  //     // alert(JSON.stringify(response));
  //     throw new Error(JSON.stringify(response));
  //   }
  //   const res = await response.json();
  //   return res;
  // };

  // const openInvoiceModal = async (data) => {
  //   await InvoiceAndWaybillDialog({
  //     title: "Transaction posted successfully",
  //     // description: "...",
  //     TransactionID: data.TransactionID,
  //     thermalPrinter: data.thermalPrinter,
  //     url: url,
  //   });
  // };

  // const lockedStockAction = async ({ errorData }) => {
  //   const dialogAction = await LockedStockDialog({
  //     title: errorData.message,
  //     items: errorData?.items,
  //   });

  //   if (dialogAction) {
  //     // send to pending
  //     createRodPermitMutation.mutate({
  //       items: tableData.map((el) => ({
  //         ...el,
  //         Quantity: el.Quantity,
  //         requirePermission: true,
  //       })),
  //       ...formik.values,
  //       subTotal,
  //       discount,
  //       amountDue,
  //       profit,
  //       balance,
  //       amount,
  //       customer: selectedCustomer,
  //       taxValue,
  //       costOFSales,
  //     });
  //   }
  // };

  // const preorderInvoice = async (payload) => {
  //   let response = await fetch(`${backendUrl}/api/invoice/preorder`, {
  //     method: "POST",
  //     credentials: "include",
  //     body: JSON.stringify(payload),
  //     headers: {
  //       Accept: "Application/json",
  //       "Content-Type": "Application/json",
  //     },
  //   });
  //   if (!response.ok) {
  //     response = await response.json();
  //     throw new Error(JSON.stringify(response));
  //   }
  //   const res = await response.json();
  //   return res;
  // };

  // to this places
  //   (payload) => preorderInvoice(payload),
  //   {
  //     onSuccess: async ({ data, message }) => {
  //       toast.success(message);

  //       PreorderDialog({
  //         title: "Transaction posted successfully",
  //         TransactionID: data.TransactionID,
  //       });

  //       if (!isEdit) {
  //         formik.resetForm();
  //         setSelectedCustomer(defaultCustomer);
  //         return;
  //       }
  //     },
  //     onError: ({ message }) => {
  //       toast.error(`Unable to perform action: ${message}`);
  //     },
  //   }
  // );

  // const salesIncome = useMemo(() => {
  //   return currency(subTotal).add(chargesAfterTax).value;
  // }, [chargesAfterTax, subTotal]);

  // permit
  const createRodPermit = async (payload) => {
    if (
      payload.currency &&
      payload.currency !== generalSettings?.prevailingCurrency
    ) {
      payload = convertToBaseCurrency({
        data: payload,
        conversionAmount: payload.conversionAmount,
      });
    }

    let response = await fetch(
      `${backendUrl}/api/invoice/create-pending-permit`,
      {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(payload),
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };

  const createRodPermitMutation = useMutation(
    (payload) => createRodPermit(payload),
    {
      onSuccess: ({ message, data }) => {
        toast.success(message);
        /*  formik.setFieldValue("pendingTransaction", data.TransactionID);
        setLockedTableData([...tableData]);
        setTableData([
          ...tableData.map((el) => ({
            ...el,
            Quantity: el.Quantity,
            requirePermission: true,
          })),
        ]); */
        copyText(
          `${services.frontendUrl}/approval/details/${data.TransactionID}`
        );

        // Clear UI
        formik.resetForm();
        setSelectedCustomer(defaultCustomer);
      },
      onError: ({ message = "" }) => {
        toast.error(`Unable to perform action: ${message}`);
      },
    }
  );

  let tableItems = [];

  const populateTableFromItems = (items) => {
    // console.log(items);
    const initalValue = formik.values;

    // const dataItem = items[0];

    // const combinedData = { ...initalValue, ...dataItem };

    if (generalSettings?.itemsRequiresSerialNumber) {
      setSerialNumbersForEdit(
        items
          .map((el) => el.serialNumberItems)
          .flat()
          .map((el) => el.serialNum)
      );
    }

    //console.log(items);
    // convert qtys and clean
    if (
      items &&
      items[0]?.baseCurrency &&
      items[0]?.baseCurrency !== generalSettings?.prevailingCurrency
    ) {
      items = items.map((item) =>
        convertFromBaseCurrency({
          data: item,
          conversionAmount: items[0]?.baseConversionAmount,
        })
      );
    }
    //
    // console.log(items);
    // convert qtys and clean up

    // console.log(items, "d");

    items = items?.map((el) => {
      // ------------------------

      // check if sales type  - old  records
      const value = qtyFormat(el.QTY, el.Serial_Number, itemMeasurements).split(
        "-"
      );
      const tons = value[0];

      return {
        ...el,
        //  old way -  user  must  sell  either  ton or  pcs not both
        saleType: el.Serial_Number
          ? Number(tons) > 0
            ? "Tons"
            : "Pieces"
          : "",
        // new way for new records
        ...(el?.saleType ? { saleType: el?.saleType } : {}),
        Product_Name: el.ProductName ? el.ProductName : el.Product_Name,
        Item_Desc: el.Serial_Number,
        PriceSold: currency(el.PriceSold, {
          symbol: "",
          separator: "",
        }).format(),
        Quantity: currency(el.QTY, {
          symbol: "",
          separator: "",
        }).format(),
        UnitCost: currency(el.UnitCost, {
          symbol: "",
          separator: "",
        }).format(),
        Discount: currency(el.Discount, {
          symbol: "",
          separator: "",
        }).format(),
        SubTotal: currency(el.SubTotal, {
          symbol: "",
          separator: "",
        }).format(),
        Profit: currency(el.Profit, {
          symbol: "",
          separator: "",
        }).format(),
        UnitPrice: currency(el.Unit_Price, {
          symbol: "",
          separator: "",
        }).format(),
        Size: el.Serial_Number,
        selectedBatches: el?.selectedBatches
          ? JSON.parse(el.selectedBatches)
          : [],
      };
    });

    //  console.log(items);

    if (items) {
      formik.resetForm();
      let {
        PayType,
        VAT,
        OverwriteOfficer,
        Date_Log,
        TransactionID,
        customer,
        ProductName,
        shippingCost,
        otherCharges,
        /*  currency: */ baseCurrency = generalSettings?.prevailingCurrency,
        taxType,
        ShipTo,
        linkedPaymentID,
        jobNumber,
        milestone,
        /*  conversionAmount:  */ baseConversionAmount = 0,
        poNumber,

        //   console.log(linkedPaymentID, ShipTo);
        bank,

        // --------------------------
        loadingCharge,
        offloadingCharge,
        transportCharge,
        posCharge,
        // --------------------------
        LoadingCharge,
        OffloadingCharge,
        TransportCharge,
        PosCharge,
        // --------------------------
        canModify = false,
        Status,
        dueIn,
        UserName,
        supplyNow,
        amountFromCustomerBalance,
        bankOnInvoice,
        creditLine,
        AmountPaid,
      } = items[0];

      //

      loadingCharge = loadingCharge ? loadingCharge : LoadingCharge;
      offloadingCharge = offloadingCharge ? offloadingCharge : OffloadingCharge;
      transportCharge = transportCharge ? transportCharge : TransportCharge;
      posCharge = posCharge ? posCharge : PosCharge;

      //  console.log(posCharge);

      //  console.log(linkedPaymentID, ShipTo, PayType);
      // TODO - Default Pay Type
      if (PayType === "Proforma") {
        if (appSettings.requireSalesRep) {
        } else {
          PayType = generalSettings.defaultInvoicePaymentType;
        }
      }

      const orders = {
        ...formik.values,
        //    taxValue: VAT,
        ...(["Split Payment Multiple", "Split Payment"].includes(PayType)
          ? { PaymentType: PayType }
          : {}),
        OverwriteOfficer,
        // salesDate: Date_Log, // use current date
        ...(TransactionID ? { pendingTransaction: TransactionID } : {}),
        invoiceCat: ProductName,
        shippingCost,
        otherCharges,
        currency: baseCurrency,
        taxType: taxType || "None",
        jobNumber,
        milestone,
        conversionAmount: baseConversionAmount,
        poNumber,
        salesDate: !isEdit ? new Date() : Date_Log,
        pendingTransaction: !isEdit ? TransactionID : "",
        invoiceCat: ProductName,
        shippingCost,
        otherCharges,
        currency,
        taxType: taxType || "None",
        BankName: bank ? bank : "",
        loadingCharge,
        offloadingCharge,
        transportCharge,
        posCharge,
        ...(splitPaymentsDataToEdit ? splitPaymentsDataToEdit : {}),
        dueIn: dueIn ? dueIn : generalSettings.dueIn,
        lockBank: Boolean(
          !isEmpty(bank) && UserName === "Store" && appSettings.isMertesacker
        ),
        ...(supplyNow !== undefined ? { supplyNow } : {}),
        amountFromCustomerBalance,
        bankOnInvoice,
        creditLine,
        items: items,
        amountPaid: AmountPaid,
      };

      //items[0]["orders"] = orders;
      // This was not setting, had to delay

      if (ShipTo) {
        // formik.setFieldValue("ShipTo", ShipTo);
        orders["ShipTo"] = ShipTo;
      }
      if (linkedPaymentID) {
        // formik.setFieldValue("linkedPaymentID", linkedPaymentID);
        orders["linkedPaymentID"] = linkedPaymentID;
      }

      // formik.setFieldValue("currency", baseCurrency);
      orders["currency"] = currency;

      //  setTableData(items);
      orders.customer = customer;

      const convertQuantity = (Quantity, Item_Desc, type) => {
        return lowerCase(type) === "tons"
          ? pcsToTons(Quantity, Item_Desc, itemMeasurements)
          : Quantity;
      };

      const totalPosCharge = () => {
        return orders["splitPaymentCardArray"]
          .map((el) => el.posCharge)
          .reduce(
            (a, b) =>
              currency(a, {
                precision: 2,
              }).add(b),
            0
          );
      };

      const chargesAfterTax = () => {
        return currency(orders["LoadingCharge"], {
          symbol: "",
          separator: "",
        })
          .add(orders["offloadingCharge"])
          .add(orders["posCharge"])
          .add(orders["transportCharge"])
          .add(totalPosCharge())
          .add(orders["shippingCost"])
          .add(orders["otherCharges"]);
      };

      const discount = () => {
        const sum = orders["items"]
          ? orders["items"]
            .map((el) => el.Discount)
            .reduce(
              (a, b) =>
                currency(a, {
                  precision: 2,
                }).add(b),
              0
            )
          : 0.0;
        return sum
          ? currency(sum, {
            symbol: "",
            separator: "",
          }).format()
          : "0.00";
      };

      const subTotal = () => {
        // TODO - Add Charges
        const sum = orders["items"]
          ? orders["items"]
            .map((el) => Number(el.SubTotal))
            .reduce(
              (a, b) =>
                currency(a, {
                  symbol: "",
                  precision: 2,
                }).add(b),
              0
            )
          : 0.0;
        return sum
          ? currency(sum, {
            symbol: "",
            separator: "",
          })
            .add(discount()) // don't known -
            .format()
          : "0.00";
      };

      const salesIncome = () => {
        return currency(subTotal()).add(chargesAfterTax()).value;
      };

      const totalSplitPaymentChequeAmount = () => {
        return orders["splitPaymentChequeArray"]
          ?.map((el) => el.amount)
          .reduce(
            (a, b) =>
              currency(a, {
                precision: 2,
              }).add(b),
            0
          );
      };

      const totalSplitPaymentDirectBankTransferAmount = () => {
        return orders["splitDirectBankTransferArray"]
          .map((el) => el.amount)
          .reduce(
            (a, b) =>
              currency(a, {
                precision: 2,
              }).add(b),
            0
          );
      };

      const totalSplitPaymentCardAmount = () => {
        return orders["splitPaymentCardArray"]
          .map((el) => el.amount)
          .reduce(
            (a, b) =>
              currency(a, {
                precision: 2,
              }).add(b),
            0
          );
      };

      const totalCashFromMultiPaymentSplit = () => {
        return (
          //  .add(chargesAfterTax)
          currency(totalSplitPaymentCardAmount(), {
            symbol: "",
            separator: "",
          })
            .add(totalSplitPaymentChequeAmount())
            .add(totalSplitPaymentDirectBankTransferAmount())
            .add(orders["cashAmount"]).value
        );
      };

      const taxValue = () => {
        const taxType = taxOptions.find((el) => el.value === orders["taxType"]);

        // console.log(subTotal());
        return currency(subTotal(), {
          symbol: "",
          separator: "",
        })
          .multiply(taxType?.percentage)
          .format();
      };

      const amountDue = () => {
        const sum = orders["items"]
          ? orders["items"]
            .map((el) => Number(el.SubTotal))
            .reduce(
              (a, b) =>
                currency(Number(a), {
                  precision: 2,
                }).add(Number(b)),
              0
            )
          : 0.0;

        // add loading charges

        // console.log("this is the sum", taxValue());
        // console.log("this is the sum", chargesAfterTax());

        const total = sum
          ? currency(sum, {
            symbol: "",
            separator: "",
          })
            .multiply(100)
            .divide(100)
            .add(taxValue())
            .add(chargesAfterTax())
            .format()
          : "0.00";

        // TODO - Add Invoice Discount Data %, Fixed

        // const value = formik.values.PaymentType;
        // if (value === "Credit" || value === "Customer Account") {
        //   formik.setFieldValue("amountPaid", 0);
        // } else {
        //   formik.setFieldValue("amountPaid", total);
        // }

        // formik.setFieldValue("amountToPayFromCustomerCredit", total);

        // will be set before we get here
        // if (value !== "Split Payment Multiple") {
        // formik.setFieldValue("cashAmount", total);
        //}
        return total;
      };

      const amount = () => {
        return currency(amountDue(), {
          symbol: "",
          separator: "",
        })
          .subtract(orders["cashAmount"])
          .format();
      };

      const balance = () => {
        // is Balance Zero for split Payment
        const totalCashForSplit = currency(orders["cashAmount"], {
          symbol: "",
          separator: "",
        })
          .add(amount())
          .format();

        // console.log(amountDue, totalCashFromMultiPaymentSplit);

        return currency(amountDue(), {
          symbol: "",
          separator: "",
        })
          .subtract(orders["amountFromCustomerBalance"])
          .subtract(
            orders["PaymentType"] === "Split Payment"
              ? totalCashForSplit()
              : orders["PaymentType"] === "Split Payment Multiple"
                ? totalCashFromMultiPaymentSplit()
                : orders["amountPaid"]
          )
          .format();
      };

      const profit = () => {
        const sum = items
          ? items
            .map((el) => el.Profit)
            .reduce(
              (a, b) =>
                currency(a, {
                  precision: 2,
                }).add(b),
              0
            )
          : 0.0;
        return sum
          ? currency(sum, {
            symbol: "",
            separator: "",
          }).format()
          : "0.00";
      };

      const costOFSales = () => {
        return items
          .map(
            (el) =>
              currency(el.UnitCost, { symbol: "", separator: "" }).multiply(
                convertQuantity(el.Quantity, el.Serial_Number, el.saleType)
              ).value
          )
          .reduce(
            (a, b) =>
              currency(a, {
                precision: 2,
              }).add(b).value,
            0
          );
      };

      const handlePaymentMethod = (value) => {
        if (value === "Proforma") {
          orders["PaymentType"] = "Credit";
          orders["amountPaid"] = 0;
          return;
        }

        orders["PaymentType"] = value;
        if (value === "Credit" || value === "Customer Account") {
          orders["amountPaid"] = 0;
        }
        // clear pos charge
        if (value !== "Credit/Debit Card") {
          orders["posCharge"] = 0;
        }

        if (value === "Split Payment" || value === "Split Payment Multiple") {
          orders["cashAmount"] = currency(amountDue()).subtract(
            chargesAfterTax()
          ).value;
        }

        if (value !== "Split Payment Multiple") {
          orders["splitPaymentCardArray"] = [
            {
              amount: "",
              bank: "",
              posCharge: "",
              fixedAmount: "",
            },
          ];

          orders["amountFromCustomerBalance"] = 0;
        }
      };

      handlePaymentMethod(
        linkedPaymentID ? "Customer Account" : jobNumber ? "Credit" : PayType
      );
      //

      orders["totalPosCharge"] = chargesAfterTax();
      orders["chargesAfterTax"] = chargesAfterTax();
      orders["discount"] = discount();
      orders["SubTotal"] = subTotal();
      orders["amountDue"] = amountDue();

      orders["salesIncome"] = salesIncome();
      orders["totalSplitPaymentChequeAmount"] = totalSplitPaymentChequeAmount();
      orders[
        "totalSplitPaymentDirectBankTransferAmount"
      ] = totalSplitPaymentDirectBankTransferAmount();
      orders["totalSplitPaymentCardAmount"] = totalSplitPaymentCardAmount();
      orders[
        "totalCashFromMultiPaymentSplit"
      ] = totalCashFromMultiPaymentSplit();
      orders["taxValue"] = taxValue();
      orders["amount"] = amount();
      orders["balance"] = balance();
      orders["profit"] = profit();
      orders["costOFSales"] = costOFSales();

      // console.log("this is th efirst order  === ", orders);

      // locked
      // if (!isEdit && !canModify && mode !== "preorder") {
      //   setLockedTableData(items);
      // }

      // if (appSettings.requireSalesRep) {
      //   getSaleReps({
      //     selectedCustomer: customer,
      //     invoiceCat: ProductName,
      //   });
      // }

      // if (Status === "Pre-order" && !isEdit) {
      //   handlePaymentMethod("Customer Account");
      //   return;
      // }

      //  console.log(splitPaymentsDataToEdit);
      // if (["Split Payment Multiple", "Split Payment"].includes(PayType)) return;
      // handlePaymentMethod(
      //   linkedPaymentID ? "Customer Account" : jobNumber ? "Credit" : PayType
      // );
      //  handlePaymentMethod(linkedPaymentID ? "Customer Account" : PayType);
      return orders;
    }

    // ====
  };
  // const queryClient = useQueryClient();

  // const { backendUrl } = useAuth();

  const { data, isLoading } = useQuery(
    ["GET_TRAnSACTION_ALL-ITEMS"],
    () =>
      fetchActionsUtil(
        `${url}/api/permits/get-all-items-transactions`,
        "POST",
        "",
        transactionID
      ),
    {
      onSuccess: ({ data }) => {
        // console.log(data);
        data = data.map((item) => populateTableFromItems(item));

        setOrderd(data);

        // console.log("this is the item", data);
        // console.log(data);
      },
    }
  );

  return (
    <Modal
      show={show}
      onHide={() => proceed(false)}
      dialogClassName="requisition-details-modal"
      centered={true}
      animation={false}
      enforceFocus={false}
      backdrop={backdrop}
    >
      <Modal.Header />
      <Modal.Body className="approve-action">
        <div className="text-info-h">
          <div className="mb-4">
            {type === "info" ? (
              <ApproveBlueModalIcon />
            ) : type === "danger" ? (
              <RejectModalIcon />
            ) : (
              <ApproveBlueModalIcon />
            )}{" "}
          </div>

          <h2>{title}</h2>
          <p
            className="fs-6"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>

        {!isEmpty(summary) ? (
          <>
            <Table striped className="mt-3">
              <thead>
                <tr>
                  <th colSpan={2}>{summaryTitle}</th>
                </tr>
              </thead>
              <tbody>
                {map(summary, (obj, key, all) => (
                  <tr key={key}>
                    <td>
                      {removeUnderscore ? String(key).replaceAll("_", "") : key}
                    </td>
                    <td>
                      {obj
                        ? isNumber(Number(obj)) && !isNaN(Number(obj))
                          ? currency(obj, {
                            symbol: "",
                          }).format()
                          : obj
                        : "-"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        ) : null}

        {withComment && (
          <Form.Group className="my-4">
            <Form.Label className="d-none">Comment :</Form.Label>
            <label className="border p-0 rounded pb-2 message-area d-block">
              <Form.Control
                name="message"
                as="textarea"
                className="w-100 border-0 p-3"
                placeholder="Enter your comment"
                rows={4}
                autoFocus
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
            </label>
          </Form.Group>
        )}
      </Modal.Body>
      <Modal.Footer>
        <span />
        <div className="proceed-actions justify-content-between">
          {choice ? (
            <>
              <Button
                onClick={() => proceed(false)}
                variant="white"
                className="border bg-white"
              >
                No
              </Button>

              <Form onSubmit={formik.handleSubmit}>
                <Button
                  // onClick={() =>
                  //   withComment
                  //     ? proceed({
                  //         proceed: true,
                  //         comment,
                  //       })
                  //     : proceed(true)
                  // }
                  disabled={isLoading || mutationLoading}
                  type="submit"
                  variant="primary"
                  autoFocus={true}
                >
                  {isLoading || mutationLoading ? "Please wait..." : "Yes"}
                </Button>
              </Form>
            </>
          ) : (
            <>
              <span />
              <Button
                onClick={() =>
                  withComment
                    ? proceed({
                      proceed: true,
                      comment,
                    })
                    : proceed(true)
                }
                variant="primary"
                autoFocus={true}
              >
                Okay
              </Button>
            </>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
}

function ExpenseChoiceDialog({
  proceed,
  show,
  title = "",
  description = "",
}) {

  return (
    <Modal
      show={show}
      onHide={() => proceed(false)}
      dialogClassName="requisition-details-modal"
      centered={true}
      animation={false}
      enforceFocus={false}
    >
      <Modal.Header />
      <Modal.Body className="approve-action">
        <div className="text-info-h">
          <div className="mb-4">
            <ApproveBlueModalIcon />
          </div>

          <h2>{title}</h2>
          <p
            className="fs-6"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>


      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => proceed(false)}
          variant="white"
        >
          Cancel
        </Button>
        <div className="proceed-actions justify-content-between">


          <Button
            onClick={() => proceed({
              mode: 'Multi Mode'
            })}
            variant='lighter'
          >
            Multi Mode
          </Button>

          <Button
            onClick={() => proceed({
              mode: 'Single Mode'
            })}
            variant="primary"
          >
            Single Mode
          </Button>
        </div>
      </Modal.Footer>
    </Modal >
  );
}

export default function ConfirmDialog(props) {
  return createConfirmation(confirmable(Dialog))(props);
}
export function ConfirmBillingDialog(props) {
  return createConfirmation(confirmable(BillingDialog))(props);
}
export function ConfirmRemoveUserBillingDialog(props) {
  return createConfirmation(confirmable(RemoveUserBillingDialog))(props);
}
export function ConfirmApproveAndProcessDialogDialog(props) {
  return createConfirmation(confirmable(ApproveAndProcessDialog))(props);
}
export function ConfirmApproveAndProcessAllDialogDialog(props) {
  return createConfirmation(confirmable(ApproveAndProcessAllDialog))(props);
}
export function ConfirmExpenseChoiceDialog(props) {
  return createConfirmation(confirmable(ExpenseChoiceDialog))(props);
}
